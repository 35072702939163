const baseRoute = '/fleet-maintenance'

export const FleetMaintenanceAreaPaths = {
  Hu: 'hu',
  InactiveVehicles: 'inactive-vehicles',
  Inspections: 'inspections',
  Vic: 'vic',
  WorkOrders: 'work-orders',
}
export const FleetMaintenanceAreaRoutes = {
  route: baseRoute,
  Hu: `${baseRoute}/${FleetMaintenanceAreaPaths.Hu}`,
  InactiveVehicles: `${baseRoute}/${FleetMaintenanceAreaPaths.InactiveVehicles}`,
  Inspections: `${baseRoute}/${FleetMaintenanceAreaPaths.Inspections}`,
  Vic: `${baseRoute}/${FleetMaintenanceAreaPaths.Vic}`,
  WorkOrders: `${baseRoute}/${FleetMaintenanceAreaPaths.WorkOrders}`,
}
