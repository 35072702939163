/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { BackofficeUserFragmentDoc } from '../../../../../shifts-employee/src/lib/ShiftDetails/graphql/fragment/backofficeUserFragment.hook'
const defaultOptions = {} as const
export type TaskNotificationsQueryVariables = Types.Exact<{
  assignedToMe?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
  serviceAreaUUID: Types.Scalars['ID']['input']
  vehicleHubLocation?: Types.InputMaybe<
    Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']
  >
  types: Array<Types.TaskNotificationType> | Types.TaskNotificationType
  search?: Types.InputMaybe<Types.Scalars['String']['input']>
  sorting?: Types.InputMaybe<
    Array<Types.TaskNotificationSorting> | Types.TaskNotificationSorting
  >
  status?: Types.InputMaybe<
    Array<Types.TaskNotificationStatus> | Types.TaskNotificationStatus
  >
  pagination?: Types.InputMaybe<Types.PaginationInput>
}>

export type TaskNotificationsQuery = {
  __typename?: 'Query'
  newTasks: { __typename?: 'TaskNotificationList'; total: number }
  myInProgressTasks: { __typename?: 'TaskNotificationList'; total: number }
  inProgressTasks: { __typename?: 'TaskNotificationList'; total: number }
  pendingTasks: { __typename?: 'TaskNotificationList'; total: number }
  solvedTasks: { __typename?: 'TaskNotificationList'; total: number }
  taskNotifications: {
    __typename?: 'TaskNotificationList'
    total: number
    tasks: Array<
      | {
          __typename?: 'HubServiceNotification'
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          vehicle: {
            __typename?: 'Vehicle'
            id: string
            label: number | null
            isWheelchairAccessible: boolean | null
            vehicleGroup: {
              __typename?: 'VehicleGroup'
              sdsProvider: Types.SdsProvider
            } | null
          } | null
          employee: {
            __typename?: 'Employee'
            id: string
            firstName: string | null
            lastName: string | null
            roles: Array<Types.Role>
            externalId: string | null
          } | null
          shift: { __typename?: 'Shift'; id: string } | null
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'IvcTaskNotification'
          timestamp: string
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          vehicle: {
            __typename?: 'Vehicle'
            id: string
            label: number | null
            isWheelchairAccessible: boolean | null
            currentlyLocatedInHub: {
              __typename?: 'Hub'
              displayName: string
            } | null
            vehicleGroup: {
              __typename?: 'VehicleGroup'
              sdsProvider: Types.SdsProvider
            } | null
          } | null
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'PassengerUnbuckled'
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'RoadBlockageTaskNotification'
          serviceAreaId: string | null
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          roadBlockage: {
            __typename?: 'RoadBlockage'
            id: string
            segments: Array<{ __typename?: 'RoadSegment'; name: string }>
          } | null
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'ShiftDeviationNotification'
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'StopAccessibilityDownvotedByDrivers'
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          stop: {
            __typename?: 'Stop'
            id: string
            names: Partial<Record<'de' | 'en', string>>
            serviceArea: { __typename?: 'ServiceArea'; id: string }
          } | null
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'StopActivationFailedNotification'
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          stop: {
            __typename?: 'Stop'
            id: string
            names: Partial<Record<'de' | 'en', string>>
            serviceArea: { __typename?: 'ServiceArea'; id: string }
          } | null
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'StopAreaMissingActiveStopsNotification'
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          area: {
            __typename?: 'Area'
            id: string
            name: string | null
            serviceArea: { __typename?: 'ServiceArea'; id: string }
          } | null
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'StopDownVotedByDrivers'
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          stop: {
            __typename?: 'Stop'
            id: string
            names: Partial<Record<'de' | 'en', string>>
            serviceArea: { __typename?: 'ServiceArea'; id: string }
          } | null
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'StopPotentiallyUnreachable'
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          stop: {
            __typename?: 'Stop'
            id: string
            names: Partial<Record<'de' | 'en', string>>
            serviceArea: { __typename?: 'ServiceArea'; id: string }
          } | null
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'StopValidationFailed'
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          stop: {
            __typename?: 'Stop'
            id: string
            names: Partial<Record<'de' | 'en', string>>
            serviceArea: { __typename?: 'ServiceArea'; id: string }
          } | null
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'TimeManagementNotification'
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          vehicle: {
            __typename?: 'Vehicle'
            id: string
            label: number | null
            isWheelchairAccessible: boolean | null
            vehicleGroup: {
              __typename?: 'VehicleGroup'
              sdsProvider: Types.SdsProvider
            } | null
          } | null
          employee: {
            __typename?: 'Employee'
            id: string
            firstName: string | null
            lastName: string | null
            roles: Array<Types.Role>
            externalId: string | null
            supervisorUser: {
              __typename?: 'BackofficeUser'
              id: string
              firstName: string
              lastName: string
            } | null
          } | null
          shift: {
            __typename?: 'Shift'
            id: string
            shiftType: Types.ShiftType
            plannedCheckIn: string
            shiftTypeOverrideDetails: {
              __typename?: 'ShiftTypeOverrideDetails'
              originalShiftType: Types.ShiftType
              overriddenAt: string
            } | null
          } | null
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'TripPrebookingNoAssignment'
          rejectionAt: string
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          trip: {
            __typename?: 'Trip'
            id: string
            pickup: {
              __typename?: 'TripTransferInformation'
              promisedTime: {
                __typename?: 'DateTimeInterval'
                from: string
                to: string
              }
            }
            delivery: {
              __typename?: 'TripTransferInformation'
              promisedTime: {
                __typename?: 'DateTimeInterval'
                from: string
                to: string
              }
            }
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              id: string
              lastName: string | null
              phoneNumber: string | null
              wheelchairAccess: boolean
            } | null
          } | null
          pickupStop: {
            __typename?: 'Stop'
            id: string
            names: Partial<Record<'de' | 'en', string>>
            address: {
              __typename?: 'StopAddress'
              city: string | null
              houseNumber: string | null
              postalCode: string | null
              street: string | null
            }
            customerDisplayLocation: {
              __typename?: 'Location'
              latitude: number
              longitude: number
            }
          } | null
          dropoffStop: {
            __typename?: 'Stop'
            id: string
            names: Partial<Record<'de' | 'en', string>>
            address: {
              __typename?: 'StopAddress'
              city: string | null
              houseNumber: string | null
              postalCode: string | null
              street: string | null
            }
            customerDisplayLocation: {
              __typename?: 'Location'
              latitude: number
              longitude: number
            }
          } | null
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'VehicleAvailabilityNotification'
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          vehicle: {
            __typename?: 'Vehicle'
            id: string
            label: number | null
            isWheelchairAccessible: boolean | null
            currentlyLocatedInHub: {
              __typename?: 'Hub'
              displayName: string
            } | null
            vehicleGroup: {
              __typename?: 'VehicleGroup'
              sdsProvider: Types.SdsProvider
            } | null
          } | null
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'VehicleIncidentNotification'
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'VehicleSurveillanceNotification'
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          vehicle: {
            __typename?: 'Vehicle'
            id: string
            label: number | null
            vehicleGroup: {
              __typename?: 'VehicleGroup'
              sdsProvider: Types.SdsProvider
            } | null
          } | null
          employee: {
            __typename?: 'Employee'
            id: string
            firstName: string | null
            lastName: string | null
            roles: Array<Types.Role>
          } | null
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
      | {
          __typename?: 'VehicleWaypointAttendance'
          id: string
          foreignId: string
          actionType: Types.TaskActionType
          severity: Types.TaskNotificationSeverity
          status: Types.TaskNotificationStatus
          createdAt: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            backofficeUserId: string | null
            to: Types.TaskNotificationStatus
            initiatedBySystem: boolean
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          pended: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
          } | null
          resolved: {
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
            backofficeUser: {
              __typename?: 'BackofficeUser'
              username: string
            } | null
          } | null
          note: {
            __typename?: 'TaskNotificationNote'
            note: string
            timestamp: string
          } | null
          stateChanges: Array<{
            __typename?: 'TaskStateChangeDetails'
            timestamp: string | null
            initiatedBySystem: boolean
            to: Types.TaskNotificationStatus
          }>
        }
    >
  }
}

export const TaskNotificationsDocument = gql`
    query taskNotifications($assignedToMe: Boolean, $serviceAreaUUID: ID!, $vehicleHubLocation: [ID!], $types: [TaskNotificationType!]!, $search: String, $sorting: [TaskNotificationSorting!], $status: [TaskNotificationStatus!], $pagination: PaginationInput) {
  newTasks: taskNotifications(
    search: $search
    serviceAreaUUID: $serviceAreaUUID
    vehicleHubLocation: $vehicleHubLocation
    status: [NEW]
    types: $types
  ) {
    total
  }
  myInProgressTasks: taskNotifications(
    assignedToMe: true
    search: $search
    serviceAreaUUID: $serviceAreaUUID
    vehicleHubLocation: $vehicleHubLocation
    status: [IN_PROGRESS]
    types: $types
  ) {
    total
  }
  inProgressTasks: taskNotifications(
    assignedToMe: false
    search: $search
    serviceAreaUUID: $serviceAreaUUID
    vehicleHubLocation: $vehicleHubLocation
    status: [IN_PROGRESS]
    types: $types
  ) {
    total
  }
  pendingTasks: taskNotifications(
    search: $search
    serviceAreaUUID: $serviceAreaUUID
    vehicleHubLocation: $vehicleHubLocation
    status: [PENDING]
    types: $types
  ) {
    total
  }
  solvedTasks: taskNotifications(
    search: $search
    serviceAreaUUID: $serviceAreaUUID
    vehicleHubLocation: $vehicleHubLocation
    status: [SOLVED]
    types: $types
  ) {
    total
  }
  taskNotifications(
    assignedToMe: $assignedToMe
    search: $search
    serviceAreaUUID: $serviceAreaUUID
    vehicleHubLocation: $vehicleHubLocation
    sorting: $sorting
    types: $types
    status: $status
    pagination: $pagination
  ) {
    tasks {
      id
      foreignId
      actionType
      severity
      status
      createdAt
      notificationType
      assigned {
        timestamp
        backofficeUser {
          username
        }
        backofficeUserId
        to
        initiatedBySystem
      }
      pended {
        timestamp
      }
      resolved {
        timestamp
        backofficeUser {
          username
        }
        initiatedBySystem
        to
      }
      note {
        note
        timestamp
      }
      stateChanges {
        timestamp
        initiatedBySystem
        to
      }
      ... on VehicleAvailabilityNotification {
        vehicle {
          id
          label
          currentlyLocatedInHub {
            displayName
          }
          isWheelchairAccessible
          vehicleGroup {
            sdsProvider
          }
        }
      }
      ... on VehicleSurveillanceNotification {
        vehicle {
          id
          label
          vehicleGroup {
            sdsProvider
          }
        }
        employee {
          id
          firstName
          lastName
          roles
        }
      }
      ... on HubServiceNotification {
        vehicle {
          id
          label
          isWheelchairAccessible
          vehicleGroup {
            sdsProvider
          }
        }
        employee {
          id
          firstName
          lastName
          roles
          externalId
        }
        shift {
          id
        }
      }
      ... on TimeManagementNotification {
        vehicle {
          id
          label
          isWheelchairAccessible
          vehicleGroup {
            sdsProvider
          }
        }
        employee {
          id
          firstName
          lastName
          roles
          externalId
          supervisorUser {
            ...backofficeUser
          }
        }
        shift {
          id
          shiftType
          plannedCheckIn
          shiftTypeOverrideDetails {
            originalShiftType
            overriddenAt
          }
        }
      }
      ... on StopActivationFailedNotification {
        stop {
          id
          names
          serviceArea {
            id
          }
        }
      }
      ... on StopDownVotedByDrivers {
        stop {
          id
          names
          serviceArea {
            id
          }
        }
      }
      ... on StopAccessibilityDownvotedByDrivers {
        stop {
          id
          names
          serviceArea {
            id
          }
        }
      }
      ... on StopPotentiallyUnreachable {
        stop {
          id
          names
          serviceArea {
            id
          }
        }
      }
      ... on StopValidationFailed {
        stop {
          id
          names
          serviceArea {
            id
          }
        }
      }
      ... on StopAreaMissingActiveStopsNotification {
        area {
          id
          name
          serviceArea {
            id
          }
        }
      }
      ... on TripPrebookingNoAssignment {
        trip {
          id
          pickup {
            promisedTime {
              from
              to
            }
          }
          delivery {
            promisedTime {
              from
              to
            }
          }
          customer {
            firstName
            id
            lastName
            phoneNumber
            wheelchairAccess
          }
        }
        rejectionAt
        pickupStop {
          id
          address {
            city
            houseNumber
            postalCode
            street
          }
          names
          customerDisplayLocation {
            latitude
            longitude
          }
        }
        dropoffStop {
          id
          address {
            city
            houseNumber
            postalCode
            street
          }
          names
          customerDisplayLocation {
            latitude
            longitude
          }
        }
      }
      ... on IvcTaskNotification {
        vehicle {
          id
          label
          currentlyLocatedInHub {
            displayName
          }
          isWheelchairAccessible
          vehicleGroup {
            sdsProvider
          }
        }
        timestamp
      }
      ... on RoadBlockageTaskNotification {
        serviceAreaId
        roadBlockage {
          id
          segments {
            name
          }
        }
      }
    }
    total
  }
}
    ${BackofficeUserFragmentDoc}`

/**
 * __useTaskNotificationsQuery__
 *
 * To run a query within a React component, call `useTaskNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskNotificationsQuery({
 *   variables: {
 *      assignedToMe: // value for 'assignedToMe'
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *      vehicleHubLocation: // value for 'vehicleHubLocation'
 *      types: // value for 'types'
 *      search: // value for 'search'
 *      sorting: // value for 'sorting'
 *      status: // value for 'status'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTaskNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaskNotificationsQuery,
    TaskNotificationsQueryVariables
  > &
    (
      | { variables: TaskNotificationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TaskNotificationsQuery,
    TaskNotificationsQueryVariables
  >(TaskNotificationsDocument, options)
}
export function useTaskNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskNotificationsQuery,
    TaskNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TaskNotificationsQuery,
    TaskNotificationsQueryVariables
  >(TaskNotificationsDocument, options)
}
export function useTaskNotificationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TaskNotificationsQuery,
        TaskNotificationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    TaskNotificationsQuery,
    TaskNotificationsQueryVariables
  >(TaskNotificationsDocument, options)
}
export type TaskNotificationsQueryHookResult = ReturnType<
  typeof useTaskNotificationsQuery
>
export type TaskNotificationsLazyQueryHookResult = ReturnType<
  typeof useTaskNotificationsLazyQuery
>
export type TaskNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useTaskNotificationsSuspenseQuery
>
export type TaskNotificationsQueryResult = Apollo.QueryResult<
  TaskNotificationsQuery,
  TaskNotificationsQueryVariables
>
