import { CapacityPlanningAreaRoutes } from '@backoffice-frontend/common'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { NavigationItem } from '@backoffice-frontend/patterns'
import { ClipboardChartIcon } from '@moia-dev/pace-icons'

export const CapacityPlanningAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={CapacityPlanningAreaRoutes.route}
      Icon={ClipboardChartIcon}
    />
  )
}
