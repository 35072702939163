import { useErrorHandlingQuery } from '@backoffice-frontend/data-fetching'
import { useGlobalServiceAreaIdsQuery } from './globalServiceAreaIds.hook'
import { useMoiaSelectedServiceArea } from './useSelectedServiceArea'

// This lives here because it used in various areas. One can try moving this to
// the `sam-common` lib but beware or circular dependency shenanigans.
export const useGlobalServiceAreaIds = (): [
  {
    uuid: string | undefined
    id: string | undefined
  },
  boolean,
] => {
  const { serviceAreaId } = useMoiaSelectedServiceArea()

  const { data, loading } = useErrorHandlingQuery(
    useGlobalServiceAreaIdsQuery,
    serviceAreaId ? { variables: { serviceAreaId } } : { skip: true },
  )

  return [
    { uuid: data?.serviceAreaById?.uuid, id: data?.serviceAreaById?.id },
    loading,
  ]
}
