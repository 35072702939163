import type { BackofficeUpdate } from '@backoffice-frontend/graphql'
import { BackofficeUpdateType } from '@backoffice-frontend/graphql'
import {
  FormChipsComboBox,
  MoiaFormDropdown,
  MoiaFormTextArea,
  MoiaFormTextField,
  MoiaGrid,
} from '@backoffice-frontend/patterns'
import {
  composeValidators,
  useValidators,
} from '@backoffice-frontend/validators'
import { BoumRightEnDeCoder } from '@moia-dev/moia-token-claims'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useTranslation } from 'react-i18next'
import { UpdatesFormFieldWrapper } from './UpdatesFormFieldWrapper'
import { getTranslatedType } from './translateBackofficeUpdates'

export type BackofficeUpdatesFormInput = Pick<
  BackofficeUpdate,
  | 'type'
  | 'rights'
  | 'titleEn'
  | 'titleDe'
  | 'notesDe'
  | 'notesEn'
  | 'images'
  | 'videoDe'
  | 'videoEn'
>
type BackofficeUpdatesFormProps = {
  values: Omit<BackofficeUpdatesFormInput, 'images' | 'videos'>
}

export const BackofficeUpdatesTextForm = ({
  values,
}: BackofficeUpdatesFormProps) => {
  const { t } = useTranslation()

  const TITLE_LENGTH = 100
  const NOTES_LENGTH = 1000

  const types = Object.values(BackofficeUpdateType)
  const rights = BoumRightEnDeCoder.values()

  const { required, requiredArrayElement } = useValidators()

  return (
    <>
      <UpdatesFormFieldWrapper title={t('common_type_label')} hasSeparator>
        <Field
          component={MoiaFormDropdown}
          label={t('updates_dialog_updateType_label')}
          name="type"
          id="type"
          required
          options={types.map(type => ({
            value: type,
            label: getTranslatedType(t, type),
          }))}
          validate={composeValidators([required])}
        />
      </UpdatesFormFieldWrapper>
      <UpdatesFormFieldWrapper
        title={t('updates_dialog_affectedUsers_header')}
        tooltipText={{
          title: t('updates_tooltip_affectedUsersTitle'),
          paragraphs: [t('updates_tooltip_affectedUsersDescription')],
        }}
        hasSeparator
      >
        <FieldArray name="rights" required validate={requiredArrayElement}>
          {props => (
            <FormChipsComboBox
              options={rights}
              required
              label={t('updates_dialog_affectedUsers_label')}
              {...props}
            />
          )}
        </FieldArray>
      </UpdatesFormFieldWrapper>
      <UpdatesFormFieldWrapper
        title={t('updates_dialog_updateTitle_header')}
        tooltipText={{
          title: t('updates_tooltip_updateTitle'),
        }}
        hasSeparator
      >
        <MoiaGrid>
          <Field
            component={MoiaFormTextField}
            required
            label={t('updates_dialog_updateTitleEnglish_label')}
            name="titleEn"
            id="titleEn"
            inputProps={{ maxLength: TITLE_LENGTH }}
            validate={composeValidators([required])}
            helperText={t('common_charactersLeft_label', {
              length: values.titleEn
                ? TITLE_LENGTH - values.titleEn.length
                : TITLE_LENGTH,
            })}
          />
          <Field
            component={MoiaFormTextField}
            required
            label={t('updates_dialog_updateTitleGerman_label')}
            name="titleDe"
            id="titleDe"
            inputProps={{ maxLength: TITLE_LENGTH }}
            validate={composeValidators([required])}
            helperText={t('common_charactersLeft_label', {
              length: values.titleDe
                ? TITLE_LENGTH - values.titleDe.length
                : TITLE_LENGTH,
            })}
          />
        </MoiaGrid>
      </UpdatesFormFieldWrapper>
      <UpdatesFormFieldWrapper
        title={t('updates_dialog_updateDescription_header')}
        tooltipText={{
          title: t('updates_tooltip_updateDescription'),
          paragraphs: [
            t('common_textformattingBullet'),
            t('common_textformattingBold'),
            t('common_textformattingStrikethrough'),
          ],
        }}
      >
        <MoiaGrid gridGap={4}>
          <Field
            component={MoiaFormTextArea}
            required
            multiline
            rows={8}
            variant="outlined"
            label={t('updates_dialog_updateDescriptionEnglish_label')}
            name="notesEn"
            id="notesEn"
            inputProps={{ maxLength: NOTES_LENGTH }}
            validate={composeValidators([required])}
            helperText={t('common_charactersLeft_label', {
              length: values.notesEn
                ? NOTES_LENGTH - values.notesEn.length
                : NOTES_LENGTH,
            })}
          />
          <Field
            component={MoiaFormTextArea}
            required
            multiline
            rows={8}
            variant="outlined"
            label={t('updates_dialog_updateDescriptionGerman_label')}
            name="notesDe"
            id="notesDe"
            inputProps={{ maxLength: NOTES_LENGTH }}
            validate={composeValidators([required])}
            helperText={t('common_charactersLeft_label', {
              length: values.notesDe
                ? NOTES_LENGTH - values.notesDe.length
                : NOTES_LENGTH,
            })}
          />
        </MoiaGrid>
      </UpdatesFormFieldWrapper>
    </>
  )
}
