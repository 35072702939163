import {
  ENV,
  Environment,
  mockServerPort,
} from '@backoffice-frontend/environment'

const CustomerFeatureAllowlistingStages: Record<Environment, string> = {
  [Environment.local]: `http://localhost:${mockServerPort}/api/customer-feature-allowlisting`,
  [Environment.test]: `http://localhost:${mockServerPort}/api/customer-feature-allowlisting`,
  [Environment.development]: `https://cfa-admin.mobile-platform.dev.moia-group.io/bofe`,
  [Environment.integration]: `https://cfa-admin.mobile-platform.int.moia-group.io/bofe`,
  [Environment.production]: `https://cfa-admin.mobile-platform.prd.moia-group.io/bofe`,
}

const Host = CustomerFeatureAllowlistingStages[ENV]

export const CustomerFeatureAllowlistingEndpoints = {
  ListFeatures: `${Host}/features`,
  CreateFeature: `${Host}/features`,
  UpdateFeature: `${Host}/features/:featureId`,
  DeleteFeature: `${Host}/features/:featureId`,
  CreatePermission: `${Host}/permissions/create`,
  DeletePermission: `${Host}/permissions/delete`,
  ListFeaturePermissions: `${Host}/permissions?featureId=:featureId`,
  ListCustomerPermissions: `${Host}/permissions?customerId=:customerId`,
  ListCustomerFeatures: `${Host}/customer_features`,
  ListFeatureCustomers: `${Host}/feature_customers`,
}
