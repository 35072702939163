import type { TypePolicies } from '@apollo/client'
import { gql, makeVar, useQuery } from '@apollo/client'

// The return value of makeVar is a function that you call to read or modify your reactive variable's value.
export const searchQueryVar = makeVar('')
export const searchDisabledVar = makeVar(false)

export const QUERY_MOIA_HEADER_SEARCH_QUERY = gql`
  query getMoiaHeaderSearchQuery {
    getMoiaHeaderSearchQuery @client {
      searchQuery
      searchDisabled
    }
  }
`

export const MoiaHeaderSearchTypePolicies: TypePolicies = {
  Query: {
    fields: {
      getMoiaHeaderSearchQuery: {
        read(): { searchQuery: string; searchDisabled: boolean } {
          return {
            searchQuery: searchQueryVar(),
            searchDisabled: searchDisabledVar(),
          }
        },
      },
    },
  },
}

export const useMoiaDebouncedHeaderSearch = (): string => {
  const { data } = useQuery(QUERY_MOIA_HEADER_SEARCH_QUERY)
  return data.getMoiaHeaderSearchQuery.searchQuery.trim()
}
