import { refreshClaims } from '@backoffice-frontend/common'
import { useErrorHandlingMutation } from '@backoffice-frontend/data-fetching'
import {
  List,
  ListItem,
  ListItemText,
  MoiaButton,
  MoiaDialog,
  MoiaDialogContent,
  MoiaDialogHeader,
  useMoiaErrorNotification,
  useMoiaSuccessNotification,
} from '@backoffice-frontend/patterns'
import { useClaims } from '@backoffice-frontend/restricted'
import type { BackofficeGroups } from '@backoffice-frontend/user-management-ui'
import {
  useBackofficeGroups,
  useBackofficeLoggedInUser,
} from '@backoffice-frontend/user-management-ui'
import { BoumRight, BoumRightEnDeCoder } from '@moia-dev/moia-token-claims'
import { XIcon } from '@moia-dev/pace-icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ResetUserButton, persistUser } from './ResetUser'
import { useBackofficeUserManagementSwapGroupsMutation } from './swapUserWithGroups.hook'

const getGroupsByRight = (groups: BackofficeGroups, rightName: string) =>
  groups.filter(group => group.rights?.some(right => right === rightName))

type RightNamesByAreas =
  | 'AREA_EMPLOYEES'
  | 'AREA_VEHICLES'
  | 'AREA_VEHICLE_GROUPS'
  | 'AREA_CUSTOM_ROAD_BLOCKAGES'
  | 'AREA_CUSTOMERS'
  | 'AREA_SERVICE_AREA'
  | 'AREA_ALERTS_TESTING'

const getPathName = (): RightNamesByAreas | string => {
  const href = window.location.pathname.split('/')
  if (href[1] === 'sam') {
    return href[2]
  }
  return href[1]
}
const getRightName = () => {
  const rightName = `AREA_${getPathName()
    .split('-')
    .map(e => e.toUpperCase())
    .join('_')}`
  const d = (f: BoumRight) => BoumRightEnDeCoder.fromEnum(f)!
  switch (rightName) {
    case 'AREA_EMPLOYEES':
      return d(BoumRight.AREA_EMPLOYEE)
    case 'AREA_VEHICLES':
      return d(BoumRight.AREA_VEHICLE)
    case 'AREA_VEHICLE_GROUPS':
      return d(BoumRight.AREA_VEHICLE_GROUP)
    case 'AREA_CUSTOM_ROAD_BLOCKAGES':
      return d(BoumRight.AREA_CUSTOM_STREET_ROUTING)
    case 'AREA_CUSTOMERS':
      return d(BoumRight.AREA_CUSTOMER)
    case 'AREA_SERVICE_AREA':
      return d(BoumRight.AREA_SERVICE_AREAS)
    case 'AREA_ALERTS_TESTING':
      return d(BoumRight.AREA_TEST)
    default:
      return rightName
  }
}

export const ListOfAvailabilityGroups = ({
  isOpen,
  toggleOpen,
}: {
  isOpen: boolean
  toggleOpen: () => void
}) => {
  const { t } = useTranslation()
  const [swapGroups] = useErrorHandlingMutation(
    useBackofficeUserManagementSwapGroupsMutation,
  )
  const [groups, setGroups] = useState<BackofficeGroups>([])
  const { enqueueMoiaSuccessNotification } = useMoiaSuccessNotification()
  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()
  const { setClaims } = useClaims()

  const { data } = useBackofficeGroups()
  const { user } = useBackofficeLoggedInUser()
  const userGroups = user?.timedGroups
  persistUser(user)

  const rightName = getRightName()

  useEffect(() => {
    if (data) {
      const groups = getGroupsByRight(data, rightName)
      setGroups(groups)
    }
  }, [data, rightName])

  const isUserAddedToGroup = (groupName: string) =>
    userGroups?.some(group => group?.groupName === groupName)

  const handleRefreshToken = async () => {
    try {
      const claims = await refreshClaims()
      if (claims) {
        setClaims(claims)
        // reload page to update JWT token
        window.location.reload()
      } else {
        enqueueMoiaErrorNotification(
          t('authenticatedAppView_rightsAndPermissionRefreshFailed'),
        )
      }
    } catch {
      enqueueMoiaErrorNotification(
        t('authenticatedAppView_rightsAndPermissionRefreshFailed'),
      )
    }
  }

  const handleAssignGroup = async (groupName: string) => {
    try {
      await swapGroups({
        variables: {
          input: {
            groupName: null,
            groupsList: [groupName],
          },
        },
      })
      enqueueMoiaSuccessNotification(
        t('authenticatedAppView_groupsSwappedSuccess'),
      )
      toggleOpen()
      await handleRefreshToken()
    } catch (e) {
      enqueueMoiaErrorNotification(e)
    }
  }

  const visibleGroups = groups.length > 0 ? groups : data
  if (!visibleGroups) {
    return null
  }
  return (
    <MoiaDialog onClose={toggleOpen} open={isOpen}>
      <MoiaDialogHeader
        title={t('authenticatedAppView_groupsWithAccess_header')}
        action={<XIcon onClick={toggleOpen} />}
      />
      <MoiaDialogContent>
        <List component="div" disablePadding>
          <ResetUserButton handleRefreshToken={handleRefreshToken} />
          {visibleGroups.map(group => (
            <ListItem
              selected={isUserAddedToGroup(group.name)}
              sx={{
                '& button': {
                  opacity: 0,
                },
                '&:hover': {
                  '& button': {
                    opacity: 1,
                  },
                },
              }}
              key={group.name}
            >
              <ListItemText primary={group.name} />
              <MoiaButton
                color="primary"
                onClick={() => handleAssignGroup(group.name)}
              >
                {t('authenticatedAppView_applyGroup_button')}
              </MoiaButton>
            </ListItem>
          ))}
        </List>
      </MoiaDialogContent>
    </MoiaDialog>
  )
}
