/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type AdVehicleStreamServiceCancellationQueryVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type AdVehicleStreamServiceCancellationQuery = {
  __typename?: 'Query'
  vehicleByID: {
    __typename?: 'Vehicle'
    id: string
    fccCurrentAdAlerts: {
      __typename?: 'FccAdAlerts'
      alerts: Array<{
        __typename?: 'FccAdAlert'
        id: string
        type: Types.FccAdAlertType
        status: Types.FccAdAlertStatus
      }>
    } | null
  }
}

export type AdVehicleStreamServiceCancellationStateSubscriptionVariables =
  Types.Exact<{
    vehicleId: Types.Scalars['String']['input']
  }>

export type AdVehicleStreamServiceCancellationStateSubscription = {
  __typename?: 'Subscription'
  fccServiceCancellationState: {
    __typename?: 'FccServiceCancellationState'
    vehicleId: string
    state: Types.FccServiceCancellationStateEnum | null
    cancellationError: {
      __typename?: 'FccServiceCancellationError'
      code: Types.FccServiceCancellationErrorCode
    } | null
  }
}

export type AdVehicleStreamInitiateCancelAllTripsMutationVariables =
  Types.Exact<{
    vehicleId: Types.Scalars['ID']['input']
    incidentId?: Types.InputMaybe<Types.Scalars['ID']['input']>
  }>

export type AdVehicleStreamInitiateCancelAllTripsMutation = {
  __typename?: 'Mutation'
  fccInitiateCancelAllTrips: any | null
}

export type AdVehicleStreamConfirmCancelAllTripsMutationVariables =
  Types.Exact<{
    vehicleId: Types.Scalars['ID']['input']
  }>

export type AdVehicleStreamConfirmCancelAllTripsMutation = {
  __typename?: 'Mutation'
  fccConfirmCancelAllTrips: any | null
}

export type AdVehicleStreamContinueTripsMutationVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
  incidentId: Types.Scalars['ID']['input']
}>

export type AdVehicleStreamContinueTripsMutation = {
  __typename?: 'Mutation'
  fccContinueTrips: any | null
}

export type AdVehicleStreamServiceCancellationVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  fccCurrentAdAlerts: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename?: 'FccAdAlert'
      id: string
      type: Types.FccAdAlertType
      status: Types.FccAdAlertStatus
    }>
  } | null
}

export type AdVehicleStreamServiceCancellationAlertFragment = {
  __typename?: 'FccAdAlert'
  id: string
  type: Types.FccAdAlertType
  status: Types.FccAdAlertStatus
}

export const AdVehicleStreamServiceCancellationAlertFragmentDoc = gql`
    fragment AdVehicleStreamServiceCancellationAlert on FccAdAlert {
  id
  type
  status
}
    `
export const AdVehicleStreamServiceCancellationVehicleFragmentDoc = gql`
    fragment AdVehicleStreamServiceCancellationVehicle on Vehicle {
  id
  fccCurrentAdAlerts {
    alerts {
      ...AdVehicleStreamServiceCancellationAlert
    }
  }
}
    ${AdVehicleStreamServiceCancellationAlertFragmentDoc}`
export const AdVehicleStreamServiceCancellationDocument = gql`
    query AdVehicleStreamServiceCancellation($vehicleId: ID!) {
  vehicleByID(id: $vehicleId) {
    ...AdVehicleStreamServiceCancellationVehicle
  }
}
    ${AdVehicleStreamServiceCancellationVehicleFragmentDoc}`

/**
 * __useAdVehicleStreamServiceCancellationQuery__
 *
 * To run a query within a React component, call `useAdVehicleStreamServiceCancellationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdVehicleStreamServiceCancellationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdVehicleStreamServiceCancellationQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAdVehicleStreamServiceCancellationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdVehicleStreamServiceCancellationQuery,
    AdVehicleStreamServiceCancellationQueryVariables
  > &
    (
      | {
          variables: AdVehicleStreamServiceCancellationQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdVehicleStreamServiceCancellationQuery,
    AdVehicleStreamServiceCancellationQueryVariables
  >(AdVehicleStreamServiceCancellationDocument, options)
}
export function useAdVehicleStreamServiceCancellationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdVehicleStreamServiceCancellationQuery,
    AdVehicleStreamServiceCancellationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdVehicleStreamServiceCancellationQuery,
    AdVehicleStreamServiceCancellationQueryVariables
  >(AdVehicleStreamServiceCancellationDocument, options)
}
export function useAdVehicleStreamServiceCancellationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdVehicleStreamServiceCancellationQuery,
        AdVehicleStreamServiceCancellationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AdVehicleStreamServiceCancellationQuery,
    AdVehicleStreamServiceCancellationQueryVariables
  >(AdVehicleStreamServiceCancellationDocument, options)
}
export type AdVehicleStreamServiceCancellationQueryHookResult = ReturnType<
  typeof useAdVehicleStreamServiceCancellationQuery
>
export type AdVehicleStreamServiceCancellationLazyQueryHookResult = ReturnType<
  typeof useAdVehicleStreamServiceCancellationLazyQuery
>
export type AdVehicleStreamServiceCancellationSuspenseQueryHookResult =
  ReturnType<typeof useAdVehicleStreamServiceCancellationSuspenseQuery>
export type AdVehicleStreamServiceCancellationQueryResult = Apollo.QueryResult<
  AdVehicleStreamServiceCancellationQuery,
  AdVehicleStreamServiceCancellationQueryVariables
>
export const AdVehicleStreamServiceCancellationStateDocument = gql`
    subscription AdVehicleStreamServiceCancellationState($vehicleId: String!) {
  fccServiceCancellationState(vehicleId: $vehicleId) {
    vehicleId
    state
    cancellationError {
      code
    }
  }
}
    `

/**
 * __useAdVehicleStreamServiceCancellationStateSubscription__
 *
 * To run a query within a React component, call `useAdVehicleStreamServiceCancellationStateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAdVehicleStreamServiceCancellationStateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdVehicleStreamServiceCancellationStateSubscription({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAdVehicleStreamServiceCancellationStateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    AdVehicleStreamServiceCancellationStateSubscription,
    AdVehicleStreamServiceCancellationStateSubscriptionVariables
  > &
    (
      | {
          variables: AdVehicleStreamServiceCancellationStateSubscriptionVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    AdVehicleStreamServiceCancellationStateSubscription,
    AdVehicleStreamServiceCancellationStateSubscriptionVariables
  >(AdVehicleStreamServiceCancellationStateDocument, options)
}
export type AdVehicleStreamServiceCancellationStateSubscriptionHookResult =
  ReturnType<typeof useAdVehicleStreamServiceCancellationStateSubscription>
export type AdVehicleStreamServiceCancellationStateSubscriptionResult =
  Apollo.SubscriptionResult<AdVehicleStreamServiceCancellationStateSubscription>
export const AdVehicleStreamInitiateCancelAllTripsDocument = gql`
    mutation AdVehicleStreamInitiateCancelAllTrips($vehicleId: ID!, $incidentId: ID) {
  fccInitiateCancelAllTrips(
    input: {vehicleId: $vehicleId, incidentId: $incidentId}
  )
}
    `
export type AdVehicleStreamInitiateCancelAllTripsMutationFn =
  Apollo.MutationFunction<
    AdVehicleStreamInitiateCancelAllTripsMutation,
    AdVehicleStreamInitiateCancelAllTripsMutationVariables
  >

/**
 * __useAdVehicleStreamInitiateCancelAllTripsMutation__
 *
 * To run a mutation, you first call `useAdVehicleStreamInitiateCancelAllTripsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdVehicleStreamInitiateCancelAllTripsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adVehicleStreamInitiateCancelAllTripsMutation, { data, loading, error }] = useAdVehicleStreamInitiateCancelAllTripsMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      incidentId: // value for 'incidentId'
 *   },
 * });
 */
export function useAdVehicleStreamInitiateCancelAllTripsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdVehicleStreamInitiateCancelAllTripsMutation,
    AdVehicleStreamInitiateCancelAllTripsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdVehicleStreamInitiateCancelAllTripsMutation,
    AdVehicleStreamInitiateCancelAllTripsMutationVariables
  >(AdVehicleStreamInitiateCancelAllTripsDocument, options)
}
export type AdVehicleStreamInitiateCancelAllTripsMutationHookResult =
  ReturnType<typeof useAdVehicleStreamInitiateCancelAllTripsMutation>
export type AdVehicleStreamInitiateCancelAllTripsMutationResult =
  Apollo.MutationResult<AdVehicleStreamInitiateCancelAllTripsMutation>
export type AdVehicleStreamInitiateCancelAllTripsMutationOptions =
  Apollo.BaseMutationOptions<
    AdVehicleStreamInitiateCancelAllTripsMutation,
    AdVehicleStreamInitiateCancelAllTripsMutationVariables
  >
export const AdVehicleStreamConfirmCancelAllTripsDocument = gql`
    mutation AdVehicleStreamConfirmCancelAllTrips($vehicleId: ID!) {
  fccConfirmCancelAllTrips(input: {vehicleId: $vehicleId})
}
    `
export type AdVehicleStreamConfirmCancelAllTripsMutationFn =
  Apollo.MutationFunction<
    AdVehicleStreamConfirmCancelAllTripsMutation,
    AdVehicleStreamConfirmCancelAllTripsMutationVariables
  >

/**
 * __useAdVehicleStreamConfirmCancelAllTripsMutation__
 *
 * To run a mutation, you first call `useAdVehicleStreamConfirmCancelAllTripsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdVehicleStreamConfirmCancelAllTripsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adVehicleStreamConfirmCancelAllTripsMutation, { data, loading, error }] = useAdVehicleStreamConfirmCancelAllTripsMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAdVehicleStreamConfirmCancelAllTripsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdVehicleStreamConfirmCancelAllTripsMutation,
    AdVehicleStreamConfirmCancelAllTripsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdVehicleStreamConfirmCancelAllTripsMutation,
    AdVehicleStreamConfirmCancelAllTripsMutationVariables
  >(AdVehicleStreamConfirmCancelAllTripsDocument, options)
}
export type AdVehicleStreamConfirmCancelAllTripsMutationHookResult = ReturnType<
  typeof useAdVehicleStreamConfirmCancelAllTripsMutation
>
export type AdVehicleStreamConfirmCancelAllTripsMutationResult =
  Apollo.MutationResult<AdVehicleStreamConfirmCancelAllTripsMutation>
export type AdVehicleStreamConfirmCancelAllTripsMutationOptions =
  Apollo.BaseMutationOptions<
    AdVehicleStreamConfirmCancelAllTripsMutation,
    AdVehicleStreamConfirmCancelAllTripsMutationVariables
  >
export const AdVehicleStreamContinueTripsDocument = gql`
    mutation AdVehicleStreamContinueTrips($vehicleId: ID!, $incidentId: ID!) {
  fccContinueTrips(input: {vehicleId: $vehicleId, incidentId: $incidentId})
}
    `
export type AdVehicleStreamContinueTripsMutationFn = Apollo.MutationFunction<
  AdVehicleStreamContinueTripsMutation,
  AdVehicleStreamContinueTripsMutationVariables
>

/**
 * __useAdVehicleStreamContinueTripsMutation__
 *
 * To run a mutation, you first call `useAdVehicleStreamContinueTripsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdVehicleStreamContinueTripsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adVehicleStreamContinueTripsMutation, { data, loading, error }] = useAdVehicleStreamContinueTripsMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      incidentId: // value for 'incidentId'
 *   },
 * });
 */
export function useAdVehicleStreamContinueTripsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdVehicleStreamContinueTripsMutation,
    AdVehicleStreamContinueTripsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdVehicleStreamContinueTripsMutation,
    AdVehicleStreamContinueTripsMutationVariables
  >(AdVehicleStreamContinueTripsDocument, options)
}
export type AdVehicleStreamContinueTripsMutationHookResult = ReturnType<
  typeof useAdVehicleStreamContinueTripsMutation
>
export type AdVehicleStreamContinueTripsMutationResult =
  Apollo.MutationResult<AdVehicleStreamContinueTripsMutation>
export type AdVehicleStreamContinueTripsMutationOptions =
  Apollo.BaseMutationOptions<
    AdVehicleStreamContinueTripsMutation,
    AdVehicleStreamContinueTripsMutationVariables
  >
