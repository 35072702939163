import type { CheckboxProps, FormControlLabelProps } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

export type MoiaCheckboxProps = CheckboxProps & {
  dataTestId?: string
}

/**
 * @deprecated Use Checkbox from "@moia-dev/pace-web" instead.
 */
export const MoiaCheckbox = ({ dataTestId, ...props }: MoiaCheckboxProps) => (
  <Checkbox
    css={theme => ({
      color: theme.semantic.ColorBorderOnControlDefault,
      padding: '4px',
      '&.Mui-checked': { color: theme.semantic.ColorActionPrimaryAlternative },
      '&.MuiCheckbox-indeterminate': {
        color: theme.semantic.ColorActionPrimaryAlternative,
      },
      '&.Mui-disabled.Mui-checked': {
        color: theme.semantic.ColorActionPrimaryAlternativeDisabled,
      },
      '&.Mui-disabled': {
        color: theme.semantic.ColorBorderOnControlDisabled,
      },
    })}
    data-testid={dataTestId}
    {...props}
  />
)

export type MoiaLabelCheckboxProps = MoiaCheckboxProps &
  Pick<FormControlLabelProps, 'label' | 'labelPlacement'>

/**
 * @deprecated Use FormField from "@moia-dev/pace-web" instead.
 */
export const MoiaLabelCheckbox = ({
  label,
  labelPlacement,
  ...props
}: MoiaLabelCheckboxProps) => (
  <FormControlLabel
    control={<MoiaCheckbox {...props} />}
    label={label}
    labelPlacement={labelPlacement}
  />
)
