/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type VehicleConfigFragment = {
  __typename?: 'VehicleGroup'
  boxProvider: Types.BoxProvider
  displayName: string
  engineType: Types.VehicleEngineType
  groupType: Types.VehicleGroupType
  id: string
  isWheelchairAccessible: boolean
  minStateOfCharge: number
  sdsProvider: Types.SdsProvider
  seats: number
  tenant: string
  totalVehicles: number
  wheelchairSeats: number
  childSeatSpecs: Array<{
    __typename?: 'ChildSeatSpec'
    seatNumber: number
    isBoosterSeat: boolean
    isChildSeat: boolean
    isAvailable: boolean
  }>
  serviceAreas: Array<{
    __typename?: 'ServiceArea'
    uuid: string
    id: string
    displayNames: Partial<Record<'de' | 'en', string>>
  } | null>
}

export const VehicleConfigFragmentDoc = gql`
    fragment VehicleConfig on VehicleGroup {
  boxProvider
  childSeatSpecs {
    seatNumber
    isBoosterSeat
    isChildSeat
    isAvailable
  }
  displayName
  engineType
  groupType
  id
  isWheelchairAccessible
  minStateOfCharge
  sdsProvider
  seats
  serviceAreas {
    uuid
    id
    displayNames
  }
  tenant
  totalVehicles
  wheelchairSeats
}
    `
