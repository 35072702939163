/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import {
  HasHubReturnErrorFragmentDoc,
  HasHubReturnNextFragmentDoc,
  IsLowOnEnergyFragmentDoc,
  IsOverheatingFragmentDoc,
  IsRebalancingFragmentDoc,
  IsSubCooledFragmentDoc,
} from '../../../../../vehicle-common/src/lib/utils/vehicleValidators.hook'
import {
  AlertLabelAdAlertFragmentDoc,
  AlertLabelTaskFragmentDoc,
} from './AlertLabel.hook'
import {
  VehicleDestinationMarkerDeliveryActionFragmentDoc,
  VehicleDestinationMarkerHubReturnActionFragmentDoc,
  VehicleDestinationMarkerPickupActionFragmentDoc,
  VehicleDestinationMarkerReallocationActionFragmentDoc,
} from './VehicleDestinationMarker.hook'
export type VehicleMarkerVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  inService: boolean | null
  isWheelchairAccessible: boolean | null
  label: number | null
  totalWheelchairUsersInVehicle: number | null
  openedBy: Types.VehicleInteractionUserType | null
  engineType: Types.VehicleEngineType | null
  highVoltageBatteryLevel: number | null
  interiorTemperature: number | null
  latestLocation: {
    __typename?: 'VehicleLocation'
    heading: number | null
    location: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    } | null
  } | null
  fccUpcomingWaypoints: Array<{
    __typename?: 'FccWaypoint'
    actions: Array<
      | {
          __typename: 'FccWaypointDeliveryAction'
          estimatedAt: string
          seats: { __typename?: 'FccWaypointSeatInfo'; wheelchairs: number }
        }
      | {
          __typename: 'FccWaypointHubReturnAction'
          isPlannedHubReturn: boolean
          reason: Types.FccWaypointHubReturnReason
          estimatedAt: string
        }
      | {
          __typename: 'FccWaypointPickupAction'
          estimatedAt: string
          seats: { __typename?: 'FccWaypointSeatInfo'; wheelchairs: number }
        }
      | { __typename: 'FccWaypointReallocationAction'; estimatedAt: string }
    >
  }> | null
  pooling: { __typename?: 'Pooling'; reason: Types.PoolingReason | null } | null
  currentlyLocatedInHub: { __typename?: 'Hub'; id: string } | null
  nextHubReturn: {
    __typename?: 'HubReturn'
    type: Types.HubReturnType
    scheduledArrival: string
  } | null
  taskNotifications: {
    __typename?: 'TaskNotificationList'
    tasks: Array<
      | {
          __typename?: 'HubServiceNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'IvcTaskNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'PassengerUnbuckled'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'RoadBlockageTaskNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'ShiftDeviationNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopAccessibilityDownvotedByDrivers'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopActivationFailedNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopAreaMissingActiveStopsNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopDownVotedByDrivers'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopPotentiallyUnreachable'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopValidationFailed'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'TimeManagementNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'TripPrebookingNoAssignment'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleAvailabilityNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleIncidentNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleSurveillanceNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleWaypointAttendance'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
    >
  } | null
  nextWaypoint: {
    __typename?: 'Waypoint'
    visible: boolean
    waypointActions: Array<{
      __typename?: 'WaypointAction'
      kind: Types.WaypointActionKind
      reallocationRadius: number | null
    }>
    stop: {
      __typename?: 'Stop'
      routingLocation: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
    } | null
  } | null
  ignition: {
    __typename?: 'Ignition'
    ignitionState: Types.IgnitionState | null
    updatedAt: string | null
  } | null
}

export type VehicleMarkerPickupActionFragment = {
  __typename: 'FccWaypointPickupAction'
  estimatedAt: string
  seats: { __typename?: 'FccWaypointSeatInfo'; wheelchairs: number }
}

export type VehicleMarkerDeliveryActionFragment = {
  __typename: 'FccWaypointDeliveryAction'
  estimatedAt: string
  seats: { __typename?: 'FccWaypointSeatInfo'; wheelchairs: number }
}

export type VehicleMarkerHubReturnActionFragment = {
  __typename: 'FccWaypointHubReturnAction'
  isPlannedHubReturn: boolean
  reason: Types.FccWaypointHubReturnReason
  estimatedAt: string
}

export type VehicleMarkerReallocationActionFragment = {
  __typename: 'FccWaypointReallocationAction'
  estimatedAt: string
}

export const VehicleMarkerPickupActionFragmentDoc = gql`
    fragment VehicleMarkerPickupAction on FccWaypointPickupAction {
  __typename
  seats {
    wheelchairs
  }
  ...VehicleDestinationMarkerPickupAction
}
    ${VehicleDestinationMarkerPickupActionFragmentDoc}`
export const VehicleMarkerDeliveryActionFragmentDoc = gql`
    fragment VehicleMarkerDeliveryAction on FccWaypointDeliveryAction {
  __typename
  seats {
    wheelchairs
  }
  ...VehicleDestinationMarkerDeliveryAction
}
    ${VehicleDestinationMarkerDeliveryActionFragmentDoc}`
export const VehicleMarkerHubReturnActionFragmentDoc = gql`
    fragment VehicleMarkerHubReturnAction on FccWaypointHubReturnAction {
  __typename
  ...VehicleDestinationMarkerHubReturnAction
}
    ${VehicleDestinationMarkerHubReturnActionFragmentDoc}`
export const VehicleMarkerReallocationActionFragmentDoc = gql`
    fragment VehicleMarkerReallocationAction on FccWaypointReallocationAction {
  __typename
  ...VehicleDestinationMarkerReallocationAction
}
    ${VehicleDestinationMarkerReallocationActionFragmentDoc}`
export const VehicleMarkerVehicleFragmentDoc = gql`
    fragment VehicleMarkerVehicle on Vehicle {
  id
  inService
  isWheelchairAccessible
  label
  totalWheelchairUsersInVehicle
  latestLocation {
    heading
    location {
      latitude
      longitude
    }
  }
  fccUpcomingWaypoints {
    actions {
      ... on FccWaypointPickupAction {
        ...VehicleMarkerPickupAction
      }
      ... on FccWaypointDeliveryAction {
        ...VehicleMarkerDeliveryAction
      }
      ... on FccWaypointHubReturnAction {
        ...VehicleMarkerHubReturnAction
      }
      ... on FccWaypointReallocationAction {
        ...VehicleMarkerReallocationAction
      }
    }
  }
  pooling {
    reason
  }
  currentlyLocatedInHub {
    id
  }
  nextHubReturn {
    type
    scheduledArrival
  }
  ...IsRebalancing
  ...HasHubReturnError
  ...HasHubReturnNext
  ...IsLowOnEnergy
  ...IsOverheating
  ...IsSubCooled
  taskNotifications(
    status: $taskNotificationStatus
    types: $taskNotificationTypes
  ) {
    tasks {
      createdAt
      status
      severity
      ...AlertLabelTask
    }
  }
}
    ${VehicleMarkerPickupActionFragmentDoc}
${VehicleMarkerDeliveryActionFragmentDoc}
${VehicleMarkerHubReturnActionFragmentDoc}
${VehicleMarkerReallocationActionFragmentDoc}
${IsRebalancingFragmentDoc}
${HasHubReturnErrorFragmentDoc}
${HasHubReturnNextFragmentDoc}
${IsLowOnEnergyFragmentDoc}
${IsOverheatingFragmentDoc}
${IsSubCooledFragmentDoc}
${AlertLabelTaskFragmentDoc}`
