import { currentEnvironmentNotBuildEnvironment } from '@backoffice-frontend/environment'
import { MoiaLogo } from '@backoffice-frontend/patterns'
import { Link } from 'react-router-dom'

type NavigationDrawerLogoProps = {
  open: boolean
}

export const NavigationDrawerLogo = ({ open }: NavigationDrawerLogoProps) => {
  const envMismatch = currentEnvironmentNotBuildEnvironment()

  return (
    <div
      css={theme => ({
        alignContent: 'center',
        display: 'grid',
        justifyContent: 'center',
        minHeight: envMismatch ? theme.headerHeight - 20 : theme.headerHeight,
      })}
    >
      <Link
        css={{
          display: 'block',
          transition: 'width 200ms',
        }}
        to="/"
      >
        <MoiaLogo
          css={theme => ({
            fill: theme.semantic.ColorContentDefault,
            ...(open
              ? {
                  height: '28px',
                  width: '126px',
                }
              : {
                  height: '11px',
                  width: '48px',
                }),
          })}
        />
      </Link>
    </div>
  )
}
