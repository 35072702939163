// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import {
  LegalDocumentManagementAreaId,
  LegalDocumentManagementAreaRoutes,
} from '@backoffice-frontend/common'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { LegalDocumentManagementAreaNavigation } from './LegalDocumentManagementAreaNavigation'

const LazyLegalDocumentManagementComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./LegalDocumentManagementArea')),
)

const LegalDocumentManagementComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyLegalDocumentManagementComponent />
  </SuspenseLoadingWrapper>
)

export const LegalDocumentManagementArea = {
  id: LegalDocumentManagementAreaId,
  requiredRight: BoumRight.BACKOFFICE_LEGAL_DOCUMENT_R,
  NavigationComponent: LegalDocumentManagementAreaNavigation,
  route: {
    path: LegalDocumentManagementAreaRoutes.route,
    component: LegalDocumentManagementComponent,
  },
}
