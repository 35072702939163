/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type AdOperatorCallControlsVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  fccCurrentAdAlerts: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename?: 'FccAdAlert'
      id: string
      type: Types.FccAdAlertType
      status: Types.FccAdAlertStatus
    }>
  } | null
}

export type TakeOverAdAlertForIncomingCallMutationVariables = Types.Exact<{
  alertId: Types.Scalars['ID']['input']
}>

export type TakeOverAdAlertForIncomingCallMutation = {
  __typename?: 'Mutation'
  takeOverOpenAlert: { __typename?: 'FccAdAlert'; id: string }
}

export const AdOperatorCallControlsVehicleFragmentDoc = gql`
    fragment AdOperatorCallControlsVehicle on Vehicle {
  id
  fccCurrentAdAlerts {
    alerts {
      id
      type
      status
    }
  }
}
    `
export const TakeOverAdAlertForIncomingCallDocument = gql`
    mutation TakeOverAdAlertForIncomingCall($alertId: ID!) {
  takeOverOpenAlert(id: $alertId) {
    id
  }
}
    `
export type TakeOverAdAlertForIncomingCallMutationFn = Apollo.MutationFunction<
  TakeOverAdAlertForIncomingCallMutation,
  TakeOverAdAlertForIncomingCallMutationVariables
>

/**
 * __useTakeOverAdAlertForIncomingCallMutation__
 *
 * To run a mutation, you first call `useTakeOverAdAlertForIncomingCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTakeOverAdAlertForIncomingCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [takeOverAdAlertForIncomingCallMutation, { data, loading, error }] = useTakeOverAdAlertForIncomingCallMutation({
 *   variables: {
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useTakeOverAdAlertForIncomingCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TakeOverAdAlertForIncomingCallMutation,
    TakeOverAdAlertForIncomingCallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TakeOverAdAlertForIncomingCallMutation,
    TakeOverAdAlertForIncomingCallMutationVariables
  >(TakeOverAdAlertForIncomingCallDocument, options)
}
export type TakeOverAdAlertForIncomingCallMutationHookResult = ReturnType<
  typeof useTakeOverAdAlertForIncomingCallMutation
>
export type TakeOverAdAlertForIncomingCallMutationResult =
  Apollo.MutationResult<TakeOverAdAlertForIncomingCallMutation>
export type TakeOverAdAlertForIncomingCallMutationOptions =
  Apollo.BaseMutationOptions<
    TakeOverAdAlertForIncomingCallMutation,
    TakeOverAdAlertForIncomingCallMutationVariables
  >
