import { trackClickEvent } from '@backoffice-frontend/tracking'
import type { LinkProps } from '@mui/material'
import MuiLink from '@mui/material/Link'
import type { ReactNode } from 'react'
import { Link as RouterLinkCompat } from 'react-router-dom'

type MoiaLinkType = 'primary' | 'secondary'

export type MoiaLinkProps = {
  className?: string
  to?: string
  type?: MoiaLinkType
  bold?: boolean
  icon?: ReactNode
  noUnderline?: boolean
  disabled?: boolean
  uppercase?: boolean
  target?: string
  href?: string
  rel?: string
  children?: ReactNode
  exact?: boolean
  trackingLabel?: string
  onClick?: LinkProps['onClick']
}

/** @deprecated Use the plain Link from react-router-dom instead */
export const MoiaLink = ({
  to = undefined,
  type = 'secondary',
  bold = false,
  icon,
  noUnderline,
  disabled,
  uppercase = false,
  children,
  exact,
  trackingLabel,
  onClick,
  ...others
}: MoiaLinkProps) => {
  const reactRouterProps = to
    ? { to, component: RouterLinkCompat, end: exact }
    : undefined

  return (
    <MuiLink
      onClick={e => {
        onClick?.(e)
        if (trackingLabel) {
          return () => trackClickEvent(trackingLabel)
        }
        return undefined
      }}
      css={[
        {
          display: 'inline-flex',
          alignItems: 'center',
          color:
            type === 'secondary'
              ? 'var(--color-action-secondary-default)'
              : 'var(--color-action-primary-alternative)',
          fontWeight: bold ? 700 : 'unset',
          textDecoration: noUnderline ? 'none' : 'underline',
          textTransform: uppercase ? 'uppercase' : 'unset',
          cursor: 'pointer',

          '&:hover': {
            color:
              type === 'secondary'
                ? 'var(--color-action-secondary-hovered)'
                : 'var(--color-action-primary-alternative-hovered)',
            textDecoration: 'underline',
          },

          '&:active': {
            color:
              type === 'secondary'
                ? 'var(--color-action-secondary-pressed)'
                : 'var(--color-action-primary-alternative-pressed)',
          },
        },

        disabled && {
          color:
            type === 'secondary'
              ? 'var(--color-action-secondary-disabled)'
              : 'var(--color-action-primary-alternative-disabled)',
          '&:hover': {
            color:
              type === 'secondary'
                ? 'var(--color-action-secondary-disabled)'
                : 'var(--color-action-primary-alternative-disabled)',
            cursor: 'not-allowed',
            textDecoration: noUnderline ? 'none' : 'underline',
          },
        },
        icon && {
          '& svg': {
            width: '20px',
          },
        },
      ]}
      {...reactRouterProps}
      {...others}
    >
      {icon && icon}
      {children && (
        <span
          css={{
            display: 'inline-flex',
            paddingLeft: icon ? 6 : undefined,
          }}
        >
          {children}
        </span>
      )}
    </MuiLink>
  )
}
