import { Environment, getEnvironment } from '@backoffice-frontend/environment'
import { AwsRum, type AwsRumConfig } from 'aws-rum-web'

export let awsRum: AwsRum | null = null
export const setupAwsRum = () => {
  const guestRoleMap = new Map<string, string>([
    [
      Environment.integration,
      'arn:aws:iam::727936352376:role/RUM-Monitor-eu-central-1-int-Unauth',
    ],
    [
      Environment.production,
      'arn:aws:iam::560280922780:role/RUM-Monitor-eu-central-1-prd-Unauth',
    ],
  ])
  const identityPoolIdMap = new Map<string, string>([
    [
      Environment.integration,
      'eu-central-1:77387cc1-eb67-4a75-bb14-b844462f5227',
    ],
    [
      Environment.production,
      'eu-central-1:833164a0-fd10-45ad-8724-7f7dc950f3ec',
    ],
  ])
  const ApplicationMonitorIdMap = new Map<string, string>([
    [Environment.integration, '32adbdb1-e4c7-460b-8860-16389cf6b992'],
    [Environment.production, '2a218436-3977-49a0-9169-929905c192e8'],
  ])
  const stage = getEnvironment()
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: guestRoleMap.get(stage),
      identityPoolId: identityPoolIdMap.get(stage),
      endpoint: 'https://dataplane.rum.eu-central-1.amazonaws.com',
      telemetries: [
        'errors',
        'http',
        [
          'performance',
          {
            ignore: (entry: PerformanceEntry) => {
              return entry.entryType === 'resource'
            },
          },
        ],
      ],
      allowCookies: true,
      enableXRay: false,
    }

    const APPLICATION_MONITOR_ID: string =
      ApplicationMonitorIdMap.get(stage) ?? ''
    const APPLICATION_VERSION: string = '1.0.0'
    const APPLICATION_REGION: string = 'eu-central-1'

    awsRum = new AwsRum(
      APPLICATION_MONITOR_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config,
    )
  } catch (error) {
    console.info('rum initialization failed')
    console.info(error)
  }
}
