import { OfferAndPoolingAreaId } from './OfferAndPoolingAreaId'

const baseRoute = '/offer-and-pooling'

export const OfferAndPoolingAreaRoutes = {
  route: baseRoute,
  title: `${OfferAndPoolingAreaId}:title`,
  OfferAndPoolingArea: {
    route: ':serviceAreaId',
  },
}
