import { DialogType, formatFileSize } from '@backoffice-frontend/common'
import { useToggle } from '@backoffice-frontend/hooks'
import {
  DownloadIcon,
  EyeIcon,
  FileDraftIcon,
  FileTextIcon,
  ImageIcon,
  TableIcon,
  TrashIcon,
  VideoIcon,
  WaveformIcon,
} from '@moia-dev/pace-icons'
import { Card } from '@moia-dev/pace-web'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MoiaGrid } from '../../basics/MoiaGrid'
import { Caption, Subtitle2 } from '../../basics/Typography'
import { useTheme } from '../../basics/theme'
import { MoiaTooltip } from '../../muiRexports'
import { ConfirmDialog } from '../../patterns/Dialog/ConfirmDialog'
import { MoiaButton } from '../MoiaButton'

export type FileShell = Partial<Pick<File, 'name' | 'size' | 'type'>>

export type UploadFileInterface<T = object> = {
  file: File | FileShell
  loading: boolean
  loadingText?: string
  description?: string
  url?: string
} & T

type FilePreviewProps<T extends UploadFileInterface> = {
  file?: T
  onDownload?: (file: T) => void
  onDelete?: (file: T) => void
  onPreview?: (file: T) => void
  deleteDialogHeader?: string
  deleteDialogText?: string
}

const FileIcon = ({ fileType }: { fileType: string }) => {
  switch (fileType) {
    case 'image':
      return <ImageIcon aria-label="TypeImageIcon" />
    case 'document':
      return <FileTextIcon aria-label="TypeDocumentIcon" />
    case 'spreadsheet':
      return <TableIcon aria-label="TypeSpreadsheetIcon" />
    case 'video':
      return <VideoIcon aria-label="TypeVideoIcon" />
    case 'audio':
      return <WaveformIcon aria-label="TypeAudioIcon" />
    default:
      return <FileDraftIcon aria-label="TypeDraftIcon" />
  }
}

/**
 * Renders the FilePreview Card. Can be used static or dynamic.
 * Including a delete confirm dialog.
 *
 */
export const FilePreview = <T extends UploadFileInterface>({
  file,
  onDelete,
  onDownload,
  onPreview,
  deleteDialogHeader,
  deleteDialogText,
}: FilePreviewProps<T>) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [confirmDialogOpen, toggleConfirmDialogOpen] = useToggle()

  if (!file) {
    return
  }

  const loading = file.loading ?? false

  const fileType = (type?: string): string => {
    if (!type) return 'unknown'

    const imageTypes = ['image/png', 'image/jpeg', 'image/gif']
    const documentTypes = [
      'plain/text',
      'application/pdf',
      'text/plain',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ]
    const spreadsheetTypes = [
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ]
    const videoTypes = ['video/mp4', 'video/mpeg', 'video/quicktime']
    const audioTypes = ['audio/mpeg', 'audio/ogg', 'audio/wav']

    if (imageTypes.includes(type)) return 'image'
    if (documentTypes.includes(type)) return 'document'
    if (spreadsheetTypes.includes(type)) return 'spreadsheet'
    if (videoTypes.includes(type)) return 'video'
    if (audioTypes.includes(type)) return 'audio'

    return 'unknown'
  }

  const showPreview =
    Boolean(
      ['image', 'video', 'audio'].includes(fileType(file.file.type)) ||
        file.file.type === 'application/pdf',
    ) &&
    (Boolean(file.url) || Boolean(onPreview))

  const showDownload = file.url || onDownload

  return (
    <div css={{ minWidth: '100%' }}>
      <Card outline>
        <MoiaGrid
          css={{
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: 'auto auto 1fr',
            width: '100%',
          }}
          aria-label="FilePreview"
        >
          {loading && !file.file.type ? (
            <FileDraftIcon />
          ) : (
            <FileIcon fileType={fileType(file.file.type)} />
          )}

          <div css={{ overflow: 'hidden' }}>
            <Subtitle2
              css={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {file.file.name ?? t('patterns_fileNameFallback')}
            </Subtitle2>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: `${theme.spacing(1)}`,
              }}
            >
              {loading && (
                <>
                  <CircularProgress
                    size={theme.spacing(2)}
                    css={{
                      color: theme.semantic.ColorActionSecondaryDefault,
                    }}
                  />
                  <Caption>{file.loadingText ?? t('common_loading')}</Caption>
                </>
              )}
              {!loading && file.file.size && (
                <Caption>{formatFileSize(file.file.size)}</Caption>
              )}
              {!loading && file.description && (
                <Caption>{file.description}</Caption>
              )}
            </div>
          </div>
          {!loading && (
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {showPreview && (
                <MoiaTooltip
                  placement="top"
                  title={t('patterns_tooltip_filePreview')}
                >
                  <MoiaButton
                    onClick={event => {
                      if (file.url) {
                        window.open(file.url, '_blank')
                      } else if (onPreview) {
                        event.preventDefault()
                        onPreview?.(file)
                      }
                    }}
                    css={{ border: 'none' }}
                  >
                    <EyeIcon aria-label="previewIcon" />
                  </MoiaButton>
                </MoiaTooltip>
              )}
              {showDownload && (
                <MoiaTooltip
                  placement="top"
                  title={t('patterns_tooltip_fileDownload')}
                >
                  <a
                    download
                    href={file.url && file.url}
                    onClick={event => {
                      if (file.url) return
                      if (file && onDownload) {
                        event.preventDefault()
                        onDownload?.(file)
                      }
                    }}
                  >
                    <MoiaButton css={{ border: 'none' }}>
                      <DownloadIcon aria-label="downloadIcon" />
                    </MoiaButton>
                  </a>
                </MoiaTooltip>
              )}
              {onDelete && (
                <MoiaTooltip
                  placement="top"
                  title={t('patterns_tooltip_fileDelete')}
                >
                  <MoiaButton css={{ border: 'none' }}>
                    <TrashIcon
                      onClick={toggleConfirmDialogOpen}
                      aria-label="deleteIcon"
                    />
                  </MoiaButton>
                </MoiaTooltip>
              )}
            </div>
          )}
        </MoiaGrid>
        <ConfirmDialog
          confirmButtonText={t('common_delete_button')}
          dialogText={
            deleteDialogText ?? t('patterns_dialog_confirmDeleteFile')
          }
          dialogType={DialogType.DELETE}
          header={
            deleteDialogHeader ?? t('patterns_dialog_confirmDeleteFileHeader')
          }
          onCancel={toggleConfirmDialogOpen}
          onConfirm={() => {
            onDelete?.(file)
            toggleConfirmDialogOpen()
          }}
          open={confirmDialogOpen}
        />
      </Card>
    </div>
  )
}
