import { TaskNotificationStatus } from '@backoffice-frontend/graphql'
import { sortAlertsBy } from '../utils'
import type {
  UseCurrentSortedAlertsAdAlertFragment,
  UseCurrentSortedAlertsTaskFragment,
} from './useCurrentSortedAlerts.hook'

export const useCurrentSortedAlerts = <
  T extends UseCurrentSortedAlertsTaskFragment,
  A extends UseCurrentSortedAlertsAdAlertFragment,
>(
  tasks: T[] = [],
  adAlerts: A[] = [],
) => {
  const filteredTasks = tasks?.filter(task =>
    [TaskNotificationStatus.New, TaskNotificationStatus.InProgress].includes(
      task.status,
    ),
  )

  const sortedTasks = [...filteredTasks, ...adAlerts].sort(
    sortAlertsBy('createdAt'),
  )

  return sortedTasks
}
