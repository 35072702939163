import { APPEARANCE_KEY, DARK_MODE_KEY } from '@backoffice-frontend/common'
import { useLocalStorage } from '@backoffice-frontend/hooks'
import { useEffect } from 'react'

export enum AppearanceOptions {
  system = 'system',
  dark = 'dark',
  light = 'light',
}

/**
 * Custom hook for managing appearance and dark mode settings.
 *
 * @returns {boolean} darkMode - A boolean indicating if dark mode is enabled.
 * @returns {AppearanceOptions} appearanceOption - An object containing the appearance settings.
 * @private {Function} setDarkMode - Private API, do not use.
 * @private {Function} setAppearanceOption - Private API, do not use.
 */
export const useAppearance = () => {
  const [appearanceOption, setAppearanceOption] =
    useLocalStorage<AppearanceOptions>(APPEARANCE_KEY, AppearanceOptions.light)
  const [darkMode, setDarkMode] = useLocalStorage<boolean>(DARK_MODE_KEY, false)

  useEffect(() => {
    if (
      appearanceOption === AppearanceOptions.light ||
      appearanceOption === AppearanceOptions.dark
    )
      return

    const colorScheme = window.matchMedia('(prefers-color-scheme: dark)')

    const handleChange = (event: MediaQueryListEvent) => {
      if (
        appearanceOption === AppearanceOptions.system &&
        darkMode === colorScheme.matches
      )
        return

      if (event.matches) {
        setDarkMode(true)
      } else {
        setDarkMode(false)
      }
    }

    colorScheme.addEventListener('change', handleChange)

    handleChange(
      new MediaQueryListEvent('change', { matches: colorScheme.matches }),
    )

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', handleChange)
    }
  }, [appearanceOption, darkMode, setDarkMode])

  useEffect(() => {
    window.addEventListener('theme-change', ((event: CustomEvent) => {
      setAppearanceOption(
        event?.detail?.appearanceOption ?? AppearanceOptions.light,
      )
      setDarkMode(event?.detail?.darkMode ?? false)
    }) as EventListener)

    return () => {
      window.removeEventListener('theme-change', () => {})
    }
  }, [setAppearanceOption, setDarkMode])

  return { appearanceOption, setAppearanceOption, darkMode, setDarkMode }
}
