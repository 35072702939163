// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import { TripAreaRoutes } from '@backoffice-frontend/trip-api'
import { TripAreaId } from './TripAreaId'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { TripAreaNavigation } from './TripAreaNavigation'

const LazyTripComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./TripArea')),
)

const TripComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyTripComponent />
  </SuspenseLoadingWrapper>
)

export const TripArea = {
  id: TripAreaId,
  requiredRight: BoumRight.AREA_TRIPS,
  NavigationComponent: TripAreaNavigation,
  route: {
    path: TripAreaRoutes.route,
    component: TripComponent,
  },
}
