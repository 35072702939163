type ColumnWidth = number | ((width: number, count: number) => number)

export const getColumnWidth = (
  tableWidth: number,
  columnWidth: ColumnWidth,
  columnCount: number,
): number =>
  typeof columnWidth === 'function'
    ? columnWidth(tableWidth, columnCount)
    : columnWidth
