/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type VehicleIdMultiselectQueryVariables = Types.Exact<{
  serviceAreaUUID: Types.Scalars['ID']['input']
}>

export type VehicleIdMultiselectQuery = {
  __typename?: 'Query'
  fleetVehicles: {
    __typename?: 'VehiclesResponse'
    vehicles: Array<{
      __typename?: 'Vehicle'
      id: string
      label: number | null
      fleet: { __typename?: 'Fleet'; name: string } | null
    }>
  }
}

export const VehicleIdMultiselectDocument = gql`
    query VehicleIdMultiselect($serviceAreaUUID: ID!) {
  fleetVehicles(
    input: {serviceAreaUUID: $serviceAreaUUID, states: [ACTIVE, INACTIVE]}
  ) {
    vehicles {
      id
      label
      fleet {
        name
      }
    }
  }
}
    `

/**
 * __useVehicleIdMultiselectQuery__
 *
 * To run a query within a React component, call `useVehicleIdMultiselectQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleIdMultiselectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleIdMultiselectQuery({
 *   variables: {
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *   },
 * });
 */
export function useVehicleIdMultiselectQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleIdMultiselectQuery,
    VehicleIdMultiselectQueryVariables
  > &
    (
      | { variables: VehicleIdMultiselectQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    VehicleIdMultiselectQuery,
    VehicleIdMultiselectQueryVariables
  >(VehicleIdMultiselectDocument, options)
}
export function useVehicleIdMultiselectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleIdMultiselectQuery,
    VehicleIdMultiselectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    VehicleIdMultiselectQuery,
    VehicleIdMultiselectQueryVariables
  >(VehicleIdMultiselectDocument, options)
}
export function useVehicleIdMultiselectSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VehicleIdMultiselectQuery,
        VehicleIdMultiselectQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    VehicleIdMultiselectQuery,
    VehicleIdMultiselectQueryVariables
  >(VehicleIdMultiselectDocument, options)
}
export type VehicleIdMultiselectQueryHookResult = ReturnType<
  typeof useVehicleIdMultiselectQuery
>
export type VehicleIdMultiselectLazyQueryHookResult = ReturnType<
  typeof useVehicleIdMultiselectLazyQuery
>
export type VehicleIdMultiselectSuspenseQueryHookResult = ReturnType<
  typeof useVehicleIdMultiselectSuspenseQuery
>
export type VehicleIdMultiselectQueryResult = Apollo.QueryResult<
  VehicleIdMultiselectQuery,
  VehicleIdMultiselectQueryVariables
>
