/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type AdVehicleStreamAdVehicleDoorStatusFragment = {
  __typename?: 'Vehicle'
  id: string
  latestADTelemetry: {
    __typename?: 'ADTelemetry'
    rightSlidingDoorOpen: {
      __typename?: 'ADTelemetryBooleanWithTimestamp'
      value: boolean
      timestamp: string
    } | null
  } | null
}

export type AdVehicleStreamFccDoorActuationStatusSubscriptionVariables =
  Types.Exact<{
    vehicleId: Types.Scalars['ID']['input']
  }>

export type AdVehicleStreamFccDoorActuationStatusSubscription = {
  __typename?: 'Subscription'
  fccDoorActuationStatus:
    | { __typename?: 'FccDoorActuationNotPossibleStatus'; vehicleId: string }
    | { __typename?: 'FccDoorActuationPossibleStatus'; vehicleId: string }
    | {
        __typename?: 'FccDoorActuationRequestFailureStatus'
        vehicleId: string
        errors: Array<Types.FccDoorActuationError>
      }
    | { __typename?: 'FccDoorActuationRequestSuccessStatus'; vehicleId: string }
}

export type AdVehicleStreamRequestDoorActuationMutationVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
  desiredDoorState: Types.FccDoorState
}>

export type AdVehicleStreamRequestDoorActuationMutation = {
  __typename?: 'Mutation'
  fccRequestDoorActuation: any | null
}

export const AdVehicleStreamAdVehicleDoorStatusFragmentDoc = gql`
    fragment AdVehicleStreamAdVehicleDoorStatus on Vehicle {
  id
  latestADTelemetry {
    rightSlidingDoorOpen {
      value
      timestamp
    }
  }
}
    `
export const AdVehicleStreamFccDoorActuationStatusDocument = gql`
    subscription AdVehicleStreamFccDoorActuationStatus($vehicleId: ID!) {
  fccDoorActuationStatus(vehicleId: $vehicleId) {
    ... on FccDoorActuationPossibleStatus {
      vehicleId
    }
    ... on FccDoorActuationNotPossibleStatus {
      vehicleId
    }
    ... on FccDoorActuationRequestSuccessStatus {
      vehicleId
    }
    ... on FccDoorActuationRequestFailureStatus {
      vehicleId
      errors
    }
  }
}
    `

/**
 * __useAdVehicleStreamFccDoorActuationStatusSubscription__
 *
 * To run a query within a React component, call `useAdVehicleStreamFccDoorActuationStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAdVehicleStreamFccDoorActuationStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdVehicleStreamFccDoorActuationStatusSubscription({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAdVehicleStreamFccDoorActuationStatusSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    AdVehicleStreamFccDoorActuationStatusSubscription,
    AdVehicleStreamFccDoorActuationStatusSubscriptionVariables
  > &
    (
      | {
          variables: AdVehicleStreamFccDoorActuationStatusSubscriptionVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    AdVehicleStreamFccDoorActuationStatusSubscription,
    AdVehicleStreamFccDoorActuationStatusSubscriptionVariables
  >(AdVehicleStreamFccDoorActuationStatusDocument, options)
}
export type AdVehicleStreamFccDoorActuationStatusSubscriptionHookResult =
  ReturnType<typeof useAdVehicleStreamFccDoorActuationStatusSubscription>
export type AdVehicleStreamFccDoorActuationStatusSubscriptionResult =
  Apollo.SubscriptionResult<AdVehicleStreamFccDoorActuationStatusSubscription>
export const AdVehicleStreamRequestDoorActuationDocument = gql`
    mutation AdVehicleStreamRequestDoorActuation($vehicleId: ID!, $desiredDoorState: FccDoorState!) {
  fccRequestDoorActuation(
    input: {vehicleId: $vehicleId, doorState: $desiredDoorState}
  )
}
    `
export type AdVehicleStreamRequestDoorActuationMutationFn =
  Apollo.MutationFunction<
    AdVehicleStreamRequestDoorActuationMutation,
    AdVehicleStreamRequestDoorActuationMutationVariables
  >

/**
 * __useAdVehicleStreamRequestDoorActuationMutation__
 *
 * To run a mutation, you first call `useAdVehicleStreamRequestDoorActuationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdVehicleStreamRequestDoorActuationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adVehicleStreamRequestDoorActuationMutation, { data, loading, error }] = useAdVehicleStreamRequestDoorActuationMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      desiredDoorState: // value for 'desiredDoorState'
 *   },
 * });
 */
export function useAdVehicleStreamRequestDoorActuationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdVehicleStreamRequestDoorActuationMutation,
    AdVehicleStreamRequestDoorActuationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdVehicleStreamRequestDoorActuationMutation,
    AdVehicleStreamRequestDoorActuationMutationVariables
  >(AdVehicleStreamRequestDoorActuationDocument, options)
}
export type AdVehicleStreamRequestDoorActuationMutationHookResult = ReturnType<
  typeof useAdVehicleStreamRequestDoorActuationMutation
>
export type AdVehicleStreamRequestDoorActuationMutationResult =
  Apollo.MutationResult<AdVehicleStreamRequestDoorActuationMutation>
export type AdVehicleStreamRequestDoorActuationMutationOptions =
  Apollo.BaseMutationOptions<
    AdVehicleStreamRequestDoorActuationMutation,
    AdVehicleStreamRequestDoorActuationMutationVariables
  >
