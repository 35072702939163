import { HubsAreaRoutes } from '@backoffice-frontend/common'
import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { HomeIcon } from '@moia-dev/pace-icons'

export const HubsAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={HubsAreaRoutes.List.route}
      Icon={HomeIcon}
    />
  )
}
