/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import {
  EmployeeLogAreaLogRecordFragmentDoc,
  EmployeeLogPoolingStateChangedLogRecordFragmentDoc,
  EmployeeLogSendVehicleToHubLogRecordFragmentDoc,
  EmployeeLogShiftStateBreakLogRecordFragmentDoc,
  EmployeeLogShiftStateHubLogRecordFragmentDoc,
  EmployeeLogShiftStateVehicleLogRecordFragmentDoc,
  EmployeeShiftChangedLogRecordFragmentDoc,
} from './employeeLogFragments.hook'
const defaultOptions = {} as const
export type EmployeeLogByShiftIdQueryVariables = Types.Exact<{
  shiftId: Types.Scalars['ID']['input']
}>

export type EmployeeLogByShiftIdQuery = {
  __typename?: 'Query'
  employeeLogByShift: {
    __typename?: 'EmployeeLogResponse'
    log: Array<
      | {
          __typename?: 'AreaLogRecord'
          eventTime: string
          shiftId: string | null
          type: Types.ShiftStateLogRecordType
          area: { __typename?: 'Area'; id: string; name: string | null } | null
        }
      | {
          __typename?: 'PoolingStateChangedLogRecord'
          eventTime: string
          shiftId: string | null
          type: Types.ShiftStateLogRecordType
          poolingEnabled: boolean
          reason: string | null
        }
      | {
          __typename?: 'SendVehicleToHubLogRecord'
          eventTime: string
          shiftId: string | null
          type: Types.ShiftStateLogRecordType
          expectedTimeAtHub: string | null
          returnReason: Types.SendVehicleToHubReturnReason
          area: { __typename?: 'Area'; id: string; name: string | null } | null
        }
      | {
          __typename?: 'ShiftChangedLogRecord'
          eventTime: string
          shiftId: string | null
          type: Types.ShiftStateLogRecordType
          logRecordOldShiftType: Types.LogRecordShiftType
          logRecordNewShiftType: Types.LogRecordShiftType
        }
      | {
          __typename?: 'ShiftStateBreakLogRecord'
          eventTime: string
          shiftId: string | null
          type: Types.ShiftStateLogRecordType
          duration: number
          earliestStart: string
          latestStart: string
        }
      | {
          __typename?: 'ShiftStateHubLogRecord'
          eventTime: string
          shiftId: string | null
          type: Types.ShiftStateLogRecordType
          createdBy: string
          hub: {
            __typename?: 'Hub'
            id: string
            displayName: string
            serviceAreaUUID: string
          } | null
        }
      | {
          __typename?: 'ShiftStateShortBreakLogRecord'
          eventTime: string
          shiftId: string | null
          type: Types.ShiftStateLogRecordType
        }
      | {
          __typename?: 'ShiftStateVehicleLogRecord'
          eventTime: string
          shiftId: string | null
          type: Types.ShiftStateLogRecordType
          createdBy: string
          vehicle: { __typename?: 'Vehicle'; id: string; label: number | null }
        }
    >
  }
}

export const EmployeeLogByShiftIdDocument = gql`
    query employeeLogByShiftId($shiftId: ID!) {
  employeeLogByShift(shiftId: $shiftId) {
    log {
      eventTime
      shiftId
      type
      ...employeeLogShiftStateBreakLogRecord
      ...employeeLogShiftStateHubLogRecord
      ...employeeLogShiftStateVehicleLogRecord
      ...employeeLogSendVehicleToHubLogRecord
      ...employeeLogPoolingStateChangedLogRecord
      ...employeeLogAreaLogRecord
      ...employeeShiftChangedLogRecord
    }
  }
}
    ${EmployeeLogShiftStateBreakLogRecordFragmentDoc}
${EmployeeLogShiftStateHubLogRecordFragmentDoc}
${EmployeeLogShiftStateVehicleLogRecordFragmentDoc}
${EmployeeLogSendVehicleToHubLogRecordFragmentDoc}
${EmployeeLogPoolingStateChangedLogRecordFragmentDoc}
${EmployeeLogAreaLogRecordFragmentDoc}
${EmployeeShiftChangedLogRecordFragmentDoc}`

/**
 * __useEmployeeLogByShiftIdQuery__
 *
 * To run a query within a React component, call `useEmployeeLogByShiftIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeLogByShiftIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeLogByShiftIdQuery({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *   },
 * });
 */
export function useEmployeeLogByShiftIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmployeeLogByShiftIdQuery,
    EmployeeLogByShiftIdQueryVariables
  > &
    (
      | { variables: EmployeeLogByShiftIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    EmployeeLogByShiftIdQuery,
    EmployeeLogByShiftIdQueryVariables
  >(EmployeeLogByShiftIdDocument, options)
}
export function useEmployeeLogByShiftIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmployeeLogByShiftIdQuery,
    EmployeeLogByShiftIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EmployeeLogByShiftIdQuery,
    EmployeeLogByShiftIdQueryVariables
  >(EmployeeLogByShiftIdDocument, options)
}
export function useEmployeeLogByShiftIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EmployeeLogByShiftIdQuery,
        EmployeeLogByShiftIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    EmployeeLogByShiftIdQuery,
    EmployeeLogByShiftIdQueryVariables
  >(EmployeeLogByShiftIdDocument, options)
}
export type EmployeeLogByShiftIdQueryHookResult = ReturnType<
  typeof useEmployeeLogByShiftIdQuery
>
export type EmployeeLogByShiftIdLazyQueryHookResult = ReturnType<
  typeof useEmployeeLogByShiftIdLazyQuery
>
export type EmployeeLogByShiftIdSuspenseQueryHookResult = ReturnType<
  typeof useEmployeeLogByShiftIdSuspenseQuery
>
export type EmployeeLogByShiftIdQueryResult = Apollo.QueryResult<
  EmployeeLogByShiftIdQuery,
  EmployeeLogByShiftIdQueryVariables
>
