import type {
  ApolloError,
  LazyQueryHookOptions,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client'
import type { QueryTuple } from '@apollo/client/react/types/types'
import { getDisablePolling } from '@backoffice-frontend/environment'
import { useMoiaErrorNotification } from '@backoffice-frontend/patterns'
import { useCallback } from 'react'

type UseQueryFn<TData, TVariables extends OperationVariables> = (
  options: QueryHookOptions<TData, TVariables> &
    ({ variables: TVariables; skip?: boolean } | { skip: boolean }),
) => QueryResult<TData, TVariables>

type UseLazyQueryFn<TData, TVariables extends OperationVariables> = (
  options: LazyQueryHookOptions<TData, TVariables>,
) => QueryTuple<TData, TVariables>

/**
 * Adds error handling to a graphql-code-generator TypeScript React Apollo Hook query
 * @param useQueryFn reference to the actual exported query function
 * @param options Apollo React Hook options as usual
 */
export const useErrorHandlingQuery = <
  TData,
  TVariables extends OperationVariables,
>(
  useQueryFn: UseQueryFn<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> &
    ({ variables: TVariables; skip?: boolean } | { skip: boolean }),
  getErrorTranslations?: (error: ApolloError) => string,
): QueryResult<TData, TVariables> => {
  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()
  if (getDisablePolling() && options?.pollInterval) {
    delete options['pollInterval']
  }

  const defaultErrorHandler = useCallback(
    (graphqlError: ApolloError) => {
      enqueueMoiaErrorNotification(
        getErrorTranslations
          ? getErrorTranslations(graphqlError)
          : graphqlError,
      )
    },
    [enqueueMoiaErrorNotification, getErrorTranslations],
  )

  const onError = options?.onError ?? defaultErrorHandler

  const result = useQueryFn({
    ...options,
    onError,
    errorPolicy: 'all',
  })

  return result
}

/**
 * Adds error handling to a graphql-code-generator TypeScript React Apollo Hook query
 * @param useLazyQueryFn reference to the actual exported query function
 * @param options Apollo React Hook options as usual
 */
export const useErrorHandlingLazyQuery = <
  TData,
  TVariables extends OperationVariables,
>(
  useLazyQueryFn: UseLazyQueryFn<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>,
): QueryTuple<TData, TVariables> => {
  if (getDisablePolling() && options?.pollInterval) {
    delete options['pollInterval']
  }
  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()

  const defaultErrorHandler = useCallback(
    (graphqlError: unknown) => {
      enqueueMoiaErrorNotification(graphqlError)
    },
    [enqueueMoiaErrorNotification],
  )

  const onError = options?.onError ?? defaultErrorHandler

  return useLazyQueryFn({
    ...options,
    onError,
    errorPolicy: 'all',
  })
}

export enum GraphQlErrorCode {
  // standard
  GRAPHQL_PARSE_FAILED = 'GRAPHQL_PARSE_FAILED',
  GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED',
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  FORBIDDEN = 'FORBIDDEN',
  PERSISTED_QUERY_NOT_FOUND = 'PERSISTED_QUERY_NOT_FOUND',
  PERSISTED_QUERY_NOT_SUPPORTED = 'PERSISTED_QUERY_NOT_SUPPORTED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  // custom
  NOT_FOUND = 'NOT_FOUND',
}

/**
 * Adds error handling to a graphql-code-generator TypeScript React Apollo Hook query, ignoring any of the supplied error codes
 * @param except array of GraphQL error codes to be ignored
 * @param useQueryFn reference to the actual exported query function
 * @param options Apollo React Hook options as usual
 */
export const useErrorHandlingQueryExcept = <
  TData,
  TVariables extends OperationVariables,
>(
  except: GraphQlErrorCode[],
  useQueryFn: UseQueryFn<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> &
    ({ variables: TVariables; skip?: boolean } | { skip: boolean }),
): QueryResult<TData, TVariables> => {
  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()

  const defaultErrorHandler = useCallback<(error: ApolloError) => void>(
    graphqlError => {
      const skipError = graphqlError.graphQLErrors.find(
        err =>
          // @ts-expect-error apollo does no longer ship nested types for errors
          except.includes(err?.extensions?.code) ||
          // @ts-expect-error apollo does no longer ship nested types for errors
          except.includes(err?.extensions?.error?.extensions?.code),
      )

      if (graphqlError && !skipError) enqueueMoiaErrorNotification(graphqlError)
    },
    [enqueueMoiaErrorNotification, except],
  )

  const onError = options?.onError ?? defaultErrorHandler

  const result = useQueryFn({
    ...options,
    onError,
    errorPolicy: 'all',
  })

  return result
}
