import { AccountViewRoute, Language, config } from '@backoffice-frontend/common'
import {
  AppearanceOptions,
  useAppearance,
} from '@backoffice-frontend/dark-mode'
import { Environment, getEnvironment } from '@backoffice-frontend/environment'
import { Badge, Divider } from '@backoffice-frontend/patterns'
import { Restricted } from '@backoffice-frontend/restricted'
import { BackofficeStatusRoutes } from '@backoffice-frontend/status-api'
import { BackofficeUpdatesRoutes } from '@backoffice-frontend/updates-api'
import { BoumRight } from '@moia-dev/moia-token-claims'
import { ChevronRightIcon } from '@moia-dev/pace-icons'
import { ActionItem } from '@moia-dev/pace-web'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LogoutButton } from '../LogoutButton'
import type { MenuState } from './MenuState'

interface AccountBadgeMainMenuProps {
  setIsOpen: (isOpen: boolean) => void
  hasNotificationsUpdates: boolean
  hasNotificationsStatuses: boolean
  setUpdatesLastVisited: () => Promise<unknown>
  refetchUpdateLastVisit: () => Promise<unknown>
  setStatusLastVisited: () => Promise<unknown>
  refetchStatusLastVisit: () => Promise<unknown>
  setModalOpen: () => void
  setCurrentMenu: (menu: MenuState) => void
}

const menuAnimations = {
  initial: { x: -50, opacity: 0 },
  animate: { x: 0, opacity: 1, transition: { duration: 0.1 } },
  exit: { x: -50, opacity: 0, transition: { duration: 0.1 } },
}

export const AccountBadgeMainMenu = ({
  setIsOpen,
  hasNotificationsUpdates,
  hasNotificationsStatuses,
  setUpdatesLastVisited,
  refetchUpdateLastVisit,
  setStatusLastVisited,
  refetchStatusLastVisit,
  setModalOpen,
  setCurrentMenu,
}: AccountBadgeMainMenuProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { appearanceOption, darkMode } = useAppearance()

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={menuAnimations}
    >
      <ActionItem
        label={t('authenticatedAppView_account_button')}
        onClick={() => {
          setIsOpen(false)
          navigate(AccountViewRoute.route)
        }}
      />
      <ActionItem
        label={t('authenticatedAppView_releaseNotes_button')}
        onClick={async () => {
          await setUpdatesLastVisited()
          await refetchUpdateLastVisit()
          navigate(BackofficeUpdatesRoutes.route)
          setIsOpen(false)
        }}
        trailing={
          hasNotificationsUpdates && (
            <Badge
              css={{
                '& > span': {
                  backgroundColor: 'var(--color-accent-default)',
                  borderRadius: '4px',
                  color: 'var(--color-content-on-accent-default)',
                  fontSize: '12px',
                  height: '18px',
                  padding: '0 4px',
                  fontWeight: '400',
                  transform: 'none',
                  position: 'relative',
                },
              }}
            />
          )
        }
      />
      <Restricted requiredRights={[BoumRight.BACKOFFICE_STATUS_CRUD]}>
        <ActionItem
          label={t('authenticatedAppView_statusPage_button')}
          onClick={async () => {
            await setStatusLastVisited()
            await refetchStatusLastVisit()
            navigate(BackofficeStatusRoutes.route)
            setIsOpen(false)
          }}
          trailing={
            hasNotificationsStatuses && (
              <Badge
                css={{
                  '& > span': {
                    backgroundColor: 'var(--color-accent-default)',
                    borderRadius: '4px',
                    color: 'var(--color-content-on-accent-default)',
                    fontSize: '12px',
                    height: '18px',
                    padding: '0 4px',
                    fontWeight: '400',
                    transform: 'none',
                    position: 'relative',
                  },
                }}
              />
            )
          }
        />
      </Restricted>
      {getEnvironment() !== Environment.production && (
        <ActionItem
          label={t('authenticatedAppView_availableGroups_button')}
          trailing={<ChevronRightIcon />}
          onClick={() => {
            setModalOpen()
          }}
        />
      )}
      <Divider spacing={1} />
      <ActionItem
        label={t('authenticatedAppView_languageSwitch_button', {
          language:
            config.getLanguage() === Language.de
              ? t('common_german_label')
              : t('common_english_label'),
        })}
        trailing={<ChevronRightIcon />}
        onClick={e => {
          e.stopPropagation()
          setCurrentMenu('language')
        }}
      />
      <ActionItem
        label={t('authenticatedAppView_themeSwitch_button', {
          theme:
            appearanceOption === AppearanceOptions.system
              ? `${t('authenticatedAppView_appearanceOptionSystem')} ${
                  darkMode
                    ? t('authenticatedAppView_appearanceOptionDark')
                    : t('authenticatedAppView_appearanceOptionLight')
                }`
              : appearanceOption === AppearanceOptions.dark
                ? t('authenticatedAppView_appearanceOptionDark')
                : t('authenticatedAppView_appearanceOptionLight'),
        })}
        trailing={<ChevronRightIcon />}
        onClick={e => {
          e.stopPropagation()
          setCurrentMenu('theme')
        }}
      />
      <Divider spacing={1} />
      <LogoutButton />
    </motion.div>
  )
}
