/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { AdOperatorCallControlsVehicleFragmentDoc } from '../../../../../ad-operator-call/src/lib/components/AdOperatorCallControls.hook'
import { AdPoolingToggleFragmentDoc } from '../../../../../ad-pooling-toggle/src/lib/AdPoolingToggle.hook'
export type AdVehicleStreamHeaderVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  isWheelchairAccessible: boolean | null
  isSelfDriving: boolean
  label: number | null
  pooling: { __typename?: 'Pooling'; enabled: boolean } | null
  fccCurrentAdAlerts: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename?: 'FccAdAlert'
      id: string
      type: Types.FccAdAlertType
      status: Types.FccAdAlertStatus
    }>
  } | null
}

export const AdVehicleStreamHeaderVehicleFragmentDoc = gql`
    fragment AdVehicleStreamHeaderVehicle on Vehicle {
  id
  isWheelchairAccessible
  isSelfDriving
  label
  ...AdPoolingToggle
  ...AdOperatorCallControlsVehicle
}
    ${AdPoolingToggleFragmentDoc}
${AdOperatorCallControlsVehicleFragmentDoc}`
