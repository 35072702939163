/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { AdVehicleStreamHeaderFccVehicleFragmentDoc } from './AdVehiclePromptHeader/AdVehiclePromptHeader.hook'
import { AdVehicleStreamPassengerInfoVehicleFragmentDoc } from './AdVehiclePromptPassengerInfo/AdVehiclePromptPassengerInfo.hook'
import { AdVehicleStreamVehicleInfoVehicleFragmentDoc } from './AdVehiclePromptVehicleInfo/AdVehiclePromptVehicleInfo.hook'
const defaultOptions = {} as const
export type AdVehiclePromptQueryVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type AdVehiclePromptQuery = {
  __typename?: 'Query'
  vehicle: {
    __typename?: 'Vehicle'
    id: string
    label: number | null
    isWheelchairAccessible: boolean | null
    fccCurrentAdAlerts: {
      __typename?: 'FccAdAlerts'
      count: number
      alerts: Array<{
        __typename: 'FccAdAlert'
        id: string
        status: Types.FccAdAlertStatus
        type: Types.FccAdAlertType
        assignee: { __typename?: 'BackofficeUser'; id: string } | null
      }>
    } | null
    fccUpcomingWaypoints: Array<{
      __typename?: 'FccWaypoint'
      adState: Types.FccWaypointState | null
      id: string
      actions: Array<
        | {
            __typename: 'FccWaypointDeliveryAction'
            id: string
            estimatedAt: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointHubReturnAction' }
        | {
            __typename: 'FccWaypointPickupAction'
            id: string
            estimatedAt: string
            adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointReallocationAction' }
      >
    }> | null
  }
}

export const AdVehiclePromptDocument = gql`
    query AdVehiclePrompt($vehicleId: ID!) {
  vehicle: vehicleByID(id: $vehicleId) {
    id
    fccCurrentAdAlerts {
      alerts {
        id
        status
        assignee {
          id
        }
      }
    }
    ...AdVehicleStreamHeaderFccVehicle
    ...AdVehicleStreamVehicleInfoVehicle
    ...AdVehicleStreamPassengerInfoVehicle
  }
}
    ${AdVehicleStreamHeaderFccVehicleFragmentDoc}
${AdVehicleStreamVehicleInfoVehicleFragmentDoc}
${AdVehicleStreamPassengerInfoVehicleFragmentDoc}`

/**
 * __useAdVehiclePromptQuery__
 *
 * To run a query within a React component, call `useAdVehiclePromptQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdVehiclePromptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdVehiclePromptQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAdVehiclePromptQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdVehiclePromptQuery,
    AdVehiclePromptQueryVariables
  > &
    (
      | { variables: AdVehiclePromptQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdVehiclePromptQuery, AdVehiclePromptQueryVariables>(
    AdVehiclePromptDocument,
    options,
  )
}
export function useAdVehiclePromptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdVehiclePromptQuery,
    AdVehiclePromptQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdVehiclePromptQuery,
    AdVehiclePromptQueryVariables
  >(AdVehiclePromptDocument, options)
}
export function useAdVehiclePromptSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdVehiclePromptQuery,
        AdVehiclePromptQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AdVehiclePromptQuery,
    AdVehiclePromptQueryVariables
  >(AdVehiclePromptDocument, options)
}
export type AdVehiclePromptQueryHookResult = ReturnType<
  typeof useAdVehiclePromptQuery
>
export type AdVehiclePromptLazyQueryHookResult = ReturnType<
  typeof useAdVehiclePromptLazyQuery
>
export type AdVehiclePromptSuspenseQueryHookResult = ReturnType<
  typeof useAdVehiclePromptSuspenseQuery
>
export type AdVehiclePromptQueryResult = Apollo.QueryResult<
  AdVehiclePromptQuery,
  AdVehiclePromptQueryVariables
>
