import {
  CustomerAreaDataTestIds,
  CustomerAreaRoutes,
} from '@backoffice-frontend/common'
import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { UsersIcon } from '@moia-dev/pace-icons'

export const CustomerAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      dataTestId={CustomerAreaDataTestIds.Navigation}
      Icon={UsersIcon}
      label={label}
      open={open}
      to={CustomerAreaRoutes.route}
    />
  )
}
