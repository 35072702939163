import { AccidentsReportsAreaRoutes } from '@backoffice-frontend/common'
import {
  NavigationItem,
  WarningIconOutlined,
} from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'

export const AccidentsReportsAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={AccidentsReportsAreaRoutes.route}
      Icon={WarningIconOutlined}
    />
  )
}
