import { formatDateTime } from '@backoffice-frontend/common'
import { useErrorHandlingQuery } from '@backoffice-frontend/data-fetching'
import {
  CellWrapper,
  LinearProgress,
  MoiaCardFilter,
  MoiaDrawerLayout,
  MoiaGrid,
  MoiaTableCard,
  VirtualizedTable,
  useDrawer,
} from '@backoffice-frontend/patterns'
import { Restricted } from '@backoffice-frontend/restricted'
import { useBackofficeStatusesQuery } from '@backoffice-frontend/status-api'
import { BoumRight } from '@moia-dev/moia-token-claims'
import orderBy from 'lodash/orderBy'
import { useTranslation } from 'react-i18next'
import { SortDirection } from 'react-virtualized'
import { BackofficeStatusCreateDialog } from './BackofficeStatusCreateDialog'
import { BackofficeStatusDrawer } from './BackofficeStatusDrawer'
import { BackofficeStatusUpdateDialog } from './BackofficeStatusUpdateDialog'
import { TranslatedType } from './translateBackofficeStatus'

export const BackofficeStatusTable = () => {
  const [id, setId] = useDrawer('')
  const { t } = useTranslation()
  const { data, loading } = useErrorHandlingQuery(useBackofficeStatusesQuery, {
    variables: {},
  })
  const filteredStatuses = data?.backofficeStatuses.statuses

  return (
    <MoiaDrawerLayout
      open={Boolean(id)}
      drawer={
        <BackofficeStatusDrawer id={id ?? ''} onClose={() => setId('')} />
      }
    >
      <MoiaTableCard
        filters={
          <MoiaCardFilter
            filters={
              <MoiaGrid
                column
                alignItems="center"
                gridTemplateColumns="min-content min-content min-content min-content"
              />
            }
            action={
              <Restricted requiredRights={[BoumRight.BACKOFFICE_STATUS_CRUD]}>
                <BackofficeStatusCreateDialog />
              </Restricted>
            }
          />
        }
      >
        {loading && <LinearProgress />}
        <VirtualizedTable
          rows={filteredStatuses ?? []}
          initialSortDirection={SortDirection.DESC}
          initialSortKey="creationDate"
          onRowClick={({ rowData }) => {
            setId(rowData?.id)
          }}
          activeRow={id}
          getId={row => row?.id ?? ''}
          hasActions
          sortList={({ sortBy, sortDirection }) => {
            return orderBy(
              filteredStatuses,
              [
                row => {
                  switch (sortBy) {
                    case 'title':
                      return row?.title ?? ''
                    case 'creationDate':
                      return row?.creationDate
                    case 'status':
                      return t(row?.type ?? '')
                    default:
                      return ''
                  }
                },
              ],
              [sortDirection],
            )
          }}
          columns={[
            {
              dataKey: 'creationDate',
              label: t('common_date_label'),
              width: 120,
              cellRenderer: ({ rowData }) => (
                <CellWrapper>
                  {formatDateTime(rowData?.creationDate ?? '')}
                </CellWrapper>
              ),
            },
            {
              dataKey: 'status',
              label: t('common_status_label'),
              width: 200,
              cellRenderer: ({ rowData }) => (
                <CellWrapper>
                  <TranslatedType type={rowData?.type} />
                </CellWrapper>
              ),
            },
            {
              dataKey: 'title',
              label: t('common_title_label'),
              cellRenderer: ({ rowData }) => (
                <CellWrapper>
                  <MoiaGrid
                    column
                    justifyContent={'space-between'}
                    css={{ width: '100%' }}
                  >
                    <b>{rowData?.title ?? ''}</b>
                    <Restricted
                      requiredRights={[BoumRight.BACKOFFICE_STATUS_CRUD]}
                    >
                      <BackofficeStatusUpdateDialog id={rowData?.id ?? ''} />
                    </Restricted>
                  </MoiaGrid>
                </CellWrapper>
              ),
            },
          ]}
        />
      </MoiaTableCard>
    </MoiaDrawerLayout>
  )
}
