import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { NavigationItem } from '@backoffice-frontend/patterns'
import { useUserHasAccess } from '@backoffice-frontend/restricted'
import { UserManagementAreaRoutes } from '@backoffice-frontend/user-management-api'
import { BoumRight } from '@moia-dev/moia-token-claims'
import { SettingsIcon } from '@moia-dev/pace-icons'

export const UserManagementAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  const userHasAccessToUserDetails = useUserHasAccess({
    requiredRights: [BoumRight.BACKOFFICE_USER_USER_R],
  })

  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={
        userHasAccessToUserDetails
          ? UserManagementAreaRoutes.Users.route
          : UserManagementAreaRoutes.Groups.route
      }
      Icon={SettingsIcon}
    />
  )
}
