import { useAuthQrCode } from '@backoffice-frontend/authentication'
import { verifyTotpToken } from '@backoffice-frontend/common'
import { FormTextfield } from '@backoffice-frontend/forms'
import { Box, useMoiaErrorNotification } from '@backoffice-frontend/patterns'
import { usePaceValidators } from '@backoffice-frontend/validators'
import { Button, LoadingIndicator, Text } from '@moia-dev/pace-web'
import { QRCodeCanvas as QRCode } from 'qrcode.react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FormValues = {
  validationToken: string
}

export const MultiFactorRegistrationForm = ({
  onRedirect,
  isMobile,
}: {
  onRedirect: VoidFunction
  isMobile: boolean
}) => {
  const { t } = useTranslation()
  const { required } = usePaceValidators()
  const { code, qrCodeUrl } = useAuthQrCode()
  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()

  const { handleSubmit, control, formState } = useForm<FormValues>({
    defaultValues: { validationToken: '' },
    mode: 'onChange',
  })

  const onSubmit = async ({ validationToken }: FormValues) => {
    try {
      const res = await verifyTotpToken(validationToken)
      if (res === 'SUCCESS') {
        onRedirect()
      }
    } catch {
      enqueueMoiaErrorNotification(t('loginView_mfaCodeInvalid'))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ul
        css={theme => ({
          listStyle: 'numbered',
          paddingLeft: theme.spacing(3),
          marginTop: theme.space.Space6,
          marginBottom: theme.space.Space6,
        })}
      >
        <li>
          <Text color="default" size="sm">
            {t('loginView_mfaRegistrationStep1')}
          </Text>
        </li>
        <li>
          <Text size="sm">{t('loginView_mfaRegistrationStep2')}</Text>
          <Text size="sm">
            {qrCodeUrl && (
              <QRCode
                css={theme => ({
                  display: 'block',
                  marginBottom: theme.space.Space3,
                  marginTop: theme.space.Space3,
                })}
                size={85}
                value={qrCodeUrl}
              />
            )}
          </Text>
        </li>
        <li>
          <Text size="sm">
            {t('loginView_mfaRegistrationStep3')}{' '}
            <span style={{ wordWrap: 'break-word' }}>{code}</span>
          </Text>
        </li>
        <li>
          <Text size="sm">{t('loginView_mfaRegistrationStep4')}</Text>
        </li>
      </ul>
      <FormTextfield
        control={control}
        name="validationToken"
        label={t('loginView_mfaCode_label')}
        rules={{ required }}
        caption={t('loginView_mfaCode_caption')}
        size={isMobile ? 'compact' : 'default'}
      />
      <Box mt={3} display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          type="submit"
          disabled={!formState.isValid || formState.isSubmitting}
          variant="primary"
          label={t('loginView_login_button')}
          size="compact"
          icon={formState.isSubmitting ? <LoadingIndicator /> : undefined}
        />
      </Box>
    </form>
  )
}
