// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import { StopNetworkAlertsAreaId } from './StopNetworkAlertsAreaId'
import { StopNetworkAlertsAreaRoutes } from './StopNetworkAlertsAreaRoutes'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { StopNetworkAlertsAreaNavigation } from './StopNetworkAlertsAreaNavigation'

const LazyStopNetworkAlertsComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./StopNetworkAlertsArea')),
)

const StopNetworkAlertsComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyStopNetworkAlertsComponent />
  </SuspenseLoadingWrapper>
)

export const StopNetworkAlertsArea = {
  id: StopNetworkAlertsAreaId,
  requiredRight: BoumRight.AREA_STOP_NETWORK_ALERTS,
  NavigationComponent: StopNetworkAlertsAreaNavigation,
  route: {
    path: StopNetworkAlertsAreaRoutes.route,
    component: StopNetworkAlertsComponent,
  },
}
