import { BACKOFFICE_EMAIL, getCachedEmail } from '@backoffice-frontend/common'
import { useErrorHandlingQuery } from '@backoffice-frontend/data-fetching'
import { useEffect, useState } from 'react'
import store from 'store'
import { useBackofficeUserByEmailQuery } from './graphql/userByEmail.hook'

/**
 * A hook that combines useLoggedInUserEmail and useBackofficeUser to return the User object for the currentAuthenticatedUser
 * @returns user
 */
export const useBackofficeLoggedInUser = () => {
  const email = useLoggedInUserEmail() ?? ''
  const { data, loading } = useErrorHandlingQuery(
    useBackofficeUserByEmailQuery,
    {
      variables: { email },
      skip: !email,
    },
  )

  return {
    loading,
    user: data?.backofficeUserByEmail,
  }
}

export const useLoggedInUserEmail = () => {
  const [email, setEmail] = useState<string | undefined>(
    store.get(BACKOFFICE_EMAIL),
  )
  useEffect(() => {
    getCachedEmail().then(email => {
      setEmail(email)
    })
  }, [])
  return email
}

export const getFullNameFromUser = (user?: {
  firstName?: string
  lastName?: string
}) => {
  return `${user?.firstName ?? ''} ${user?.lastName ?? ''}`
}
