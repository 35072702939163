import { transformDataTestIds } from '../utils'
import { EmployeeAreaId } from './EmployeeAreaId'

const BaseEmployeeAreaDataTestIds = {
  Base: EmployeeAreaId,
  Navigation: 'navigation',
  List: {
    Base: 'list',
    Header: 'header',
    Table: 'table',
    TableRow: 'table-row',
    TableBody: 'table-body',
    OpenCreateDialogButton: 'open-create-dialog-button',
    SupervisorFilter: 'employee-list-supervisor-filter',
  },
  Details: {
    Base: 'details',
    Header: 'header',
    AssignedSupervisor: 'assigned-supervisor',
    AssignSupervisorButton: 'assign-supervisor',
    StatusSelector: 'status-selector',
    RoleChip: 'role-chip',
    EmployeeBlockedTag: 'employee-blocked-tag',
    AddRoleButton: 'add-role-button',
    EmployeeDataForm: 'employee-form',
    EmployeeDataServiceAreaSelection: 'employee-data-service-area-selection',
    SubmitEmployeeFormButton: 'employee-data-submit-button',
    GoToEmployeeButton: 'go-to-employee-button',
    CloseEmployeeCreatedDialogButton: 'close-employee-created-dialog-button',
    HubCheckInOutButton: 'hub-check-in-out-btn',
    Subnavigation: {
      Base: 'subnavigation',
      PersonalData: 'personal-data',
      Shifts: 'shifts',
      DriverLog: 'driver-log',
      Settings: 'settings',
      Tips: 'tips',
      Notes: 'notes',
      Messaging: 'messaging',
    },
    EmployeeNotes: {
      Base: 'notes',
      AddNoteButton: 'add-note-button',
      SubjectDropdown: 'subject-dropdown',
      CategoryDropdown: 'category-dropdown',
    },
    Notes: {
      Base: 'notes',
      CreateNoteButton: 'create-note-button',
      AddNoteButton: 'add-note-button',
      NoteTitleField: 'note-title',
      NoteTextField: 'note-text',
      DeleteNoteButton: 'delete-note-button',
      EditNoteButton: 'edit-note-button',
      NoteSummary: 'note-summary',
      NoteAttachment: 'uploaded-file',
      FileUploadInput: 'file-upload-input',
    },
    Messaging: {
      Base: 'messaging',
      SendMessageButton: 'send-message-button',
      SubjectField: 'subject-field',
      SubjectTextField: 'subject-text-field',
      MessageField: 'message-field',
      MethodEmailCheckbox: 'method-email-checkbox',
      MethodSMSCheckbox: 'method-sms-checkbox',
      MethodPushCheckbox: 'method-push-checkbox',
      List: 'list',
      Table: 'table',
      TableRow: 'table-row',
    },
    Shifts: {
      Base: 'shifts',
      WeekPicker: 'week-picker',
      ShiftListRow: 'shift-list-row',
      ShiftListRowExpanded: 'shift-list-row-expanded',
      ShiftListRowExpandIcon: 'shift-list-row-expand-icon',
      ShiftRemoveIcon: 'shift-remove-icon',
      ShiftsExecuted: 'shifts-executed',
    },
    DriverLog: {
      Base: 'DriverLog',
      WeekPicker: 'week-picker',
      EventTime: 'eventTime',
      List: 'list',
      Type: 'type',
      Details: 'details',
    },
    Tips: {
      Base: 'tips',
      PeriodsList: {
        Base: 'periods-list',
        Table: 'table',
        TableRow: 'table-row',
      },
    },
    Settings: {
      Base: 'settings',
      SuspendToggleButton: 'suspend-toggle-button',
      BlockForm: {
        Base: 'block-form',
        Description: 'description',
        Reason: 'subject',
      },
    },
  },
  GraphQlErrorView: {
    Base: 'graphql-error-view',
    Dialog: 'dialog',
  },
  MassMessaging: {
    Base: 'mass-messaging',
    SubjectField: 'subject-field',
    SubjectTextField: 'subject-text-field',
    MessageField: 'message-field',
    MessageTypeDefault: 'message-type-default',
    MessageTypeCustom: 'message-type-custom',
    MethodEmailCheckbox: 'method-email-checkbox',
    MethodSMSCheckbox: 'method-sms-checkbox',
    MethodPushCheckbox: 'method-push-checkbox',
    DialogFooterInfo: 'dialog-footer-info',
    ConfirmDialog: 'confirm-dialog',
  },
  AutocompleteField: {
    Base: 'autocomplete-field',
  },
}

export const EmployeeAreaDataTestIds = transformDataTestIds(
  BaseEmployeeAreaDataTestIds,
)
