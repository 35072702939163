import { AlertsAreaId } from '@backoffice-frontend/alerts'
import { AlertsTestingAreaId } from '@backoffice-frontend/alerts-testing'
import {
  AccidentsReportsAreaId,
  AccountingAreaId,
  AdConfigurationAreaId,
  AdFleetMapAreaId,
  CapacityPlanningAreaId,
  CouponAreaId,
  CustomerAreaId,
  CustomerFeatureAllowlistingAreaId,
  DisruptionsAreaId,
  DsBossAreaId,
  EmployeeAreaId,
  EmployeeTimeManagementAreaId,
  FleetMaintenanceAreaId,
  FleetManagementTestingAreaId,
  FleetMapAreaId,
  FleetsAreaId,
  HubsAreaId,
  LegalDocumentManagementAreaId,
  PatternLibraryAreaId,
  PricingAreaId,
  SafetyDriverGuidanceAreaId,
  ServiceHoursAreaId,
  ShiftsAreaId,
  ShiftsCreationAreaId,
  StopNetworkAreaId,
  VehicleAlertsAreaId,
  VehicleAreaId,
  VehicleGroupAreaId,
  VirtualAdVehiclesAreaId,
} from '@backoffice-frontend/common'
import { DriverTippingAreaId } from '@backoffice-frontend/driver-tipping'
import { OfferAndPoolingAreaId } from '@backoffice-frontend/offer-and-pooling'
import { PassengerAppAreaId } from '@backoffice-frontend/passenger-app'
import { ServiceSettingsAreaId } from '@backoffice-frontend/service-settings'
import { StopNetworkAlertsAreaId } from '@backoffice-frontend/stop-network-alerts'
import { TripAreaId } from '@backoffice-frontend/trip'
import { UserManagementAreaId } from '@backoffice-frontend/user-management'
import { useTranslation } from 'react-i18next'

export const useGetAreaTranslation = () => {
  const { t } = useTranslation()

  const getAreaTranslation = (id: string) => {
    switch (id) {
      case UserManagementAreaId:
        return t('authenticatedAppView_userManagementArea')
      case AlertsAreaId:
        return t('authenticatedAppView_alertsArea')
      case CapacityPlanningAreaId:
        return t('authenticatedAppView_capacityPlanningArea')
      case EmployeeAreaId:
        return t('authenticatedAppView_employeeArea')
      case ShiftsAreaId:
        return t('authenticatedAppView_shiftsArea')
      case DriverTippingAreaId:
        return t('authenticatedAppView_driverTippingArea')
      case EmployeeTimeManagementAreaId:
        return t('authenticatedAppView_employeeTimeManagementArea')
      case VehicleAlertsAreaId:
        return t('authenticatedAppView_vehicleAlertsArea')
      case FleetMaintenanceAreaId:
        return t('authenticatedAppView_fleetMaintenanceArea')
      case VehicleAreaId:
        return t('authenticatedAppView_vehicleArea')
      case FleetMapAreaId:
        return t('authenticatedAppView_fleetMapArea')
      case AdFleetMapAreaId:
        return t('authenticatedAppView_adFleetMapArea')
      case VehicleGroupAreaId:
        return t('authenticatedAppView_vehicleGroupArea')
      case StopNetworkAreaId:
        return t('authenticatedAppView_stopNetworkArea')
      case StopNetworkAlertsAreaId:
        return t('authenticatedAppView_stopNetworkAlertsArea')
      case HubsAreaId:
        return t('authenticatedAppView_hubsArea')
      case ServiceHoursAreaId:
        return t('authenticatedAppView_serviceHoursArea')
      case CustomerAreaId:
        return t('authenticatedAppView_customerArea')
      case TripAreaId:
        return t('authenticatedAppView_tripArea')
      case CouponAreaId:
        return t('authenticatedAppView_couponArea')
      case AdConfigurationAreaId:
        return t('authenticatedAppView_adConfigurationArea')
      case AccountingAreaId:
        return t('authenticatedAppView_accountingArea')
      case PassengerAppAreaId:
        return t('authenticatedAppView_passengerAppArea')
      case PricingAreaId:
        return t('authenticatedAppView_pricingArea')
      case AlertsTestingAreaId:
        return t('authenticatedAppView_alertsTestingArea')
      case PatternLibraryAreaId:
        return t('authenticatedAppView_patternLibraryArea')
      case ShiftsCreationAreaId:
        return t('authenticatedAppView_shiftsCreationArea')
      case FleetManagementTestingAreaId:
        return t('authenticatedAppView_fleetManagementTestingArea')
      case VirtualAdVehiclesAreaId:
        return t('authenticatedAppView_virtualAdVehiclesArea')
      case DsBossAreaId:
        return t('authenticatedAppView_dsBossArea')
      case CustomerFeatureAllowlistingAreaId:
        return t('authenticatedAppView_customerFeatureAllowlistingArea')
      case AccidentsReportsAreaId:
        return t('authenticatedAppView_accidentsReportsArea')
      case SafetyDriverGuidanceAreaId:
        return t('authenticatedAppView_safetyDriverGuidanceArea')
      case DisruptionsAreaId:
        return t('authenticatedAppView_disruptionsArea')
      case FleetsAreaId:
        return t('authenticatedAppView_fleetsArea')
      case OfferAndPoolingAreaId:
        return t('authenticatedAppView_offerAndPoolingArea')
      case ServiceSettingsAreaId:
        return t('authenticatedAppView_serviceSettingsArea')
      case LegalDocumentManagementAreaId:
        return t('authenticatedAppView_legalDocumentManagementArea')
      default:
        return id
    }
  }

  return { getAreaTranslation }
}
