import { platform } from '@backoffice-frontend/here-maps'
import { MoiaTooltip, useTheme } from '@backoffice-frontend/patterns'
import { trackClickEvent } from '@backoffice-frontend/tracking'
import { RoadIcon } from '@moia-dev/pace-icons'
import { Icon } from '@moia-dev/pace-web'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FleetMapsCommonAreaId } from '../FleetMapsCommonAreaId'
import { MapContext } from './HereMap/utils'

const trafficLayer = platform.createDefaultLayers().vector.traffic.map

export const TrafficButton = () => {
  const { map } = useContext(MapContext)
  const [showTraffic, setShowTraffic] = useState<boolean>(false)
  const { t } = useTranslation(FleetMapsCommonAreaId)
  const theme = useTheme()

  const toggleTrafficLayer = (traffic: boolean) => {
    if (traffic) {
      map?.addLayer(trafficLayer)
    } else {
      map?.removeLayer(trafficLayer)
    }
  }

  return (
    <MoiaTooltip title={showTraffic ? t('Hide traffic') : t('Show traffic')}>
      <button
        type="button"
        css={{
          height: 40,
          width: 40,
          borderRadius: '50%',
          border: 'none',
          boxShadow: theme.shadows[2],
          background: showTraffic
            ? theme.semantic.ColorAccentDefault
            : theme.semantic.ColorBackgroundDefault,
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          left: theme.spacing(8),

          ...(showTraffic
            ? {}
            : {
                '&:hover': {
                  backgroundColor:
                    theme.semantic.ColorActionPrimaryAlternativePressedReduced,
                  color: theme.semantic.ColorAccentDefault,
                  '& svg': {
                    stroke: theme.semantic.ColorAccentDefault,
                  },
                },
              }),
        }}
        onClick={() => {
          trackClickEvent('fleetmap_filter__show_traffic')
          setShowTraffic(!showTraffic)
          toggleTrafficLayer(!showTraffic)
        }}
      >
        <Icon size={20}>
          <RoadIcon
            color={
              showTraffic
                ? theme.semantic.ColorContentOnAccentDefault
                : theme.semantic.ColorActionSecondaryDefault
            }
          />
        </Icon>
      </button>
    </MoiaTooltip>
  )
}
