/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type GetGlobalRegionsQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type GetGlobalRegionsQuery = {
  __typename?: 'Query'
  regions: Array<{
    __typename?: 'Region'
    id: string
    name: string
    operatorTenant: string
  }>
}

export const GetGlobalRegionsDocument = gql`
    query getGlobalRegions {
  regions {
    id
    name
    operatorTenant
  }
}
    `

/**
 * __useGetGlobalRegionsQuery__
 *
 * To run a query within a React component, call `useGetGlobalRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlobalRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGlobalRegionsQuery,
    GetGlobalRegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetGlobalRegionsQuery, GetGlobalRegionsQueryVariables>(
    GetGlobalRegionsDocument,
    options,
  )
}
export function useGetGlobalRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGlobalRegionsQuery,
    GetGlobalRegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetGlobalRegionsQuery,
    GetGlobalRegionsQueryVariables
  >(GetGlobalRegionsDocument, options)
}
export function useGetGlobalRegionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetGlobalRegionsQuery,
        GetGlobalRegionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetGlobalRegionsQuery,
    GetGlobalRegionsQueryVariables
  >(GetGlobalRegionsDocument, options)
}
export type GetGlobalRegionsQueryHookResult = ReturnType<
  typeof useGetGlobalRegionsQuery
>
export type GetGlobalRegionsLazyQueryHookResult = ReturnType<
  typeof useGetGlobalRegionsLazyQuery
>
export type GetGlobalRegionsSuspenseQueryHookResult = ReturnType<
  typeof useGetGlobalRegionsSuspenseQuery
>
export type GetGlobalRegionsQueryResult = Apollo.QueryResult<
  GetGlobalRegionsQuery,
  GetGlobalRegionsQueryVariables
>
