import type { SvgIconProps } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { Subtitle1 } from '../basics/Typography'
import { useTheme } from '../basics/theme'
import { MoiaTooltip } from '../muiRexports'
import { MoiaTag } from '../patterns/Tag/MoiaTag'
import { Badge } from './Badge'

export type AreaNavigationProps = {
  onClick?: VoidFunction
  open: boolean
  label: string
}

export type NavigationIcon =
  | React.ForwardRefExoticComponent<
      Omit<React.SVGProps<SVGSVGElement>, 'ref'> &
        React.RefAttributes<SVGSVGElement>
    >
  | React.FC<SvgIconProps>

export const NavigationItem = ({
  badgeCount,
  dataTestId,
  Icon,
  label,
  onClick,
  open,
  to,
}: {
  badgeCount?: number
  Icon: NavigationIcon
  to: string
  dataTestId?: string
} & AreaNavigationProps) => {
  const theme = useTheme()

  return (
    <NavLink
      to={to}
      onClick={onClick}
      css={{
        marginBottom: 'var(--space-2)',
        background: theme.semantic.ColorSurfaceDefault,
        '&.active>div': {
          color: theme.semantic.ColorContentOnActionPrimaryDefault,
          background: theme.semantic.ColorActionPrimaryDefault,
          h6: {
            color: theme.semantic.ColorContentOnAccentDefault,
          },
          '&:hover': {
            background: theme.semantic.ColorActionPrimaryDefaultHovered,
          },
        },
        display: 'block',
        textDecoration: 'none',
        color: theme.semantic.ColorActionSecondaryDefault,
        '>div:hover': {
          background: theme.semantic.ColorSurfaceHovered,
        },
        '&:active>div': {
          background: theme.semantic.ColorSurfacePressed,
        },
      }}
      aria-label={label}
    >
      {({ isActive }) => (
        <MoiaTooltip
          disableHoverListener={open}
          title={label}
          aria-label={dataTestId}
          placement="right"
        >
          <div
            css={
              open
                ? {
                    height: '48px',
                    display: 'grid',
                    gridAutoFlow: 'column',
                    justifyContent: 'start',
                    alignItems: 'center',
                    gap: 'var(--space-2)',
                    padding: 'var(--space-3)',
                    borderRadius: 'var(--radius-medium)',
                    gridTemplateColumns: 'auto auto 1fr',
                  }
                : {
                    width: '48px',
                    display: 'flex',
                    height: '48px',
                    borderRadius: '100%',
                    textDecoration: 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }
            }
            data-testid={dataTestId}
          >
            {badgeCount && !open ? (
              <Badge overlap="circular" variant="dot" color="error">
                <Icon />
              </Badge>
            ) : (
              <Icon
                css={{
                  color: isActive
                    ? theme.semantic.ColorContentOnActionPrimaryDefault
                    : theme.semantic.ColorActionSecondaryDefault,
                }}
              />
            )}
            {open && (
              <>
                <Subtitle1
                  noWrap
                  css={{
                    color: theme.semantic.ColorActionSecondaryDefault,
                  }}
                >
                  {label}
                </Subtitle1>
                {!!badgeCount && (
                  <MoiaTag
                    color="info"
                    css={{
                      backgroundColor: theme.semantic.ColorAccentDefault,
                      color: theme.semantic.ColorContentOnAccentDefault,
                      fontWeight: 700,
                      marginLeft: 'auto',
                      padding: theme.spacing(1),
                    }}
                    slim
                  >
                    {badgeCount}
                  </MoiaTag>
                )}
              </>
            )}
          </div>
        </MoiaTooltip>
      )}
    </NavLink>
  )
}
