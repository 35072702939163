import { ReportIoAreaRoutes } from '@backoffice-frontend/common'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { NavigationItem } from '@backoffice-frontend/patterns'
import { WarningSolidIcon } from '@moia-dev/pace-icons'

// TODO: add your NavigationItem to the main menu
// check docs/how-tos/add-a-new-library.md for more info

// TODO: Replace icon with your actual area icon
export const ReportIoAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={ReportIoAreaRoutes.route}
      Icon={WarningSolidIcon}
    />
  )
}
