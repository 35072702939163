import { useErrorHandlingMutation } from '@backoffice-frontend/data-fetching'
import type { BackofficeUpdateDelete } from '@backoffice-frontend/graphql'
import { useToggle } from '@backoffice-frontend/hooks'
import {
  Body2,
  MoiaButton,
  MoiaDialog,
  MoiaDialogContent,
  MoiaDialogFormFooter,
  useMoiaSuccessNotification,
} from '@backoffice-frontend/patterns'
import { trackClickEvent } from '@backoffice-frontend/tracking'
import {
  BackofficeUpdatesByRightsDocument,
  useBackofficeUpdateDeleteMutation,
} from '@backoffice-frontend/updates-api'
import { useTranslation } from 'react-i18next'

export type BackofficeUpdateDeleteButtonProps = {
  onDelete?: VoidFunction
} & BackofficeUpdateDelete

export const BackofficeUpdateDeleteButton = ({
  id,
  onDelete,
}: BackofficeUpdateDeleteButtonProps) => {
  const { t } = useTranslation()
  const { enqueueMoiaSuccessNotification } = useMoiaSuccessNotification()

  const [confirmDialogOpen, toggleConfirmDialogOpen] = useToggle()

  const [backofficeUpdateDelete, { loading }] = useErrorHandlingMutation(
    useBackofficeUpdateDeleteMutation,
    {
      onCompleted: result => {
        if (result.backofficeUpdateDelete.success) {
          enqueueMoiaSuccessNotification(t('updates_toast_deleteUpdateSuccess'))
          onDelete?.()
        }
      },
    },
  )

  const deleteBackofficeUpdate = async () => {
    await backofficeUpdateDelete({
      variables: {
        input: {
          id: id ?? '',
        },
      },
      refetchQueries: [BackofficeUpdatesByRightsDocument],
    })
  }

  return (
    <>
      <MoiaDialog
        maxWidth="lg"
        open={confirmDialogOpen}
        onClose={toggleConfirmDialogOpen}
        header={t('updates_dialog_deleteUpdate_header')}
      >
        <MoiaDialogContent>
          <Body2 paragraph>{t('updates_dialog_deleteUpdate')}</Body2>
        </MoiaDialogContent>
        <MoiaDialogFormFooter
          trailing={
            <>
              <MoiaButton onClick={toggleConfirmDialogOpen}>
                {t('common_back_button')}
              </MoiaButton>
              <MoiaButton
                submitting={loading}
                onClick={deleteBackofficeUpdate}
                color="primary"
              >
                {t('updates_dialog_deleteUpdate_button')}
              </MoiaButton>
            </>
          }
        />
      </MoiaDialog>
      <MoiaButton
        submitting={loading}
        onClick={() => {
          trackClickEvent('click_backofficeupdate_delete')
          toggleConfirmDialogOpen()
        }}
        danger
      >
        {t('common_delete_button')}
      </MoiaButton>
    </>
  )
}
