import { getUsername, setUpTOTP } from '@backoffice-frontend/common'
import { Environment, getEnvironment } from '@backoffice-frontend/environment'
import { useEffect, useState } from 'react'

export const useAuthQrCode = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState('')
  const [code, setCode] = useState('')

  const getEnv = () => {
    switch (getEnvironment()) {
      case Environment.development:
        return '[DEV]'
      case Environment.integration:
        return '[INT]'
      default:
        return ''
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    void Promise.all([setUpTOTP(), getUsername()]).then(([code, username]) => {
      setCode(code)
      setQrCodeUrl(
        `otpauth://totp/AWSCognito:${username}?secret=${code}&issuer=${getEnv()}MOIA-Backoffice-Frontend`,
      )
    })
  }, [])

  return { code, qrCodeUrl }
}
