import { getIsoDateFormatWithoutTime } from '@backoffice-frontend/common'
import type { DesktopDatePickerProps as MuiDesktopDatePickerProps } from '@mui/x-date-pickers'
import { DesktopDatePicker as MuiDesktopDatePicker } from '@mui/x-date-pickers'
import {
  ArrowDropLeftIcon,
  ArrowDropRightIcon,
  CalendarIcon,
  ChevronDownIcon,
} from '../../basics/Icons'
import { MoiaTextField } from '../MoiaTextField/MoiaTextField'
import './DatePickerStyles.css'
import { DatePickersActionBar } from './DatePickersActionBar'

export type DatePickerProps<Value = Date | null | unknown> =
  MuiDesktopDatePickerProps<Value>

/**
 * @deprecated Use the DatePicker component from pace-web instead.
 */
export function DatePicker<Value>({ ...restProps }: DatePickerProps<Value>) {
  return (
    <MuiDesktopDatePicker
      closeOnSelect={false}
      dayOfWeekFormatter={(day: string) => day}
      format={getIsoDateFormatWithoutTime()}
      {...restProps}
      slots={{
        actionBar: DatePickersActionBar,
        leftArrowIcon: ArrowDropLeftIcon,
        openPickerIcon: CalendarIcon,
        rightArrowIcon: ArrowDropRightIcon,
        switchViewIcon: ChevronDownIcon,
        textField: MoiaTextField,
        ...restProps.slots,
      }}
      slotProps={{
        actionBar: {
          actions: ['clear', 'cancel', 'accept'],
        },
        inputAdornment: {
          sx: theme => ({
            svg: { color: theme.semantic.ColorActionSecondaryDefault },
          }),
        },
        ...restProps.slotProps,
      }}
    />
  )
}
