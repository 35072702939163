import { MoiaGrid } from '@backoffice-frontend/patterns'
import { Button, Text } from '@moia-dev/pace-web'
import { useTranslation } from 'react-i18next'
import { useIsSystemUser } from './useIsSystemUser'

export const MultiFactorAuthenticationInformation = ({
  onNextClick,
  isMobile,
}: {
  onNextClick: VoidFunction
  isMobile: boolean
}) => {
  const { t } = useTranslation()
  useIsSystemUser()
  return (
    <MoiaGrid mt={isMobile ? 1 : 3} gridGap={isMobile ? 1 : 2}>
      <Text size="sm">{t('loginView_mfaInformation')}</Text>
      <MoiaGrid justifyContent="end" gridTemplateColumns="max-content" mt={1}>
        <Button
          onClick={onNextClick}
          variant="primary"
          type="submit"
          size="compact"
          label={t('loginView_mfaInformationContinue_button')}
        />
      </MoiaGrid>
    </MoiaGrid>
  )
}
