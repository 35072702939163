import { transformRoutes } from '../utils'

export const AccountingAreaBaseRoutes = {
  route: '/accounting',
  Reports: {
    route: '/reports',
  },
  Report: {
    route: '/report/:reportId',
  },
  GeneralLedger: {
    route: '/general-ledger',
  },
  ReceivableOverview: {
    route: '/receivable-overview',
  },
}

export const AccountingAreaRoutes = transformRoutes(AccountingAreaBaseRoutes)
