// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import {
  EmployeeAreaDataTestIds,
  EmployeeAreaId,
  EmployeeAreaRoutes,
} from '@backoffice-frontend/common'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { EmployeeAreaNavigation } from './EmployeeAreaNavigation'

const LazyEmployeeComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./EmployeeArea')),
)

const EmployeeComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyEmployeeComponent />
  </SuspenseLoadingWrapper>
)

export const EmployeeArea = {
  id: EmployeeAreaId,
  dataTestIds: EmployeeAreaDataTestIds,
  requiredRight: BoumRight.AREA_EMPLOYEE,
  NavigationComponent: EmployeeAreaNavigation,
  route: {
    path: EmployeeAreaRoutes.route,
    component: EmployeeComponent,
  },
}
