import type {
  WorkOrderServiceCategory,
  WorkOrderServiceItem,
} from '@backoffice-frontend/graphql'
import { VehicleCommonAreaId } from '@backoffice-frontend/vehicle-common'
import { useTranslation } from 'react-i18next'

export const useWorkOrderServiceCategoryTranslation = () => {
  const { t } = useTranslation(VehicleCommonAreaId)

  return (workOrderServiceCategory: WorkOrderServiceCategory): string => {
    const options: Record<WorkOrderServiceCategory, string> = {
      DAMAGES_DEFECTS: t('Damages and defects'),
      IVC: t('IVC'),
      HUB_ACTIVITIES: t('Hub activities'),
      MAINTENANCE_SERVICE: t('Maintenance and service'),
    }
    return options[workOrderServiceCategory] ?? workOrderServiceCategory
  }
}

export const useWorkOrderServiceItemTranslation = () => {
  const { t } = useTranslation(VehicleCommonAreaId)

  return (serviceItem: WorkOrderServiceItem): string => {
    const options: Record<WorkOrderServiceItem, string> = {
      CLEANING: t('Cleaning'),
      SPECIAL_CLEANING: t('Special Cleaning'),
      VEHICLE_LETTERING: t('Vehicle lettering'),
      TRANSFER_RIDE: t('Transfer ride'),
      TABLET_UPDATE: t('Tablet-Update'),
      SEAT_COMPLAINT: t('Seat complaint'),
      SMART_REPAIR_DENT: t('Smart repair - dent repairs'),
      SMART_REPAIR_PAINTWORK: t('Smart repair - paintwork'),
      ACTION_MANUFACTURER: t('Action - manufacturer'),
      ACTION_INTERNAL: t('Action - internal'),
      BRAKE_FLUID_CHANGE: t('Brake fluid change'),
    }
    return options[serviceItem] ?? serviceItem
  }
}
