import { inputFieldMinHeight } from '@backoffice-frontend/common'
import type { TextFieldProps } from '@mui/material'
import { useCallback } from 'react'
import type { FieldRenderProps } from 'react-final-form'
import { ChevronDownIcon } from '../../basics/Icons/ChevronDownIcon'
import { MoiaTextField } from '../MoiaTextField/MoiaTextField'
import { getFinalFormError } from './FormControlErrorHandler'

const textFieldStyle = {
  display: 'flex',
}

const textFieldMargins = {
  marginRight: 1,
  marginBottom: 10,
}

export type MoiaFormTextFieldProps<Value = unknown, InputValue = Value> = {
  /**
   * @deprecated
   */
  disableMinHeight?: boolean
  /**
   * @deprecated
   */
  disableMargins?: boolean
  /**
   * Feature flag to migrate legacy in line styles api
   */
  legacyStyles?: boolean

  translateValidation?: boolean
} & TextFieldProps &
  FieldRenderProps<Value, HTMLElement, InputValue>

/**
 * Wrapper component to be used with FinalForm
 *
 * @deprecated Use react-hook-form instead. See our forms documentation in our pattern-library or our docs.
 */
export function MoiaFormTextField<Value = unknown, InputValue = Value>({
  input,
  InputLabelProps,
  inputProps,
  InputProps,
  label,
  meta,
  type,
  disableMinHeight,
  disableMargins,
  helperText,
  classes,
  legacyStyles = true,
  fullWidth,
  variant = 'standard',
  disabled,
  ...restProps
}: MoiaFormTextFieldProps<Value, InputValue>) {
  const { error, errorHelperText } = getFinalFormError(meta)
  const style = legacyStyles
    ? {
        ...textFieldStyle,
        ...(disableMinHeight ? {} : { minHeight: inputFieldMinHeight }),
        ...(disableMargins ? {} : textFieldMargins),
      }
    : undefined
  const inputLabelProps =
    type === 'date' ? { ...InputLabelProps, shrink: true } : InputLabelProps

  const Icon = useCallback(
    () => (
      <ChevronDownIcon
        css={{
          stroke: disabled
            ? 'var(--color-content-disabled)'
            : 'var(--color-content-default)',
        }}
      />
    ),
    [disabled],
  )

  return (
    <MoiaTextField
      classes={classes}
      style={style}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth ?? !legacyStyles}
      helperText={errorHelperText ?? helperText}
      id={input.name}
      InputLabelProps={inputLabelProps}
      inputProps={inputProps}
      // Thanks, MUI! (https://material-ui.com/api/text-field/#props)
      InputProps={InputProps}
      label={label}
      SelectProps={{ IconComponent: Icon }}
      type={type}
      variant={variant}
      {...input}
      {...{ ...restProps }}
    />
  )
}
