import { Restricted } from '@backoffice-frontend/restricted'
import type { BoumRight } from '@moia-dev/moia-token-claims'
import type * as React from 'react'

export const MaybeRestrictedNavigationItem = ({
  children,
  requiredRight,
}: {
  children: React.ReactNode
  requiredRight?: BoumRight
}) => {
  return requiredRight ? (
    <Restricted requiredRights={[requiredRight]}>{children}</Restricted>
  ) : (
    <>{children}</>
  )
}

export const MaybeRestrictedSection = ({
  children,
  allowedRights,
}: {
  children: React.ReactNode
  allowedRights: (undefined | BoumRight)[]
}) => {
  return allowedRights.some(right => right === undefined) ? (
    <>{children}</>
  ) : (
    // some ensures this is never undefined
    <Restricted allowedRights={allowedRights as BoumRight[]}>
      {children}
    </Restricted>
  )
}
