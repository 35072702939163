/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { AdWaypointPassengersWaypointFragmentDoc } from '../../../../../../../ad-passenger-list/src/lib/AdWaypointPassengers/AdWaypointPassengers.hook'
import { GetPassengersWaypointFragmentDoc } from '../../../../../../../ad-passenger-list/src/lib/utils/passenger-filters.hook'
import {
  AdVehicleStreamAlertVehicleMapVehicleFragmentDoc,
  AdVehicleStreamAlertVehicleMapWaypointFragmentDoc,
} from '../../../../AlertVehicleMap/AlertVehicleMap.hook'
import { AdVehicleStreamEvaluateCabinSafetyCheckStatusFragmentDoc } from '../../../../evaluateCabinSafetyCheckStatus/evaluateCabinSafetyCheckStatus.hook'
import { AdVehicleStreamBoardingPassengersWaypointFragmentDoc } from '../../../BoardingPassengers/BoardingPassengers.hook'
const defaultOptions = {} as const
export type AdVehicleStreamUpcomingWaypointsQueryVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type AdVehicleStreamUpcomingWaypointsQuery = {
  __typename?: 'Query'
  vehicle: {
    __typename?: 'Vehicle'
    id: string
    upcomingWaypoints: Array<{
      __typename?: 'FccWaypoint'
      id: string
      adState: Types.FccWaypointState | null
      actions: Array<
        | {
            __typename: 'FccWaypointDeliveryAction'
            estimatedAt: string
            id: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename: 'FccWaypointHubReturnAction'; estimatedAt: string }
        | {
            __typename: 'FccWaypointPickupAction'
            adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
            estimatedAt: string
            id: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename: 'FccWaypointReallocationAction'; estimatedAt: string }
      >
      stop: {
        __typename?: 'Stop'
        routingLocation: {
          __typename?: 'Location'
          latitude: number
          longitude: number
        }
      } | null
    }> | null
  }
}

export type AdVehicleStreamWaypointAttendanceModalWaypointFragment = {
  __typename?: 'FccWaypoint'
  id: string
  adState: Types.FccWaypointState | null
  actions: Array<
    | {
        __typename: 'FccWaypointDeliveryAction'
        estimatedAt: string
        id: string
        trip: { __typename?: 'Trip'; id: string } | null
        customer: {
          __typename?: 'Customer'
          firstName: string | null
          lastName: string | null
          extendedWalkingTime: boolean
          visualImpairment: boolean | null
        } | null
        seats: {
          __typename?: 'FccWaypointSeatInfo'
          seats: number
          wheelchairs: number
          childSeats: number
          boosterSeats: number
        }
      }
    | { __typename: 'FccWaypointHubReturnAction'; estimatedAt: string }
    | {
        __typename: 'FccWaypointPickupAction'
        adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
        estimatedAt: string
        id: string
        trip: { __typename?: 'Trip'; id: string } | null
        customer: {
          __typename?: 'Customer'
          firstName: string | null
          lastName: string | null
          extendedWalkingTime: boolean
          visualImpairment: boolean | null
        } | null
        seats: {
          __typename?: 'FccWaypointSeatInfo'
          seats: number
          wheelchairs: number
          childSeats: number
          boosterSeats: number
        }
      }
    | { __typename: 'FccWaypointReallocationAction'; estimatedAt: string }
  >
  stop: {
    __typename?: 'Stop'
    routingLocation: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    }
  } | null
}

export const AdVehicleStreamWaypointAttendanceModalWaypointFragmentDoc = gql`
    fragment AdVehicleStreamWaypointAttendanceModalWaypoint on FccWaypoint {
  id
  adState
  ...GetPassengersWaypoint
  ...AdVehicleStreamBoardingPassengersWaypoint
  ...AdWaypointPassengersWaypoint
  ...AdVehicleStreamAlertVehicleMapWaypoint
}
    ${GetPassengersWaypointFragmentDoc}
${AdVehicleStreamBoardingPassengersWaypointFragmentDoc}
${AdWaypointPassengersWaypointFragmentDoc}
${AdVehicleStreamAlertVehicleMapWaypointFragmentDoc}`
export const AdVehicleStreamUpcomingWaypointsDocument = gql`
    query AdVehicleStreamUpcomingWaypoints($vehicleId: ID!) {
  vehicle: vehicleByID(id: $vehicleId) {
    id
    upcomingWaypoints: fccUpcomingWaypoints {
      ...AdVehicleStreamWaypointAttendanceModalWaypoint
      ...AdVehicleStreamEvaluateCabinSafetyCheckStatus
    }
  }
}
    ${AdVehicleStreamWaypointAttendanceModalWaypointFragmentDoc}
${AdVehicleStreamEvaluateCabinSafetyCheckStatusFragmentDoc}`

/**
 * __useAdVehicleStreamUpcomingWaypointsQuery__
 *
 * To run a query within a React component, call `useAdVehicleStreamUpcomingWaypointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdVehicleStreamUpcomingWaypointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdVehicleStreamUpcomingWaypointsQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAdVehicleStreamUpcomingWaypointsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdVehicleStreamUpcomingWaypointsQuery,
    AdVehicleStreamUpcomingWaypointsQueryVariables
  > &
    (
      | {
          variables: AdVehicleStreamUpcomingWaypointsQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdVehicleStreamUpcomingWaypointsQuery,
    AdVehicleStreamUpcomingWaypointsQueryVariables
  >(AdVehicleStreamUpcomingWaypointsDocument, options)
}
export function useAdVehicleStreamUpcomingWaypointsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdVehicleStreamUpcomingWaypointsQuery,
    AdVehicleStreamUpcomingWaypointsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdVehicleStreamUpcomingWaypointsQuery,
    AdVehicleStreamUpcomingWaypointsQueryVariables
  >(AdVehicleStreamUpcomingWaypointsDocument, options)
}
export function useAdVehicleStreamUpcomingWaypointsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdVehicleStreamUpcomingWaypointsQuery,
        AdVehicleStreamUpcomingWaypointsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AdVehicleStreamUpcomingWaypointsQuery,
    AdVehicleStreamUpcomingWaypointsQueryVariables
  >(AdVehicleStreamUpcomingWaypointsDocument, options)
}
export type AdVehicleStreamUpcomingWaypointsQueryHookResult = ReturnType<
  typeof useAdVehicleStreamUpcomingWaypointsQuery
>
export type AdVehicleStreamUpcomingWaypointsLazyQueryHookResult = ReturnType<
  typeof useAdVehicleStreamUpcomingWaypointsLazyQuery
>
export type AdVehicleStreamUpcomingWaypointsSuspenseQueryHookResult =
  ReturnType<typeof useAdVehicleStreamUpcomingWaypointsSuspenseQuery>
export type AdVehicleStreamUpcomingWaypointsQueryResult = Apollo.QueryResult<
  AdVehicleStreamUpcomingWaypointsQuery,
  AdVehicleStreamUpcomingWaypointsQueryVariables
>
