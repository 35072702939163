import { transformRoutes } from '../utils'

export const BaseCustomerAreaRoutes = {
  route: '/customers',
  List: {
    route: '',
    DeletionPending: {
      route: '/deletion-pending',
    },
  },
  Show: {
    route: '/customer/:customerId',
    Trips: {
      route: '/trips',
    },
    ListPackageContracts: {
      route: '/package-contract',
    },
    Referrals: {
      route: '/referrals',
    },
    Claims: {
      route: '/claims',
    },
  },
}

export const CustomerAreaRoutes = transformRoutes(BaseCustomerAreaRoutes)
