/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type AdVehicleStreamPassengerCardCustomerFragment = {
  __typename?: 'Customer'
  firstName: string | null
  lastName: string | null
  extendedWalkingTime: boolean
  visualImpairment: boolean | null
}

export type AdVehicleStreamConfirmManualCheckInPassengerCardSeatInfoFragment = {
  __typename?: 'FccWaypointSeatInfo'
  seats: number
  wheelchairs: number
  childSeats: number
  boosterSeats: number
}

export type AdVehicleStreamConfirmManualNoShowMutationVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
  tripId: Types.Scalars['ID']['input']
}>

export type AdVehicleStreamConfirmManualNoShowMutation = {
  __typename?: 'Mutation'
  fccConfirmManualNoShow: any | null
}

export type AdVehicleStreamConfirmManualCheckInMutationVariables = Types.Exact<{
  input: Types.FccManuallyCheckInPassengerInput
}>

export type AdVehicleStreamConfirmManualCheckInMutation = {
  __typename?: 'Mutation'
  fccManuallyCheckInPassenger: any | null
}

export const AdVehicleStreamPassengerCardCustomerFragmentDoc = gql`
    fragment AdVehicleStreamPassengerCardCustomer on Customer {
  firstName
  lastName
  extendedWalkingTime
  visualImpairment
}
    `
export const AdVehicleStreamConfirmManualCheckInPassengerCardSeatInfoFragmentDoc = gql`
    fragment AdVehicleStreamConfirmManualCheckInPassengerCardSeatInfo on FccWaypointSeatInfo {
  seats
  wheelchairs
  childSeats
  boosterSeats
}
    `
export const AdVehicleStreamConfirmManualNoShowDocument = gql`
    mutation AdVehicleStreamConfirmManualNoShow($vehicleId: ID!, $tripId: ID!) {
  fccConfirmManualNoShow(input: {tripId: $tripId, vehicleId: $vehicleId})
}
    `
export type AdVehicleStreamConfirmManualNoShowMutationFn =
  Apollo.MutationFunction<
    AdVehicleStreamConfirmManualNoShowMutation,
    AdVehicleStreamConfirmManualNoShowMutationVariables
  >

/**
 * __useAdVehicleStreamConfirmManualNoShowMutation__
 *
 * To run a mutation, you first call `useAdVehicleStreamConfirmManualNoShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdVehicleStreamConfirmManualNoShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adVehicleStreamConfirmManualNoShowMutation, { data, loading, error }] = useAdVehicleStreamConfirmManualNoShowMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useAdVehicleStreamConfirmManualNoShowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdVehicleStreamConfirmManualNoShowMutation,
    AdVehicleStreamConfirmManualNoShowMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdVehicleStreamConfirmManualNoShowMutation,
    AdVehicleStreamConfirmManualNoShowMutationVariables
  >(AdVehicleStreamConfirmManualNoShowDocument, options)
}
export type AdVehicleStreamConfirmManualNoShowMutationHookResult = ReturnType<
  typeof useAdVehicleStreamConfirmManualNoShowMutation
>
export type AdVehicleStreamConfirmManualNoShowMutationResult =
  Apollo.MutationResult<AdVehicleStreamConfirmManualNoShowMutation>
export type AdVehicleStreamConfirmManualNoShowMutationOptions =
  Apollo.BaseMutationOptions<
    AdVehicleStreamConfirmManualNoShowMutation,
    AdVehicleStreamConfirmManualNoShowMutationVariables
  >
export const AdVehicleStreamConfirmManualCheckInDocument = gql`
    mutation AdVehicleStreamConfirmManualCheckIn($input: FccManuallyCheckInPassengerInput!) {
  fccManuallyCheckInPassenger(input: $input)
}
    `
export type AdVehicleStreamConfirmManualCheckInMutationFn =
  Apollo.MutationFunction<
    AdVehicleStreamConfirmManualCheckInMutation,
    AdVehicleStreamConfirmManualCheckInMutationVariables
  >

/**
 * __useAdVehicleStreamConfirmManualCheckInMutation__
 *
 * To run a mutation, you first call `useAdVehicleStreamConfirmManualCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdVehicleStreamConfirmManualCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adVehicleStreamConfirmManualCheckInMutation, { data, loading, error }] = useAdVehicleStreamConfirmManualCheckInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdVehicleStreamConfirmManualCheckInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdVehicleStreamConfirmManualCheckInMutation,
    AdVehicleStreamConfirmManualCheckInMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdVehicleStreamConfirmManualCheckInMutation,
    AdVehicleStreamConfirmManualCheckInMutationVariables
  >(AdVehicleStreamConfirmManualCheckInDocument, options)
}
export type AdVehicleStreamConfirmManualCheckInMutationHookResult = ReturnType<
  typeof useAdVehicleStreamConfirmManualCheckInMutation
>
export type AdVehicleStreamConfirmManualCheckInMutationResult =
  Apollo.MutationResult<AdVehicleStreamConfirmManualCheckInMutation>
export type AdVehicleStreamConfirmManualCheckInMutationOptions =
  Apollo.BaseMutationOptions<
    AdVehicleStreamConfirmManualCheckInMutation,
    AdVehicleStreamConfirmManualCheckInMutationVariables
  >
