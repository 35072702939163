import { AlertsAreaRoutes } from '@backoffice-frontend/alerts-common'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { NavigationItem } from '@backoffice-frontend/patterns'
import { BellIcon } from '@moia-dev/pace-icons'
import { useAlertsBadgeCount } from './useAlertsBadgeCount'

export const AlertsAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  const { openPrebookingAlerts } = useAlertsBadgeCount()

  // link to prebooking alerts if open prebooking alerts > 0
  const targetRoute =
    openPrebookingAlerts > 0
      ? AlertsAreaRoutes.Prebookings.route
      : AlertsAreaRoutes.route

  return (
    <NavigationItem
      badgeCount={openPrebookingAlerts}
      onClick={onClick}
      open={open}
      label={label}
      to={targetRoute}
      Icon={BellIcon}
    />
  )
}
