import { useMediaQuery, useTheme } from '@backoffice-frontend/patterns'
import type { BoxProps } from '@mui/material'
import Box from '@mui/material/Box'

export const MoiaViewBox = ({
  children,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  ...restProps
}: BoxProps) => {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const defaultPadding = theme.space.Space4

  const responsivePaddingTop = isMobile ? 0 : (paddingTop ?? defaultPadding)
  const responsivePaddingBottom = isMobile
    ? 0
    : (paddingBottom ?? defaultPadding)
  const responsivePaddingLeft = isMobile ? 0 : (paddingLeft ?? defaultPadding)
  const responsivePaddingRight = isMobile ? 0 : (paddingRight ?? defaultPadding)

  return (
    <Box
      paddingTop={responsivePaddingTop}
      paddingBottom={responsivePaddingBottom}
      paddingLeft={responsivePaddingLeft}
      paddingRight={responsivePaddingRight}
      {...restProps}
    >
      {children}
    </Box>
  )
}
