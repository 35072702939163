import type { ReactNode, ReactPortal } from 'react'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

export const RenderToPortal = ({
  children,
  id,
}: {
  children?: ReactNode
  id: string
}): ReactPortal | null => {
  const [elem, setElem] = useState<HTMLElement | null>(null)
  useEffect(() => {
    const elem = document.getElementById(id)
    setElem(elem)
  }, [id])

  return elem ? ReactDOM.createPortal(children, elem) : null
}
