// Area route config

// This file was once generated when you created this area
// but you can edit it freely

const baseRoute = '/sc-labs'

export const ScLabsAreaRoutes = {
  route: baseRoute,
  path: '/',
}
