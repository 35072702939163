/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import {
  UseCurrentSortedAlertsAdAlertFragmentDoc,
  UseCurrentSortedAlertsTaskFragmentDoc,
} from '../../../../alerts-common/src/lib/hooks/useCurrentSortedAlerts.hook'
import {
  AlertAssignmentIconAdAlertFragmentDoc,
  AlertAssignmentIconTaskFragmentDoc,
} from './AlertAssignmentIcon.hook'
export type VehicleListOverlayRowVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  inService: boolean | null
  label: number | null
  taskNotifications: {
    __typename?: 'TaskNotificationList'
    tasks: Array<
      | {
          __typename?: 'HubServiceNotification'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'IvcTaskNotification'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'PassengerUnbuckled'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'RoadBlockageTaskNotification'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'ShiftDeviationNotification'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopAccessibilityDownvotedByDrivers'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopActivationFailedNotification'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopAreaMissingActiveStopsNotification'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopDownVotedByDrivers'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopPotentiallyUnreachable'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopValidationFailed'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'TimeManagementNotification'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'TripPrebookingNoAssignment'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleAvailabilityNotification'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleIncidentNotification'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleSurveillanceNotification'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleWaypointAttendance'
          status: Types.TaskNotificationStatus
          createdAt: string
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
    >
  } | null
  fccCurrentAdAlerts?: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename?: 'FccAdAlert'
      createdAt: string
      id: string
      status: Types.FccAdAlertStatus
      type: Types.FccAdAlertType
      assignee: { __typename?: 'BackofficeUser'; id: string } | null
    }>
  } | null
}

export const VehicleListOverlayRowVehicleFragmentDoc = gql`
    fragment VehicleListOverlayRowVehicle on Vehicle {
  id
  inService
  label
  taskNotifications(
    status: $taskNotificationStatus
    types: $taskNotificationTypes
  ) {
    tasks {
      status
      createdAt
      ...UseCurrentSortedAlertsTask
      ...AlertAssignmentIconTask
    }
  }
  fccCurrentAdAlerts @include(if: $withAdAlerts) {
    alerts {
      ...UseCurrentSortedAlertsAdAlert
      ...AlertAssignmentIconAdAlert
    }
  }
}
    ${UseCurrentSortedAlertsTaskFragmentDoc}
${AlertAssignmentIconTaskFragmentDoc}
${UseCurrentSortedAlertsAdAlertFragmentDoc}
${AlertAssignmentIconAdAlertFragmentDoc}`
