/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type RequestExteriorVideoStreamCredentialsMutationVariables =
  Types.Exact<{
    input: Types.RequestExteriorVideoStreamCredentialsInput
  }>

export type RequestExteriorVideoStreamCredentialsMutation = {
  __typename?: 'Mutation'
  fccRequestExteriorVideoStreamCredentials: {
    __typename?: 'ExteriorVideoStreamCredentials'
    token: string
  }
}

export type StopExteriorVideoStreamMutationVariables = Types.Exact<{
  input: Types.StopExteriorVideoStreamInput
}>

export type StopExteriorVideoStreamMutation = {
  __typename?: 'Mutation'
  fccStopExteriorVideoStream: any | null
}

export const RequestExteriorVideoStreamCredentialsDocument = gql`
    mutation RequestExteriorVideoStreamCredentials($input: RequestExteriorVideoStreamCredentialsInput!) {
  fccRequestExteriorVideoStreamCredentials(input: $input) {
    token
  }
}
    `
export type RequestExteriorVideoStreamCredentialsMutationFn =
  Apollo.MutationFunction<
    RequestExteriorVideoStreamCredentialsMutation,
    RequestExteriorVideoStreamCredentialsMutationVariables
  >

/**
 * __useRequestExteriorVideoStreamCredentialsMutation__
 *
 * To run a mutation, you first call `useRequestExteriorVideoStreamCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestExteriorVideoStreamCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestExteriorVideoStreamCredentialsMutation, { data, loading, error }] = useRequestExteriorVideoStreamCredentialsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestExteriorVideoStreamCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestExteriorVideoStreamCredentialsMutation,
    RequestExteriorVideoStreamCredentialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestExteriorVideoStreamCredentialsMutation,
    RequestExteriorVideoStreamCredentialsMutationVariables
  >(RequestExteriorVideoStreamCredentialsDocument, options)
}
export type RequestExteriorVideoStreamCredentialsMutationHookResult =
  ReturnType<typeof useRequestExteriorVideoStreamCredentialsMutation>
export type RequestExteriorVideoStreamCredentialsMutationResult =
  Apollo.MutationResult<RequestExteriorVideoStreamCredentialsMutation>
export type RequestExteriorVideoStreamCredentialsMutationOptions =
  Apollo.BaseMutationOptions<
    RequestExteriorVideoStreamCredentialsMutation,
    RequestExteriorVideoStreamCredentialsMutationVariables
  >
export const StopExteriorVideoStreamDocument = gql`
    mutation StopExteriorVideoStream($input: StopExteriorVideoStreamInput!) {
  fccStopExteriorVideoStream(input: $input)
}
    `
export type StopExteriorVideoStreamMutationFn = Apollo.MutationFunction<
  StopExteriorVideoStreamMutation,
  StopExteriorVideoStreamMutationVariables
>

/**
 * __useStopExteriorVideoStreamMutation__
 *
 * To run a mutation, you first call `useStopExteriorVideoStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopExteriorVideoStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopExteriorVideoStreamMutation, { data, loading, error }] = useStopExteriorVideoStreamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStopExteriorVideoStreamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StopExteriorVideoStreamMutation,
    StopExteriorVideoStreamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    StopExteriorVideoStreamMutation,
    StopExteriorVideoStreamMutationVariables
  >(StopExteriorVideoStreamDocument, options)
}
export type StopExteriorVideoStreamMutationHookResult = ReturnType<
  typeof useStopExteriorVideoStreamMutation
>
export type StopExteriorVideoStreamMutationResult =
  Apollo.MutationResult<StopExteriorVideoStreamMutation>
export type StopExteriorVideoStreamMutationOptions = Apollo.BaseMutationOptions<
  StopExteriorVideoStreamMutation,
  StopExteriorVideoStreamMutationVariables
>
