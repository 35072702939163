import {
  useErrorHandlingMutation,
  useErrorHandlingQuery,
} from '@backoffice-frontend/data-fetching'
import { Environment, getEnvironment } from '@backoffice-frontend/environment'
import { useToggle } from '@backoffice-frontend/hooks'
import {
  Avatar,
  Box,
  NotificationBadge,
  UnstyledButton,
} from '@backoffice-frontend/patterns'
import { useUserHasAccess } from '@backoffice-frontend/restricted'
import { useBackofficeStatusesQuery } from '@backoffice-frontend/status-api'
import { useBackofficeUpdatesByRightsQuery } from '@backoffice-frontend/updates-api'
import { useBackofficeLoggedInUser } from '@backoffice-frontend/user-management-ui'
import { BoumRight } from '@moia-dev/moia-token-claims'
import { ActionMenu, useActionMenu } from '@moia-dev/pace-web'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { ListOfAvailabilityGroups } from '../ListOfAvailabilityGroups'
import {
  useBackofficeStatusGetLastVisitForBadgeQuery,
  useBackofficeStatusSetLastVisitMutation,
  useBackofficeUpdateGetLastVisitForBadgeQuery,
  useBackofficeUpdateSetLastVisitMutation,
} from './AccountBadge.hook'
import { AccountBadgeLanguageSwitch } from './AccountBadgeLanguageSwitch'
import { AccountBadgeMainMenu } from './AccountBadgeMainMenu'
import { AccountBadgeThemeSwitch } from './AccountBadgeThemeSwitch'
import type { MenuState } from './MenuState'

const MENU_WIDTH = 210

const getUserInitials = (userName: string): string => {
  const userNameSplit = userName ? userName.split('.') : ''
  const lastNameInitial =
    userNameSplit[1]?.charAt(0) || userNameSplit[0]?.charAt(1)
  return `${userNameSplit[0]?.charAt(0)}${lastNameInitial}`.toUpperCase()
}

export const AccountBadge = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentMenu, setCurrentMenu] = useState<MenuState>('main')

  const { user: loggedInUser } = useBackofficeLoggedInUser()

  const userNameInitials = loggedInUser
    ? getUserInitials(loggedInUser.username)
    : ''

  const { data: updatesData } = useBackofficeUpdatesByRightsQuery()

  const hasAccessToStatuses = useUserHasAccess({
    requiredRights: [BoumRight.BACKOFFICE_STATUS_CRUD],
  })
  const hasAccessToUpdates = useUserHasAccess({
    requiredRights: [BoumRight.BACKOFFICE_UPDATES_R],
  })

  const { data: statusesData } = useErrorHandlingQuery(
    useBackofficeStatusesQuery,
    {
      skip: !hasAccessToStatuses,
    },
  )
  const { data: statusLastVisit, refetch: refetchStatusLastVisit } =
    useErrorHandlingQuery(useBackofficeStatusGetLastVisitForBadgeQuery, {
      skip: !hasAccessToStatuses,
    })
  const { data: updateLastVisit, refetch: refetchUpdateLastVisit } =
    useErrorHandlingQuery(useBackofficeUpdateGetLastVisitForBadgeQuery, {
      skip: !hasAccessToUpdates,
    })

  const [setUpdatesLastVisited] = useErrorHandlingMutation(
    useBackofficeUpdateSetLastVisitMutation,
  )
  const [setStatusLastVisited] = useErrorHandlingMutation(
    useBackofficeStatusSetLastVisitMutation,
  )

  const hasNotificationsUpdates =
    !!updatesData?.backofficeUpdatesByRights.updates.some(
      update =>
        update?.creationDate &&
        updateLastVisit?.lastVisit.lastVisitDate &&
        update.creationDate > updateLastVisit?.lastVisit.lastVisitDate,
    ) || false

  const hasNotificationsStatuses =
    !!statusesData?.backofficeStatuses.statuses.some(
      status =>
        status?.creationDate &&
        statusLastVisit?.lastVisit.lastVisitDate &&
        status.creationDate > statusLastVisit?.lastVisit.lastVisitDate,
    ) || false

  const hasNotifications = hasNotificationsUpdates || hasNotificationsStatuses

  const { getReferenceProps, ...props } = useActionMenu({
    isOpen,
    setIsOpen,
  })

  useEffect(() => {
    if (!isOpen) {
      setCurrentMenu('main')
    }
  }, [isOpen])

  const [modalOpen, setModalOpen] = useToggle(false)

  return (
    <>
      <Box {...getReferenceProps()} ref={props.refs.setReference}>
        <UnstyledButton>
          <NotificationBadge active={hasNotifications}>
            <Avatar small active={isOpen}>
              {userNameInitials}
            </Avatar>
          </NotificationBadge>
        </UnstyledButton>
        <ActionMenu {...props}>
          <motion.div
            animate={{
              transition: { duration: 0.2, ease: 'easeInOut' },
            }}
            style={{ overflow: 'hidden', width: MENU_WIDTH }}
          >
            <AnimatePresence mode="wait" initial={false}>
              {currentMenu === 'main' && (
                <AccountBadgeMainMenu
                  setCurrentMenu={setCurrentMenu}
                  setIsOpen={setIsOpen}
                  hasNotificationsUpdates={hasNotificationsUpdates}
                  hasNotificationsStatuses={hasNotificationsStatuses}
                  setUpdatesLastVisited={setUpdatesLastVisited}
                  refetchUpdateLastVisit={refetchUpdateLastVisit}
                  setStatusLastVisited={setStatusLastVisited}
                  refetchStatusLastVisit={refetchStatusLastVisit}
                  setModalOpen={setModalOpen}
                />
              )}
              {currentMenu === 'language' && (
                <AccountBadgeLanguageSwitch setCurrentMenu={setCurrentMenu} />
              )}
              {currentMenu === 'theme' && (
                <AccountBadgeThemeSwitch setCurrentMenu={setCurrentMenu} />
              )}
            </AnimatePresence>
          </motion.div>
        </ActionMenu>
      </Box>
      {getEnvironment() !== Environment.production && (
        <ListOfAvailabilityGroups
          isOpen={modalOpen}
          toggleOpen={setModalOpen}
        />
      )}
    </>
  )
}
