import {
  CheckmarkIcon,
  ErrorCircleSolidIcon,
  InfoISolidIcon,
  WarningSolidIcon,
  XIcon,
} from '@moia-dev/pace-icons'
import { Body2, Subtitle2 } from '../basics/Typography'
import { useTheme } from '../basics/theme'
import { UnstyledButton } from '../inputs/MoiaButton'

type Variant = 'default' | 'warning' | 'alert' | 'success' | 'criticalAlert'

type ContentInfoProps = {
  className?: string
  infoText?: string | React.ReactNode | null
  title: string
  variant?: Variant
  onDismiss?: () => void
}

/**
 * @deprecated Use the Callout component from pace-web instead.
 */
export const ContentInfo = ({
  className,
  infoText,
  title,
  variant = 'default',
  onDismiss,
}: ContentInfoProps) => {
  const theme = useTheme()

  const getBorderColor = (variant: Variant): string => {
    switch (variant) {
      case 'default':
        return theme.semantic.ColorAccessoryDefault
      case 'warning':
        return theme.semantic.ColorWarningDefault
      case 'alert':
        return theme.semantic.ColorCriticalDefault
      case 'success':
        return theme.semantic.ColorSuccessDefault
      case 'criticalAlert':
        return theme.semantic.ColorCriticalDefault
      default:
        return theme.semantic.ColorAccessoryDefault
    }
  }

  return (
    <div
      className={className}
      css={{
        border: '1px solid',
        borderColor: getBorderColor(variant),
        borderRadius: '8px',
        boxSizing: 'border-box',
        color: theme.semantic.ColorContentDefault,
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
        position: 'relative',
        ...(variant === 'criticalAlert' && {
          backgroundColor: theme.semantic.ColorCriticalDefault,
          color:
            theme.semantic
              .ColorContentOnContrastCriticalSuccessDefaultAlternative,
        }),
      }}
    >
      <div
        css={theme => ({
          alignItems: 'center',
          display: 'grid',
          gridGap: theme.spacing(1),
          gridTemplateColumns: 'min-content 1fr min-content',
          '& svg': {
            maxHeight: '20px',
            maxWidth: '20px',
          },
        })}
      >
        {(variant === 'alert' || variant === 'criticalAlert') && (
          <ErrorCircleSolidIcon
            color={
              variant === 'criticalAlert'
                ? theme.semantic
                    .ColorContentOnContrastCriticalSuccessDefaultAlternative
                : theme.semantic.ColorCriticalDefault
            }
          />
        )}
        {variant === 'default' && (
          <InfoISolidIcon color={theme.semantic.ColorContentDefault} />
        )}
        {variant === 'warning' && (
          <>
            <WarningSolidIcon color={theme.semantic.ColorWarningDefault} />
          </>
        )}
        {variant === 'success' && (
          <CheckmarkIcon color={theme.semantic.ColorSuccessDefault} />
        )}
        <Subtitle2 css={{ color: 'inherit' }}>{title}</Subtitle2>
        {onDismiss && (
          <UnstyledButton
            css={{
              height: '18px',
              width: '18px',
              ...(variant === 'criticalAlert'
                ? {
                    svg: {
                      color:
                        theme.semantic
                          .ColorContentOnContrastCriticalSuccessDefaultAlternative,
                    },
                  }
                : {
                    svg: {
                      color: theme.semantic.ColorActionSecondaryDefault,
                    },
                  }),
            }}
            onClick={onDismiss}
          >
            <XIcon />
          </UnstyledButton>
        )}
      </div>
      {infoText && <Body2 css={{ color: 'inherit' }}>{infoText}</Body2>}
    </div>
  )
}
