/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { HubViewVehicleFragmentFragmentDoc } from '../fragment/hubViewVehicle.hook'
const defaultOptions = {} as const
export type HubViewDashboardArrivalsQueryVariables = Types.Exact<{
  hubId: Types.Scalars['ID']['input']
  interval: Types.DateTimeIntervalInput
}>

export type HubViewDashboardArrivalsQuery = {
  __typename?: 'Query'
  vehicleHubArrivals: {
    __typename?: 'VehicleHubArrivalsResponse'
    total: number
    vehicles: Array<{
      __typename?: 'Vehicle'
      id: string
      label: number | null
      state: Types.VehicleState
      engineType: Types.VehicleEngineType | null
      fuelLevel: number | null
      highVoltageBatteryLevel: number | null
      chargingMode: Types.ChargingMode | null
      nextHubReturn: {
        __typename?: 'HubReturn'
        scheduledArrival: string
        type: Types.HubReturnType
      } | null
    }>
  }
}

export const HubViewDashboardArrivalsDocument = gql`
    query HubViewDashboardArrivals($hubId: ID!, $interval: DateTimeIntervalInput!) {
  vehicleHubArrivals(hubID: $hubId, interval: $interval) {
    total
    vehicles {
      ...HubViewVehicleFragment
      nextHubReturn {
        scheduledArrival
        type
      }
    }
  }
}
    ${HubViewVehicleFragmentFragmentDoc}`

/**
 * __useHubViewDashboardArrivalsQuery__
 *
 * To run a query within a React component, call `useHubViewDashboardArrivalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHubViewDashboardArrivalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHubViewDashboardArrivalsQuery({
 *   variables: {
 *      hubId: // value for 'hubId'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useHubViewDashboardArrivalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    HubViewDashboardArrivalsQuery,
    HubViewDashboardArrivalsQueryVariables
  > &
    (
      | { variables: HubViewDashboardArrivalsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    HubViewDashboardArrivalsQuery,
    HubViewDashboardArrivalsQueryVariables
  >(HubViewDashboardArrivalsDocument, options)
}
export function useHubViewDashboardArrivalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HubViewDashboardArrivalsQuery,
    HubViewDashboardArrivalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    HubViewDashboardArrivalsQuery,
    HubViewDashboardArrivalsQueryVariables
  >(HubViewDashboardArrivalsDocument, options)
}
export function useHubViewDashboardArrivalsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HubViewDashboardArrivalsQuery,
        HubViewDashboardArrivalsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    HubViewDashboardArrivalsQuery,
    HubViewDashboardArrivalsQueryVariables
  >(HubViewDashboardArrivalsDocument, options)
}
export type HubViewDashboardArrivalsQueryHookResult = ReturnType<
  typeof useHubViewDashboardArrivalsQuery
>
export type HubViewDashboardArrivalsLazyQueryHookResult = ReturnType<
  typeof useHubViewDashboardArrivalsLazyQuery
>
export type HubViewDashboardArrivalsSuspenseQueryHookResult = ReturnType<
  typeof useHubViewDashboardArrivalsSuspenseQuery
>
export type HubViewDashboardArrivalsQueryResult = Apollo.QueryResult<
  HubViewDashboardArrivalsQuery,
  HubViewDashboardArrivalsQueryVariables
>
