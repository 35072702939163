import { INFO_NOT_AVAILABLE_NA } from '../const'
import { CurrencySymbols } from '../const/enums/CurrencySymbols'
import { config } from './config'

/**
 * Formats a number. Formats a number with customizable decimal places (only 0, 1, or 2 are allowed).
 * Uses the languages set by the client.
 * @param number - The number to format.
 * @returns The formatted number as a string.
 * @example formatNumber(1) // Returns "1.00"
 * @example formatNumber(1.23) // Returns "1.23" or "1,23" depending on the language
 */
export const formatNumber = (number: number, decimalPlaces = 2) => {
  const validDecimalPlaces = [0, 1, 2].includes(decimalPlaces)
    ? decimalPlaces
    : 2

  const language = config.getLanguage()
  return new Intl.NumberFormat(language, {
    maximumFractionDigits: validDecimalPlaces,
    minimumFractionDigits: validDecimalPlaces,
  }).format(number)
}

/**
 * Formats a number. Will return the full number without decimal places if there are none. Otherwise, it will return 2 decimal places. Uses the languages set by the client.
 * @param {number} number - The number to format.
 * @returns {string} The formatted number as a string.
 * @example formatNumberFullOrDecimal(1) // Returns "1"
 * @example formatNumberFullOrDecimal(1.23) // Returns "1.23" or "1,23" depending on the language
 */
export const formatNumberFullOrDecimal = (number: number) => {
  const language = config.getLanguage()
  return new Intl.NumberFormat(language, {
    maximumFractionDigits: 2,
  }).format(number)
}

/**
 * Formats a currency value.
 *
 * @param number - The currency value to format.
 * @param symbol - The currency symbol to use. Defaults to EUR.
 *
 * @return The formatted currency value as a string. Depending on the language, the symbol will be placed before or after the number.
 */
export const formatCurrency = (
  number: number,
  symbol: string = CurrencySymbols.EUR,
  decimalPlaces = 2,
) => {
  const language = config.getLanguage()

  return language === 'de'
    ? `${formatNumber(number, decimalPlaces)} ${symbol}`
    : `${symbol}${formatNumber(number, decimalPlaces)}`
}

export const formatPercent = (number: number, decimalPlaces = 2) =>
  `${formatNumber(number * 100, decimalPlaces)}%`

export const formatInteger = (number: number | string) => {
  const language = config.getLanguage()
  return new Intl.NumberFormat(language, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(Number(number))
}

// formatHundredsToCommaSeperatedString Input -> Output: 150 -> 1,50
export const formatHundredsToCommaSeperatedString = (
  number: number | undefined,
  locale = 'de',
): string => {
  const fullNumber = (number ?? 0) / 100
  return Number(Number.parseFloat(`${fullNumber}`).toFixed(2)).toLocaleString(
    locale,
    {
      minimumFractionDigits: 2,
    },
  )
}

// formatCommaSeperatedStringToHundreths Input -> Output: 1,50 -> 150
export const formatCommaSeperatedStringToHundreds = (
  value: string | number,
) => {
  if (typeof value === 'number') {
    return value * 100
  }

  if (
    typeof value === 'string' &&
    (value.includes(',') || value.includes('.'))
  ) {
    const convertedNumber = value.replace(/,|\./g, '')
    return Number.parseInt(convertedNumber, 10)
  }

  return Number.parseInt(value, 10) * 100
}

export const formatKilometers = (number: number) =>
  `${formatInteger(number)} km`

/**
 * @description Formats a phone number
 * @param {string} value +491234567890
 * @returns {string} +49 123 456 789 0
 */
export const formatPhoneNumber = (value: string): string => {
  const phoneNumber = `${value}`

  const phoneNumberChunks = phoneNumber.match(/.{1,3}/g)
  const formattedPhoneNumber = phoneNumberChunks?.join(' ') ?? ''

  return phoneNumber && formattedPhoneNumber
}

/**
 * @description Formats a file size in bytes to a human readable string.
 * If the size is below 1KB, it is displayed in bytes. If it is below 1MB, it is displayed in KB. If it is below 1GB, it is displayed in MB. If it is below 1TB, it is displayed in GB. .00 is removed from the string.
 * @param {number} size - The file size in bytes.
 * @param {boolean} strict - If true, the size is calculated using 1024 as the multiplier. If false, the size is calculated using 1000 as the multiplier.
 * @returns {string} The file size as a readable string.
 *
 * @example
 * // Returns "4.23 KB"
 * formatFileSize(4321)
 *
 * // Returns "256 bytes"
 * formatFileSize(256)
 */
export const formatFileSize = (size?: number, strict?: boolean): string => {
  if (!size) return INFO_NOT_AVAILABLE_NA
  const units = ['bytes', 'kB', 'MB', 'GB', 'TB']
  const multiplier = strict ? 1024 : 1000

  let i = 0
  while (size >= multiplier && i < units.length - 1) {
    size /= multiplier
    i++
  }

  const language = config.getLanguage()

  const formattedSize = size
    .toFixed(2)
    .replace('.', language === 'de' ? ',' : '.')

  return formattedSize.endsWith('.00') || formattedSize.endsWith(',00')
    ? formattedSize.slice(0, -3) + ' ' + units[i]
    : formattedSize + ' ' + units[i]
}

/**
 * Formats an array of file types into a comma-separated string.
 *
 * @param fileTypes - The array of file types to format.
 * @returns The formatted string.
 */
export const formatFileTypes = (fileTypes: string[]) => {
  return fileTypes.map(it => it.split('/')[1]).join(', ')
}
