import {
  AccidentsReportsAreaRoutes,
  DisruptionsAreaRoutes,
  VehicleAreaRoutes,
} from '@backoffice-frontend/common'
import { RioCategory } from '@backoffice-frontend/graphql'
import { BoumRight } from '@moia-dev/moia-token-claims'

export type CategoryConfig = {
  rights: { write: BoumRight[]; read: BoumRight[] }
  routes: { list: string; view: string; notFound: string }
}

const accidentConfig = {
  rights: {
    write: [BoumRight.REPORT_ACCIDENT_CRUD],
    read: [BoumRight.REPORT_ACCIDENT_CRUD, BoumRight.REPORT_ACCIDENT_R],
  },
  routes: {
    list: AccidentsReportsAreaRoutes.route,
    view: AccidentsReportsAreaRoutes.ViewReport.route,
    notFound: AccidentsReportsAreaRoutes.NotFound.route,
  },
}

const vehicleChecksConfig = {
  rights: {
    write: [BoumRight.REPORT_VEHICLE_CHECK_CRUD],
    read: [
      BoumRight.REPORT_VEHICLE_CHECK_CRUD,
      BoumRight.REPORT_VEHICLE_CHECK_R,
    ],
  },
  routes: {
    list: VehicleAreaRoutes.RideChecks.route,
    view: VehicleAreaRoutes.RideChecksView.route,
    notFound: VehicleAreaRoutes.RideChecksNotFound.route,
  },
}

const disruptionConfig = {
  rights: {
    write: [BoumRight.REPORT_DISRUPTION_CRUD],
    read: [BoumRight.REPORT_DISRUPTION_CRUD, BoumRight.REPORT_DISRUPTION_R],
  },
  routes: {
    list: DisruptionsAreaRoutes.route,
    view: DisruptionsAreaRoutes.ViewReport.route,
    notFound: DisruptionsAreaRoutes.NotFound.route,
  },
}

export const getCategoryConfig: (category: RioCategory) => CategoryConfig = (
  category: RioCategory,
) => {
  switch (category) {
    case RioCategory.Accident:
      return accidentConfig
    case RioCategory.VehicleChecks:
      return vehicleChecksConfig
    case RioCategory.Disruption:
      return disruptionConfig

    default:
      throw new Error(`Unknown category ${category}`)
  }
}
