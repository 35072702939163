import {
  type AreaNavigationProps,
  NavigationItem,
} from '@backoffice-frontend/patterns'
import { FilesIcon } from '@moia-dev/pace-icons'
import { LegalDocumentManagementAreaRoutes } from './LegalDocumentManagementAreaRoutes'

export const LegalDocumentManagementAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={LegalDocumentManagementAreaRoutes.route}
      Icon={FilesIcon}
    />
  )
}
