/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type AdVehicleStreamAlertVehicleMapVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  latestLocation: {
    __typename?: 'VehicleLocation'
    heading: number | null
    location: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    } | null
  } | null
  fccUpcomingWaypoints: Array<{
    __typename?: 'FccWaypoint'
    id: string
    stop: {
      __typename?: 'Stop'
      routingLocation: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
    } | null
    actions: Array<
      | { __typename?: 'FccWaypointDeliveryAction'; estimatedAt: string }
      | { __typename?: 'FccWaypointHubReturnAction'; estimatedAt: string }
      | { __typename?: 'FccWaypointPickupAction'; estimatedAt: string }
      | { __typename?: 'FccWaypointReallocationAction'; estimatedAt: string }
    >
  }> | null
}

export type AdVehicleStreamAlertVehicleMapWaypointFragment = {
  __typename?: 'FccWaypoint'
  id: string
  stop: {
    __typename?: 'Stop'
    routingLocation: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    }
  } | null
  actions: Array<
    | { __typename?: 'FccWaypointDeliveryAction'; estimatedAt: string }
    | { __typename?: 'FccWaypointHubReturnAction'; estimatedAt: string }
    | { __typename?: 'FccWaypointPickupAction'; estimatedAt: string }
    | { __typename?: 'FccWaypointReallocationAction'; estimatedAt: string }
  >
}

export type AdVehicleStreamAlertVehicleMapLocationFragment = {
  __typename?: 'Location'
  latitude: number
  longitude: number
}

export const AdVehicleStreamAlertVehicleMapLocationFragmentDoc = gql`
    fragment AdVehicleStreamAlertVehicleMapLocation on Location {
  latitude
  longitude
}
    `
export const AdVehicleStreamAlertVehicleMapWaypointFragmentDoc = gql`
    fragment AdVehicleStreamAlertVehicleMapWaypoint on FccWaypoint {
  id
  stop {
    routingLocation {
      ...AdVehicleStreamAlertVehicleMapLocation
    }
  }
  actions {
    ... on FccWaypointDeliveryAction {
      estimatedAt
    }
    ... on FccWaypointPickupAction {
      estimatedAt
    }
    ... on FccWaypointHubReturnAction {
      estimatedAt
    }
    ... on FccWaypointReallocationAction {
      estimatedAt
    }
  }
}
    ${AdVehicleStreamAlertVehicleMapLocationFragmentDoc}`
export const AdVehicleStreamAlertVehicleMapVehicleFragmentDoc = gql`
    fragment AdVehicleStreamAlertVehicleMapVehicle on Vehicle {
  id
  latestLocation {
    location {
      ...AdVehicleStreamAlertVehicleMapLocation
    }
    heading
  }
  fccUpcomingWaypoints {
    ...AdVehicleStreamAlertVehicleMapWaypoint
  }
}
    ${AdVehicleStreamAlertVehicleMapLocationFragmentDoc}
${AdVehicleStreamAlertVehicleMapWaypointFragmentDoc}`
