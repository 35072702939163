import { type TypePolicies, gql, makeVar, useQuery } from '@apollo/client'
import { config } from '@backoffice-frontend/common'

type SelectedRegionQuery = {
  regionId?: string
  regionName?: string
}

export const GET_SELECTED_REGION = gql`
  query getSelectedRegion {
    getSelectedRegion @client {
      regionId
    }
  }
`

export const useGlobalRegionId = (): [string | undefined, boolean] => {
  const { data } = useQuery(GET_SELECTED_REGION)
  const loading = false

  return [data?.getSelectedRegion.regionId, loading]
}

type SelectedRegion = string
export const selectedRegionVar = makeVar<SelectedRegion>(config.getRegionId())

export const selectRegion = (regionId?: SelectedRegion) => {
  if (regionId === selectedRegionVar()) {
    return
  }

  config.setRegionId(regionId)
  selectedRegionVar(regionId)
}

export const MoiaRegionTypePolicies: TypePolicies = {
  Query: {
    fields: {
      getSelectedRegion: {
        read(): SelectedRegionQuery {
          return {
            regionId: selectedRegionVar(),
          }
        },
      },
    },
  },
}
