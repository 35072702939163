import { Toast } from '@moia-dev/pace-web'
import type { SnackbarKey } from 'notistack'
import { useSnackbar } from 'notistack'
import type { ReactNode } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * A hook allows to display a success notification at the bottom center of
 * the screen.
 */
export const useMoiaSuccessNotification = (
  persist = false,
  duration = 2000,
): {
  enqueueMoiaSuccessNotification: (success: ReactNode) => SnackbarKey
  closeMoiaSuccessNotification: (key: SnackbarKey) => void
  closeAllNotifications: () => void
} => {
  const { t } = useTranslation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const enqueueMoiaSuccessNotification = useCallback(
    (message: ReactNode) =>
      enqueueSnackbar(message, {
        persist,
        autoHideDuration: duration,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        content: function SnackbarContent(key) {
          return (
            <div role="alert">
              <Toast
                variant="success"
                action={() => closeSnackbar(key)}
                actionLabel={t('common_ok_label')}
              >
                {message}
              </Toast>
            </div>
          )
        },
        // preventing duplicate only works if we got a string as the message (instead of a React element)
        preventDuplicate: true,
      }),
    [enqueueSnackbar, closeSnackbar, t, persist, duration],
  )

  const closeMoiaSuccessNotification = useCallback(
    (key: SnackbarKey) => closeSnackbar(key),
    [closeSnackbar],
  )

  const closeAllNotifications = useCallback(() => {
    closeSnackbar()
  }, [closeSnackbar])

  return {
    enqueueMoiaSuccessNotification,
    closeMoiaSuccessNotification,
    closeAllNotifications,
  }
}
