import { useErrorHandlingLazyQuery } from '@backoffice-frontend/data-fetching'
import { useEffect } from 'react'
import type { ServiceAreaByIdWithLocationAttributesQuery } from '../ServiceAreaByIdWithoutLocationAttributes/serviceAreaByIdWithLocationAttributes.hook'
import { useServiceAreaByIdWithLocationAttributesLazyQuery } from '../ServiceAreaByIdWithoutLocationAttributes/serviceAreaByIdWithLocationAttributes.hook'

// This lives here because it used in various areas, primarily the ones that
// render a map. One can try moving this to the `sam-common` lib but beware or
// circular dependency shenanigans.
export const useServiceAreaByIdWithLocationAttributes = ({
  serviceAreaId,
  serviceAreaUuid,
  fetchPolicy,
}: {
  serviceAreaId?: string
  serviceAreaUuid?: string
  fetchPolicy?: 'cache-first' | 'cache-only' | 'no-cache' | 'network-only'
}):
  | ServiceAreaByIdWithLocationAttributesQuery['serviceAreaById']
  | undefined => {
  const [fetchServiceAreaByIdWithLocationAttributes, { data }] =
    useErrorHandlingLazyQuery(
      useServiceAreaByIdWithLocationAttributesLazyQuery,
      { fetchPolicy: fetchPolicy ?? 'cache-first' },
    )

  useEffect(() => {
    if (serviceAreaId) {
      void fetchServiceAreaByIdWithLocationAttributes({
        variables: {
          serviceAreaId,
        },
      })
    } else if (serviceAreaUuid) {
      void fetchServiceAreaByIdWithLocationAttributes({
        variables: {
          serviceAreaId: serviceAreaUuid,
        },
      })
    }
  }, [
    fetchServiceAreaByIdWithLocationAttributes,
    serviceAreaId,
    serviceAreaUuid,
  ])

  if (!serviceAreaId && !serviceAreaUuid) {
    return undefined
  }

  return data?.serviceAreaById
}
