import {
  Environment,
  getEnvironment,
  setEnvironment,
} from '@backoffice-frontend/environment'
import { Option, Select } from '@moia-dev/pace-web'

export const getEnvLabel = (env: string) => {
  switch (env) {
    case Environment.development:
      return 'DEV'
    case Environment.integration:
      return 'INT'
    case Environment.production:
      return 'PRD'
    case Environment.local:
      return 'LOC'
    case Environment.test:
      return 'TEST'
    default:
      return ''
  }
}

export const SwitchEnvironmentDropdown = ({
  className,
}: {
  className?: string
}) => {
  return (
    <Select
      className={className}
      options={Object.keys(Environment).map(env => {
        return (
          <Option key={env} value={env}>
            {getEnvLabel(env)}
          </Option>
        )
      })}
      size={'compact'}
      renderValue={option => getEnvLabel(option)}
      selectedOption={getEnvironment()}
      setSelectedOption={setEnvironment}
    />
  )
}
