/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type GlobalServiceAreaIdsQueryVariables = Types.Exact<{
  serviceAreaId: Types.Scalars['ID']['input']
}>

export type GlobalServiceAreaIdsQuery = {
  __typename?: 'Query'
  serviceAreaById: {
    __typename?: 'ServiceArea'
    uuid: string
    id: string
  } | null
}

export const GlobalServiceAreaIdsDocument = gql`
    query globalServiceAreaIds($serviceAreaId: ID!) {
  serviceAreaById(id: $serviceAreaId) {
    uuid
    id
  }
}
    `

/**
 * __useGlobalServiceAreaIdsQuery__
 *
 * To run a query within a React component, call `useGlobalServiceAreaIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalServiceAreaIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalServiceAreaIdsQuery({
 *   variables: {
 *      serviceAreaId: // value for 'serviceAreaId'
 *   },
 * });
 */
export function useGlobalServiceAreaIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GlobalServiceAreaIdsQuery,
    GlobalServiceAreaIdsQueryVariables
  > &
    (
      | { variables: GlobalServiceAreaIdsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GlobalServiceAreaIdsQuery,
    GlobalServiceAreaIdsQueryVariables
  >(GlobalServiceAreaIdsDocument, options)
}
export function useGlobalServiceAreaIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GlobalServiceAreaIdsQuery,
    GlobalServiceAreaIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GlobalServiceAreaIdsQuery,
    GlobalServiceAreaIdsQueryVariables
  >(GlobalServiceAreaIdsDocument, options)
}
export function useGlobalServiceAreaIdsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GlobalServiceAreaIdsQuery,
        GlobalServiceAreaIdsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GlobalServiceAreaIdsQuery,
    GlobalServiceAreaIdsQueryVariables
  >(GlobalServiceAreaIdsDocument, options)
}
export type GlobalServiceAreaIdsQueryHookResult = ReturnType<
  typeof useGlobalServiceAreaIdsQuery
>
export type GlobalServiceAreaIdsLazyQueryHookResult = ReturnType<
  typeof useGlobalServiceAreaIdsLazyQuery
>
export type GlobalServiceAreaIdsSuspenseQueryHookResult = ReturnType<
  typeof useGlobalServiceAreaIdsSuspenseQuery
>
export type GlobalServiceAreaIdsQueryResult = Apollo.QueryResult<
  GlobalServiceAreaIdsQuery,
  GlobalServiceAreaIdsQueryVariables
>
