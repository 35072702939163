// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import {
  DisruptionsAreaId,
  DisruptionsAreaRoutes,
} from '@backoffice-frontend/common'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { DisruptionsAreaNavigation } from './DisruptionsAreaNavigation'

const LazyDisruptionsComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./DisruptionsArea')),
)

const DisruptionsComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyDisruptionsComponent />
  </SuspenseLoadingWrapper>
)

export const DisruptionsArea = {
  id: DisruptionsAreaId,
  requiredRight: BoumRight.REPORT_DISRUPTION_AREA,
  NavigationComponent: DisruptionsAreaNavigation,
  route: {
    path: DisruptionsAreaRoutes.route,
    component: DisruptionsComponent,
  },
}
