import {
  AppearanceOptions,
  useAppearance,
} from '@backoffice-frontend/dark-mode'
import { Divider } from '@backoffice-frontend/patterns'
import { CheckmarkIcon, ChevronLeftIcon } from '@moia-dev/pace-icons'
import { ActionItem } from '@moia-dev/pace-web'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import type { MenuState } from './MenuState'
import { menuAnimations } from './menuAnimations'

interface AccountBadgeThemeSwitchProps {
  setCurrentMenu: (menu: MenuState) => void
}

export const AccountBadgeThemeSwitch = ({
  setCurrentMenu,
}: AccountBadgeThemeSwitchProps) => {
  const { t } = useTranslation()
  const { setDarkMode, appearanceOption, setAppearanceOption } = useAppearance()

  const handleToggleDarkMode = (appearanceOption: AppearanceOptions) => {
    let isDarkMode = false

    if (appearanceOption === AppearanceOptions.light) {
      setAppearanceOption(AppearanceOptions.light)
    }
    if (appearanceOption === AppearanceOptions.dark) {
      setAppearanceOption(AppearanceOptions.dark)
      isDarkMode = true
    }
    if (appearanceOption === AppearanceOptions.system) {
      setAppearanceOption(AppearanceOptions.system)
      isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
    }

    setDarkMode(isDarkMode)

    const themeChangeEvent = new CustomEvent('theme-change', {
      detail: {
        appearanceOption,
        darkMode: isDarkMode,
      },
    })

    window.dispatchEvent(themeChangeEvent)
  }

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={menuAnimations}
    >
      <ActionItem
        label={t('authenticatedAppView_themeSwitch_label')}
        leading={<ChevronLeftIcon />}
        onClick={e => {
          e.stopPropagation()
          setCurrentMenu('main')
        }}
      />
      <Divider spacing={1} />

      <ActionItem
        leading={
          appearanceOption === AppearanceOptions.light && <CheckmarkIcon />
        }
        label={t('authenticatedAppView_appearanceOptionLight')}
        onClick={e => {
          e.stopPropagation()
          handleToggleDarkMode(AppearanceOptions.light)
        }}
      />
      <ActionItem
        leading={
          appearanceOption === AppearanceOptions.dark && <CheckmarkIcon />
        }
        label={t('authenticatedAppView_appearanceOptionDark')}
        onClick={e => {
          e.stopPropagation()
          handleToggleDarkMode(AppearanceOptions.dark)
        }}
      />
      <ActionItem
        leading={
          appearanceOption === AppearanceOptions.system && <CheckmarkIcon />
        }
        label={t('authenticatedAppView_appearanceOptionSystem')}
        onClick={e => {
          e.stopPropagation()
          handleToggleDarkMode(AppearanceOptions.system)
        }}
      />
    </motion.div>
  )
}
