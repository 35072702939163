import type { TypographyProps } from '@mui/material/Typography'
import Typography from '@mui/material/Typography'

/**
 * @deprecated Use Heading component from pace-web instead.
 */
export const H2 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="h2" {...others}>
    {children}
  </Typography>
)

/**
 * @deprecated Use Heading component from pace-web instead.
 */
export const H3 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="h3" {...others}>
    {children}
  </Typography>
)

/**
 * @deprecated Use Heading component from pace-web instead.
 */
export const H4 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="h4" {...others}>
    {children}
  </Typography>
)

/**
 * @deprecated Use Heading component from pace-web instead.
 */
export const H5 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="h5" {...others}>
    {children}
  </Typography>
)

/**
 * @deprecated Use Heading component from pace-web instead.
 */
export const H6 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="h6" {...others}>
    {children}
  </Typography>
)

/**
 * @deprecated Use Heading component from pace-web instead.
 */
export const Subtitle1 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="subtitle1" {...others}>
    {children}
  </Typography>
)

/**
 * @deprecated Use Heading component from pace-web instead.
 */
export const Subtitle2 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="subtitle2" {...others}>
    {children}
  </Typography>
)

/**
 * @deprecated Use Text component from pace-web instead.
 */
export const Body1 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="body1" {...others}>
    {children}
  </Typography>
)

/**
 * @deprecated Use Text component from pace-web instead.
 */
export const Body2 = ({ children, ...others }: TypographyProps) => (
  <Typography variant="body2" {...others}>
    {children}
  </Typography>
)

/**
 * @deprecated Use Text component from pace-web instead.
 */
export const Caption = ({ children, ...others }: TypographyProps) => (
  <Typography variant="caption" {...others}>
    {children}
  </Typography>
)

/**
 * @deprecated Use Text component from pace-web instead.
 */
export const Overline = ({ children, ...others }: TypographyProps) => (
  <Typography variant="overline" {...others}>
    {children}
  </Typography>
)
