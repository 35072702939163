import * as dark from '@moia-dev/pace-core-web/moia/pace-colors-dark'
import * as light from '@moia-dev/pace-core-web/moia/pace-colors-light'
import * as radius from '@moia-dev/pace-core-web/pace-radius'
import * as space from '@moia-dev/pace-core-web/pace-spacing'
import type { Palette, PaletteColor, Theme, ThemeOptions } from '@mui/material'
import { alpha } from '@mui/material'
import { boxShadow1 } from './fadedColors'
import {
  black,
  coral,
  gold,
  guidanceBlue,
  jade,
  moiaBlack,
  white,
} from './moiaColors'

type MOIAPaletteColor = PaletteColor &
  Record<'superlight' | 'outlineText', string>

type PaletteOptionsWithMOIAPaletteColor =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'info'
  | 'success'

type MOIAPalette = Omit<Palette, PaletteOptionsWithMOIAPaletteColor> & {
  [key in PaletteOptionsWithMOIAPaletteColor]: MOIAPaletteColor
}

type Semantic = typeof light
type Space = typeof space
type Radius = typeof radius
// adds additional fields to the mui default Theme
// so TypeScript knows they exist in useTheme and other helpers that reference the global Theme interface
declare module '@mui/material/styles' {
  interface Theme {
    semantic: Semantic
    radius: Radius
    space: Space
    palette: MOIAPalette
    headerHeight: number
    paceHeaderHeight: PaceHeaderHeight
    inputFieldHeight: number
  }
  interface ThemeOptions {
    headerHeight?: number
    paceHeaderHeightDesktop?: number
    paceHeaderHeightMobile?: number
    inputFieldHeight?: number
    paceHeaderHeight: PaceHeaderHeight
    semantic: Semantic
    space: Space
    radius: Radius
  }
}

type HeaderVariants = {
  single: number
  double: number
}
type PaceHeaderHeight = {
  desktop: HeaderVariants
  mobile: HeaderVariants
}

// overwrites emotions default theme object enables typeChecking for the css prop
type MOIATheme = Omit<Theme, 'palette'> & {
  palette: MOIAPalette
} & {
  headerHeight: number
  paceHeaderHeight: PaceHeaderHeight
  inputFieldHeight: number
}
declare module '@emotion/react' {
  export interface Theme extends MOIATheme {}
}

const typography: ThemeOptions['typography'] = {
  fontFamily: 'var(--font-family-brand)',
  h1: {
    fontWeight: 600,
    fontSize: '96px',
    letterSpacing: '-1.5px',
    lineHeight: '100%',
  },
  h2: {
    fontWeight: 600,
    fontSize: '60px',
    letterSpacing: '-0.5px',
    lineHeight: '105%',
  },
  h3: {
    fontWeight: 600,
    fontSize: '48px',
    letterSpacing: '0px',
    lineHeight: '110%',
  },
  h4: {
    fontWeight: 600,
    fontSize: '34px',
    letterSpacing: '0.25px',
    lineHeight: '120%',
  },
  h5: {
    fontWeight: 600,
    fontSize: '24px',
    letterSpacing: '0px',
    lineHeight: '130%',
  },
  h6: {
    fontWeight: 600,
    fontSize: '20px',
    letterSpacing: '0.15px',
    lineHeight: '140%',
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '160%',
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '150%',
  },
  body1: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
  },
  body2: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '160%',
  },
  button: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '175%',
    textTransform: 'none',
  },
  caption: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.2px',
    lineHeight: '150%',
  },
  overline: {
    fontWeight: 400,
    fontSize: '10px',
    letterSpacing: '1.5px',
    lineHeight: '100%',
    textTransform: 'uppercase',
  },
}

/**
 * @deprecated use css variables from design-tokens-web library | https://frontend.backoffice.int.moia-group.io/pattern-library/patterns/Colors
 */
const palette = {
  common: {
    black,
    white,
  },
  primary: {
    ...guidanceBlue,
    dark: guidanceBlue.main,
    contrastText: white,
  },
  secondary: {
    dark: black,
    main: moiaBlack[900],
    light: moiaBlack[300],
    contrastText: white,
  },
  error: {
    ...coral,
    dark: coral.main,
    contrastText: white,
  },
  warning: {
    ...gold,
    dark: gold.main,
    contrastText: black,
  },
  // info - used to present information to the user that is neutral and not necessarily important.
  info: {
    dark: moiaBlack[500],
    main: moiaBlack[300],
    light: moiaBlack[200],
    contrastText: black,
    superlight: moiaBlack[50],
  },
  // success - used to indicate the successful completion of an action that user triggered.
  success: {
    ...jade,
    dark: jade.main,
    contrastText: white,
  },
  grey: moiaBlack,
  text: {
    primary: moiaBlack['900'],
    secondary: moiaBlack['600'],
    disabled: 'var(--color-content-disabled)',
  },
  divider: alpha(moiaBlack['900'], 0.15),
  background: {
    paper: white,
    default: moiaBlack['100'],
  },
  action: {
    active: moiaBlack['500'],
    hover: alpha(moiaBlack['900'], 0.05),
    selected: alpha(moiaBlack['900'], 0.08),
    disabled: alpha(moiaBlack['900'], 0.27),
    disabledBackground: alpha(moiaBlack['900'], 0.15),
  },
}

const defaultDisabledStyles = {
  '&.Mui-disabled': {
    color: moiaBlack[400],
    background: moiaBlack[50],
  },
} as const

export const moiaThemeOptions: ThemeOptions = {
  headerHeight: 80,
  paceHeaderHeight: {
    mobile: {
      single: 44,
      double: 89,
    },
    desktop: {
      single: 64,
      double: 117,
    },
  },
  inputFieldHeight: 64,
  semantic: light,
  radius,
  space,
  palette,
  typography,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
          position: 'relative',
          padding: 0,
          margin: 0,
        },
        '*': {
          boxSizing: 'border-box',
          fontFamily: 'var(--font-family-brand)',
        },
        p: {
          margin: 0,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          color: 'inherit',
          borderRadius: '8px',
          background: 'var(--color-surface-default)',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: '20px',
        },
        root: {
          minHeight: '70px',
          padding: '24px 24px 8px 24px',
        },
        action: {
          marginTop: 0,
          marginRight: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
          '&:last-child': {
            paddingBottom: '32px',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'var(--color-surface-contrast-default)',
          color: 'var(--color-content-on-contrast-critical-success-default)',
          fontWeight: 'bold',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        containedInherit: {
          background: white,
          ...defaultDisabledStyles,
        },
        containedPrimary: {
          background: guidanceBlue.main,
          ...defaultDisabledStyles,
        },
        containedSecondary: {
          background: black,
          ...defaultDisabledStyles,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '2px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: 'auto',
          cursor: 'pointer',
          '& td:first-of-type': {
            paddingLeft: 0,
          },
          '& th:first-of-type': {
            paddingLeft: 0,
          },
        },
        head: { cursor: 'initial' },
        footer: {
          cursor: 'initial',
          height: 'auto',
          p: {
            color: 'var(--color-content-default)',
          },
          div: {
            color: 'var(--color-content-default)',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          ...typography.subtitle2,
        },
        root: {
          padding: '12px 24px 12px 24px',
          '&:empty': {
            padding: 0,
          },
          backgroundColor: 'var(--color-surface-default)',
          color: 'var(--color-content-default)',
          borderBottom: '1px solid var(--color-accessory-default)',
          'div > span > svg': {
            color: 'var(--color-content-default)',
          },
          '.Mui-active': {
            color: 'var(--color-action-secondary-default) !important',
          },
          zIndex: 'initial',
        },
        stickyHeader: { backgroundColor: 'var(--color-surface-default)' },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          color: 'var(--color-content-reduced)',
          '&.Mui-focused': {
            color: 'var(--color-accent-default)',
          },
          '&.Mui-disabled': {
            color: 'var(--color-content-disabled)',
          },
          '&.Mui-error': {
            color: 'var(--color-critical-default)',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: 'var(--color-content-reduced)',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: { ...typography.subtitle2, backgroundColor: moiaBlack[200] },
      },
    },
    MuiFormControlLabel: { styleOverrides: { label: { ...typography.body2 } } },
    MuiSnackbarContent: {
      styleOverrides: { root: { backgroundColor: white } },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          ...typography.subtitle1,
          textAlign: 'center',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: { color: 'var(--color-content-default)' },
        caption: {
          display: 'block',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: 'var(--color-content-default)',
          ':hover:not(.Mui-disabled, .Mui-error):before': {
            borderColor: 'var(--color-action-secondary-default)',
          },
          '&:before': {
            borderColor: 'var(--color-action-secondary-default)',
          },
          '&:after': {
            borderColor: 'var(--color-action-secondary-default)',
          },
          '&.Mui-focused': {
            '&:before': {
              borderColor: 'var(--color-accent-default)',
            },
            '&:after': {
              borderColor: 'var(--color-accent-default)',
            },
          },
          '&.Mui-disabled': {
            color: 'var(--color-content-disabled)',
            '&:before': {
              borderColor: 'var(--color-content-disabled)',
              borderBottomStyle: 'solid',
            },
            '&:after': {
              borderColor: 'var(--color-content-disabled)',
            },
          },
          '&.Mui-error': {
            color: 'var(--color-critical-default)',
            '&:before': {
              borderColor: 'var(--color-critical-default)',
            },
            '&:after': {
              borderColor: 'var(--color-critical-default)',
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          color: 'var(--color-content-default)',
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            background: 'var(--color-surface-pressed)',
          },
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            background: 'var(--color-surface-pressed)',
          },
          '& .MuiAutocomplete-option.Mui-focused': {
            background: 'var(--color-surface-hovered)',
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          height: 50,
          width: 50,
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        anchorOriginBottomCenter: {
          '@media (min-width: 960px)': {
            bottom: '24px',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: 'var(--color-surface-default)',
          color: 'var(--color-content-default)',
          '&:hover': {
            background: 'var(--color-surface-hovered)',
          },
          '&.Mui-selected': {
            '&:hover': {
              background: 'var(--color-surface-hovered)',
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: 'var(--color-surface-default)',
          color: 'inherit',
        },
        elevation1: {
          boxShadow: boxShadow1,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--color-surface-reduced-alternative)',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: 'var(--color-accent-default)',
        },
      },
    },
  },
}

export const darkThemeOptions: ThemeOptions = {
  ...moiaThemeOptions,
  semantic: dark,
}

export { useTheme } from '@mui/material'

export type { Theme } from '@mui/material'
