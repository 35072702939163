import { logout } from '@backoffice-frontend/common'
import {
  MoiaButton,
  MoiaCard,
  MoiaCardContent,
  MoiaCardHeader,
  MoiaDialogFormFooter,
  MoiaViewBox,
} from '@backoffice-frontend/patterns'
import { useTranslation } from 'react-i18next'
import { MFASwitchView } from '../AccountView/components/MFASwitch/MFASwitchView'

export const SetupMFA = () => {
  const { t } = useTranslation()
  return (
    <MoiaViewBox css={{ height: '100vh', overflow: 'auto' }}>
      <MoiaCard>
        <MoiaCardHeader title={t('authenticatedAppView_mfaSetup_header')} />
        <MoiaCardContent>
          <MFASwitchView />
        </MoiaCardContent>
        <MoiaDialogFormFooter
          trailing={
            <MoiaButton onClick={logout}>
              {t('common_logout_button')}{' '}
            </MoiaButton>
          }
        />
      </MoiaCard>
    </MoiaViewBox>
  )
}
