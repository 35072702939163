import { MfaMethods, getUserPreferredMFA } from '@backoffice-frontend/common'
import { ContentInfo } from '@backoffice-frontend/patterns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const MFASuccessfulEnabledView = () => {
  const [hasMFA, setMFA] = useState<boolean | undefined>()

  useEffect(() => {
    void getUserPreferredMFA().then(mfaMethod => {
      if (mfaMethod === MfaMethods.SOFTWARE_TOKEN_MFA) {
        setMFA(true)
      }
    })
  }, [])
  const { t } = useTranslation()

  return (
    <>
      {hasMFA && (
        <ContentInfo
          variant="success"
          title={t('authenticatedAppView_mfaEnabled_title')}
          infoText={t('authenticatedAppView_mfaEnabled_caption')}
        />
      )}
    </>
  )
}
