import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { WelcomeAreaRoutes } from '@backoffice-frontend/welcome-api'
import { PersonIcon } from '@moia-dev/pace-icons'

export const WelcomeAreaNavigation = ({ open, label }: AreaNavigationProps) => {
  return (
    <NavigationItem
      open={open}
      label={label}
      to={WelcomeAreaRoutes.route}
      Icon={PersonIcon}
    />
  )
}
