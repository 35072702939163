import {
  WorkOrderServiceCategory,
  WorkOrderServiceItem,
} from '@backoffice-frontend/graphql'
import {
  useWorkOrderServiceCategoryTranslation,
  useWorkOrderServiceItemTranslation,
} from '@backoffice-frontend/vehicle-common'
import { Option } from '@moia-dev/pace-web'

export type WorkOrderServiceItemCategoryMapping = {
  title: WorkOrderServiceCategory
  serviceItems: WorkOrderServiceItem[]
}

const workOrderServiceItemCategoryMappings: WorkOrderServiceItemCategoryMapping[] =
  [
    {
      title: WorkOrderServiceCategory.MaintenanceService,
      serviceItems: [
        WorkOrderServiceItem.ActionManufacturer,
        WorkOrderServiceItem.ActionInternal,
        WorkOrderServiceItem.BrakeFluidChange,
      ],
    },
    {
      title: WorkOrderServiceCategory.DamagesDefects,
      serviceItems: [
        WorkOrderServiceItem.SeatComplaint,
        WorkOrderServiceItem.SmartRepairDent,
        WorkOrderServiceItem.SmartRepairPaintwork,
      ],
    },
    {
      title: WorkOrderServiceCategory.Ivc,
      serviceItems: [WorkOrderServiceItem.TabletUpdate],
    },
    {
      title: WorkOrderServiceCategory.HubActivities,
      serviceItems: [
        WorkOrderServiceItem.VehicleLettering,
        WorkOrderServiceItem.Cleaning,
        WorkOrderServiceItem.SpecialCleaning,
        WorkOrderServiceItem.TransferRide,
      ],
    },
  ]

export const workOrderServiceItemOptions = () => {
  return workOrderServiceItemCategoryMappings.flatMap(category => [
    <Option key={category.title} disabled={true} value={''}>
      {useWorkOrderServiceCategoryTranslation()(category.title)}
    </Option>,
    ...category.serviceItems.map(item => (
      <Option key={item} value={item}>
        {useWorkOrderServiceItemTranslation()(item)}
      </Option>
    )),
  ])
}
