import {
  FleetMapAreaDataTestIds,
  FleetMapAreaRoutes,
} from '@backoffice-frontend/common'
import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { MapIcon } from '@moia-dev/pace-icons'

export const FleetMapAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={FleetMapAreaRoutes.route}
      Icon={MapIcon}
      dataTestId={FleetMapAreaDataTestIds.Navigation}
    />
  )
}
