/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { GetPassengersWaypointFragmentDoc } from '../../../../../../ad-passenger-list/src/lib/utils/passenger-filters.hook'
import { AdVehicleStreamAdVehicleSeatingVehicleFragmentDoc } from '../../seating/seating.hook'
export type AdVehicleStreamPassengerUnbuckledActionCardVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  fccUpcomingWaypoints: Array<{
    __typename?: 'FccWaypoint'
    actions: Array<
      | {
          __typename: 'FccWaypointDeliveryAction'
          id: string
          estimatedAt: string
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename?: 'FccWaypointHubReturnAction' }
      | {
          __typename: 'FccWaypointPickupAction'
          id: string
          estimatedAt: string
          adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename?: 'FccWaypointReallocationAction' }
    >
  }> | null
  adSeatsLayout: {
    __typename?: 'FccSeatLayout'
    thirdRowRight: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    thirdRowMiddle: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    thirdRowLeft: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowRight: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowMiddle: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowLeft: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
  } | null
}

export const AdVehicleStreamPassengerUnbuckledActionCardVehicleFragmentDoc = gql`
    fragment AdVehicleStreamPassengerUnbuckledActionCardVehicle on Vehicle {
  id
  ...AdVehicleStreamAdVehicleSeatingVehicle
  fccUpcomingWaypoints {
    ...GetPassengersWaypoint
  }
}
    ${AdVehicleStreamAdVehicleSeatingVehicleFragmentDoc}
${GetPassengersWaypointFragmentDoc}`
