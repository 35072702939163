import { Environment, getEnvironment } from '@backoffice-frontend/environment'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { awsRum } from './awsRum'

export const setupGoogleAnalytics = () => {
  if (getEnvironment() === Environment.integration) {
    ReactGA.initialize('G-Q780YWLMCT')
  }
  if (getEnvironment() === Environment.production) {
    ReactGA.initialize('G-KZ7S0BRHD2')
  }
}

export const isTrackingEnabled = (label: string | undefined) => {
  switch (getEnvironment()) {
    case Environment.integration:
      console.info('reporting event with label:', label)
      return true
    case Environment.production:
      return true
    default:
      return false
  }
}

type TrackingProps = {
  action?: string
  category?: string
  label?: string
  value?: number
}
export const trackAnalyticsEvent = (props: TrackingProps) => {
  if (isTrackingEnabled(props.label)) {
    ReactGA.event({
      action: props.action ?? 'interaction',
      category: props.category ?? window.location.pathname.split('/')[1],
      label: props.label,
      value: props.value,
    })
    awsRum?.recordEvent(`${props.action ?? 'interaction'}${props.label}`, {})
  }
}

export const trackClickEvent = (label: string) => {
  if (isTrackingEnabled(label)) {
    trackAnalyticsEvent({ action: 'click', label: label })
    awsRum?.recordEvent(`click_${label}`, {})
  }
}

export const useImpressionTracking = (props: Omit<TrackingProps, 'action'>) => {
  // biome-ignore lint/correctness/useExhaustiveDependencies: we don't want to re-track really only track once
  useEffect(() => {
    trackAnalyticsEvent({ ...props, action: 'component-impression' })
  }, [])
}
