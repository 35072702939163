const baseRoute = '/pricing'
const createRoute = (subRoute: string) => ({
  route: `${baseRoute}/:serviceAreaId${subRoute}`,
  path: `/:serviceAreaId${subRoute}`,
})

export const PricingAreaRoutes = {
  route: baseRoute,
  path: '/',
  Overview: createRoute('/overview'),
  Simulator: {
    ...createRoute('/simulator'),
    Edit: createRoute('/simulator/edit'),
  },
  Optimizer: createRoute('/optimizer'),
}
