import { getCSSVariableValue } from '@backoffice-frontend/common'
import { useAppearance } from '@backoffice-frontend/dark-mode'
import { Global, css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { LeafletMapCommonAreaId } from '../../LeafletMapCommonAreaId'

const BOX_SHADOW_LIGHT = `0px 3px 1px -2px rgba(0, 0, 0, 0.2),
0px 2px 2px 0px rgba(0, 0, 0, 0.14),
0px 1px 5px 0px rgba(0, 0, 0, 0.12)`

const BOX_SHADOW_DARK = `0px 3px 1px -2px rgba(255, 255, 255, 0.2),
0px 2px 2px 0px rgba(255, 255, 255, 0.14),
0px 1px 5px 0px rgba(255, 255, 255, 0.12)`

const BUTTON_BORDER_RADIUS = '8px'

export const OverrideMapControlsCSS = () => {
  const { darkMode } = useAppearance()
  const BOX_SHADOW = darkMode ? BOX_SHADOW_DARK : BOX_SHADOW_LIGHT
  const COLOR_ACCENT_DEFAULT = getCSSVariableValue('--color-accent-default')
  const COLOR_SURFACE_DEFAULT = getCSSVariableValue('--color-surface-default')
  const COLOR_CONTENT_DEFAULT = getCSSVariableValue('--color-content-default')
  const COLOR_CONTENT_ON_ACCESS_DEFAULT = getCSSVariableValue(
    '--color-content-on-accent-default',
  )

  const { t } = useTranslation(LeafletMapCommonAreaId)

  const createStopLabel = t('Create stop')
  const createAreaLabel = t('Create area')
  return (
    <Global
      styles={css`
        .leaflet-bar,
        .leaflet-buttons-control-button:hover,
        .leaflet-control-layers {
          border-radius: ${BUTTON_BORDER_RADIUS} !important;
          border: none !important;
        }

        .leaflet-control-zoom-in:hover,
        .leaflet-control-zoom-out:hover,
        .leaflet-buttons-control-button:hover,
        .active .leaflet-buttons-control-button {
          box-shadow: ${BOX_SHADOW} !important;
          background-color: ${COLOR_ACCENT_DEFAULT} !important;
          color: ${COLOR_CONTENT_ON_ACCESS_DEFAULT} !important;
        }

        .leaflet-buttons-control-button,
        a.leaflet-control-zoom-out,
        a.leaflet-control-zoom-in,
        .leaflet-control-layers {
          border-radius: ${BUTTON_BORDER_RADIUS} !important;
          box-shadow: ${BOX_SHADOW};
          background: ${COLOR_SURFACE_DEFAULT} !important;
        }

        .leaflet-buttons-control-button,
        a.leaflet-control-zoom-out,
        a.leaflet-control-zoom-in {
          width: 36px !important;
          height: 36px !important;
          line-height: 36px !important;
          font-weight: 400;
        }

        a.leaflet-control-zoom-out,
        a.leaflet-control-zoom-in {
          color: ${COLOR_CONTENT_DEFAULT} !important;
        }

        a.leaflet-control-zoom-in {
          margin-bottom: 4px;
        }
        .leaflet-pm-toolbar.leaflet-pm-draw.leaflet-bar.leaflet-control {
          margin-top: 12px;
          display: flex;
          flex-direction: column;
          gap: 4px;
        }

        .leaflet-control-layers {
          border: none !important;
          box-shadow: ${BOX_SHADOW} !important;
        }

        .leaflet-pm-icon-polygon {
          background-image: ${
            darkMode
              ? 'url(/assets/maps/polygon_draw_active.svg)'
              : 'url(/assets/maps/polygon_draw.svg)'
          } !important;
        }
        .leaflet-buttons-control-button:hover .leaflet-pm-icon-polygon,
        .active .leaflet-pm-icon-polygon {
          background-image: url(/assets/maps/polygon_draw_active.svg) !important;
        }

        .leaflet-buttons-control-button {
          padding: 8px !important;
        }

        .leaflet-pm-icon-marker {
          background-image: ${
            darkMode
              ? 'url(/assets/maps/stop_draw_active.svg)'
              : 'url(/assets/maps/stop_draw.svg)'
          } !important;
        }
        .leaflet-buttons-control-button:hover .leaflet-pm-icon-marker,
        .active .leaflet-pm-icon-marker {
          background-image: url(/assets/maps/stop_draw_active.svg) !important;
        }

        .leaflet-control-layers-toggle {
          background-image: ${
            darkMode
              ? 'url(/assets/maps/layers_active.svg)'
              : 'url(/assets/maps/layers.svg)'
          } !important;
          background-size: 18px 18px !important;
          width: 36px !important;
          height: 36px !important;
        }

        .leaflet-control-layers-list {
          color: ${COLOR_CONTENT_DEFAULT};
        }

        .leaflet-div-icon {
          background: transparent !important;
          border: none !important;
        }

        div[title]:hover:after {
          content: attr(title);
          position: absolute !important;
          top: 10% !important;
          left: 40px !important;
          z-index: 1 !important;

          /* Subtitle 2 (14 bld) */
          font-family: 'Apercu MOIA Pro';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 160%;
          /* or 22px */

          color: #ffffff;

          background: #000c15;
          border-radius: 4px;
          padding: 4px 8px 4px 8px;
          width: max-content;
        }

        div[title='Draw Marker']:hover:after {
          content: '${createStopLabel}';
        }
        div[title='Draw Polygons']:hover:after {
          content: '${createAreaLabel}';
        }

        div.leaflet-bar.active:hover:after,
        div.button-container.active:hover:after {
          display: none;
        }

        .leaflet-pm-actions-container {
          left: 40px !important;
          top: 10% !important;
        }

        .leaflet-pm-actions-container a,
        .leaflet-pm-action {
          color: #ffffff !important;
          background: #000c15 !important;

          /* Subtitle 2 (14 bld) */
          font-family: 'Apercu MOIA Pro';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 160%;
          /* or 22px */
        }
      `}
    />
  )
}
