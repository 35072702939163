import { Avatar as MuiAvatar } from '@mui/material'
import type { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar'
import type { BadgeProps } from '@mui/material/Badge'
import Badge from '@mui/material/Badge'
import { H5, Subtitle2 } from './Typography'

export type AvatarProps = MuiAvatarProps & {
  active?: boolean
  small?: boolean
}

const AvatarContentWrapper = ({
  children,
  small = false,
}: {
  children: React.ReactNode
  small?: boolean
}): JSX.Element => {
  if (small) {
    return (
      <Subtitle2
        css={theme => ({
          color: theme.semantic.ColorContentOnActionSecondaryDefault,
        })}
      >
        {children}
      </Subtitle2>
    )
  }
  return (
    <H5
      css={theme => ({
        color: theme.semantic.ColorContentOnActionSecondaryDefault,
      })}
    >
      {children}
    </H5>
  )
}

/**
 * @deprecated
 */
export const Avatar = ({
  active = false,
  small = false,
  children,
  ...restProps
}: AvatarProps) => {
  const dimension = small ? 32 : 48

  return (
    <MuiAvatar
      css={{
        width: dimension,
        height: dimension,
        backgroundColor: active
          ? 'var(--color-action-secondary-pressed)'
          : 'var(--color-action-secondary-default)',
        ...(!active && {
          '&:hover': {
            backgroundColor: 'var(--color-action-secondary-hovered)',
          },
        }),
      }}
      {...restProps}
    >
      <AvatarContentWrapper small={small}>{children}</AvatarContentWrapper>
    </MuiAvatar>
  )
}

type NotificationBadgeProps = BadgeProps & {
  active?: boolean
}

/**
 * @deprecated Use the Badge component from pace-web instead.
 */
export const NotificationBadge = ({
  children,
  active = false,
}: NotificationBadgeProps) => {
  if (!active) return <>{children}</>

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      variant="dot"
      css={{
        '& .MuiBadge-badge': {
          backgroundColor: 'var(--color-critical-default)',
          color: 'var(--color-critical-default)',
          boxShadow: `0 0 0 2px var(--color-background-alternative)`,
          width: '12px',
          height: '12px',
          borderRadius: '50%',
          '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
          },
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
          },
        },
      }}
    >
      {children}
    </Badge>
  )
}
