import type { BadgeProps as MuiBadgeProps } from '@mui/material'
import { Badge as MuiBadge } from '@mui/material'

export type BadgeProps = {
  size?: 'small' | 'large'
} & MuiBadgeProps

/**
 * @deprecated Use Badge from "@moia-dev/pace-web" instead.
 */
export const Badge = ({ size = 'small', ...props }: BadgeProps) => {
  return (
    <MuiBadge
      variant="dot"
      css={{
        '& .MuiBadge-badge': {
          backgroundColor: 'var(--color-critical-default)',
          color: 'var(--color-critical-default)',
          boxShadow: `0 0 0 2px var(--color-background-alternative)`,
          ...(size === 'small'
            ? {
                width: '8px',
                height: '8px',
              }
            : {
                width: '12px',
                height: '12px',
              }),
          borderRadius: '50%',
        },
      }}
      {...props}
    />
  )
}
