/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { AdOnBoardPassengersVehicleFragmentDoc } from '../../../../ad-passenger-list/src/lib/AdOnBoardPassengers/AdOnBoardPassengers.hook'
import { AdVehicleStreamAdAlertListVehicleFragmentDoc } from '../AdAlertList/AdAlertList.hook'
import { AdVehicleStreamAdVehicleDoorStatusFragmentDoc } from '../AdVehicleDoorStatus/AdVehicleDoorStatus.hook'
import {
  AdVehicleStreamAlertVehicleMapVehicleFragmentDoc,
  AdVehicleStreamAlertVehicleMapWaypointFragmentDoc,
} from '../AlertVehicleMap/AlertVehicleMap.hook'
import { AdVehicleStreamEvaluateCabinSafetyCheckStatusFragmentDoc } from '../evaluateCabinSafetyCheckStatus/evaluateCabinSafetyCheckStatus.hook'
import { AdVehicleStreamEmergencyStopActionCardVehicleFragmentDoc } from './ActionCards/EmergencyStopActionCard/EmergencyStopActionCard.hook'
import { AdVehicleStreamPassengerUnbuckledActionCardVehicleFragmentDoc } from './ActionCards/PassengerUnbuckledActionCard/PassengerUnbuckledActionCard.hook'
import { AdVehicleStreamServiceCancellationActionCardVehicleFragmentDoc } from './ActionCards/ServiceCancellationActionCard/serviceCancellationActionCard.hook'
import { AdVehicleStreamHeaderVehicleFragmentDoc } from './AdVehicleModalHeader/AdVehicleModalHeader.hook'
const defaultOptions = {} as const
export type AdVehicleStreamAdVehicleModalQueryVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type AdVehicleStreamAdVehicleModalQuery = {
  __typename?: 'Query'
  vehicleByID: {
    __typename?: 'Vehicle'
    id: string
    isWheelchairAccessible: boolean | null
    isSelfDriving: boolean
    label: number | null
    fccUpcomingWaypoints: Array<{
      __typename?: 'FccWaypoint'
      id: string
      adState: Types.FccWaypointState | null
      stop: {
        __typename?: 'Stop'
        routingLocation: {
          __typename?: 'Location'
          latitude: number
          longitude: number
        }
      } | null
      actions: Array<
        | {
            __typename: 'FccWaypointDeliveryAction'
            estimatedAt: string
            id: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename: 'FccWaypointHubReturnAction'; estimatedAt: string }
        | {
            __typename: 'FccWaypointPickupAction'
            estimatedAt: string
            adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
            id: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename: 'FccWaypointReallocationAction'; estimatedAt: string }
      >
    }> | null
    fccOperatorAdAlerts: {
      __typename?: 'FccAdAlerts'
      alerts: Array<{
        __typename: 'FccAdAlert'
        id: string
        createdAt: string
        type: Types.FccAdAlertType
        status: Types.FccAdAlertStatus
      }>
    } | null
    latestLocation: {
      __typename?: 'VehicleLocation'
      heading: number | null
      location: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      } | null
    } | null
    latestADTelemetry: {
      __typename?: 'ADTelemetry'
      rightSlidingDoorOpen: {
        __typename?: 'ADTelemetryBooleanWithTimestamp'
        value: boolean
        timestamp: string
      } | null
      speed: {
        __typename?: 'ADTelemetryFloatWithTimestamp'
        value: number
      } | null
    } | null
    pooling: { __typename?: 'Pooling'; enabled: boolean } | null
    fccCurrentAdAlerts: {
      __typename?: 'FccAdAlerts'
      alerts: Array<{
        __typename?: 'FccAdAlert'
        id: string
        type: Types.FccAdAlertType
        status: Types.FccAdAlertStatus
      }>
    } | null
    adSeatsLayout: {
      __typename?: 'FccSeatLayout'
      thirdRowRight: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
      thirdRowMiddle: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
      thirdRowLeft: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
      secondRowRight: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
      secondRowMiddle: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
      secondRowLeft: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
    } | null
  }
}

export type AdVehicleStreamStopHandlingVehicleMutationVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type AdVehicleStreamStopHandlingVehicleMutation = {
  __typename?: 'Mutation'
  fccStopHandlingVehicle: any | null
}

export type AdVehicleStreamAdVehicleModalVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  isWheelchairAccessible: boolean | null
  isSelfDriving: boolean
  label: number | null
  fccUpcomingWaypoints: Array<{
    __typename?: 'FccWaypoint'
    id: string
    adState: Types.FccWaypointState | null
    stop: {
      __typename?: 'Stop'
      routingLocation: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
    } | null
    actions: Array<
      | {
          __typename: 'FccWaypointDeliveryAction'
          estimatedAt: string
          id: string
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename: 'FccWaypointHubReturnAction'; estimatedAt: string }
      | {
          __typename: 'FccWaypointPickupAction'
          estimatedAt: string
          adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
          id: string
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename: 'FccWaypointReallocationAction'; estimatedAt: string }
    >
  }> | null
  fccOperatorAdAlerts: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename: 'FccAdAlert'
      id: string
      createdAt: string
      type: Types.FccAdAlertType
      status: Types.FccAdAlertStatus
    }>
  } | null
  latestLocation: {
    __typename?: 'VehicleLocation'
    heading: number | null
    location: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    } | null
  } | null
  latestADTelemetry: {
    __typename?: 'ADTelemetry'
    rightSlidingDoorOpen: {
      __typename?: 'ADTelemetryBooleanWithTimestamp'
      value: boolean
      timestamp: string
    } | null
    speed: {
      __typename?: 'ADTelemetryFloatWithTimestamp'
      value: number
    } | null
  } | null
  pooling: { __typename?: 'Pooling'; enabled: boolean } | null
  fccCurrentAdAlerts: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename?: 'FccAdAlert'
      id: string
      type: Types.FccAdAlertType
      status: Types.FccAdAlertStatus
    }>
  } | null
  adSeatsLayout: {
    __typename?: 'FccSeatLayout'
    thirdRowRight: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    thirdRowMiddle: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    thirdRowLeft: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowRight: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowMiddle: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowLeft: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
  } | null
}

export const AdVehicleStreamAdVehicleModalVehicleFragmentDoc = gql`
    fragment AdVehicleStreamAdVehicleModalVehicle on Vehicle {
  id
  fccUpcomingWaypoints {
    ...AdVehicleStreamAlertVehicleMapWaypoint
    ...AdVehicleStreamEvaluateCabinSafetyCheckStatus
  }
  ...AdVehicleStreamHeaderVehicle
  ...AdVehicleStreamAdAlertListVehicle
  ...AdVehicleStreamAlertVehicleMapVehicle
  ...AdVehicleStreamAdVehicleDoorStatus
  ...AdVehicleStreamPassengerUnbuckledActionCardVehicle
  ...AdVehicleStreamServiceCancellationActionCardVehicle
  ...AdVehicleStreamEmergencyStopActionCardVehicle
  ...AdOnBoardPassengersVehicle
}
    ${AdVehicleStreamAlertVehicleMapWaypointFragmentDoc}
${AdVehicleStreamEvaluateCabinSafetyCheckStatusFragmentDoc}
${AdVehicleStreamHeaderVehicleFragmentDoc}
${AdVehicleStreamAdAlertListVehicleFragmentDoc}
${AdVehicleStreamAlertVehicleMapVehicleFragmentDoc}
${AdVehicleStreamAdVehicleDoorStatusFragmentDoc}
${AdVehicleStreamPassengerUnbuckledActionCardVehicleFragmentDoc}
${AdVehicleStreamServiceCancellationActionCardVehicleFragmentDoc}
${AdVehicleStreamEmergencyStopActionCardVehicleFragmentDoc}
${AdOnBoardPassengersVehicleFragmentDoc}`
export const AdVehicleStreamAdVehicleModalDocument = gql`
    query AdVehicleStreamAdVehicleModal($vehicleId: ID!) {
  vehicleByID(id: $vehicleId) {
    ...AdVehicleStreamAdVehicleModalVehicle
  }
}
    ${AdVehicleStreamAdVehicleModalVehicleFragmentDoc}`

/**
 * __useAdVehicleStreamAdVehicleModalQuery__
 *
 * To run a query within a React component, call `useAdVehicleStreamAdVehicleModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdVehicleStreamAdVehicleModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdVehicleStreamAdVehicleModalQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAdVehicleStreamAdVehicleModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdVehicleStreamAdVehicleModalQuery,
    AdVehicleStreamAdVehicleModalQueryVariables
  > &
    (
      | {
          variables: AdVehicleStreamAdVehicleModalQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdVehicleStreamAdVehicleModalQuery,
    AdVehicleStreamAdVehicleModalQueryVariables
  >(AdVehicleStreamAdVehicleModalDocument, options)
}
export function useAdVehicleStreamAdVehicleModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdVehicleStreamAdVehicleModalQuery,
    AdVehicleStreamAdVehicleModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdVehicleStreamAdVehicleModalQuery,
    AdVehicleStreamAdVehicleModalQueryVariables
  >(AdVehicleStreamAdVehicleModalDocument, options)
}
export function useAdVehicleStreamAdVehicleModalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdVehicleStreamAdVehicleModalQuery,
        AdVehicleStreamAdVehicleModalQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AdVehicleStreamAdVehicleModalQuery,
    AdVehicleStreamAdVehicleModalQueryVariables
  >(AdVehicleStreamAdVehicleModalDocument, options)
}
export type AdVehicleStreamAdVehicleModalQueryHookResult = ReturnType<
  typeof useAdVehicleStreamAdVehicleModalQuery
>
export type AdVehicleStreamAdVehicleModalLazyQueryHookResult = ReturnType<
  typeof useAdVehicleStreamAdVehicleModalLazyQuery
>
export type AdVehicleStreamAdVehicleModalSuspenseQueryHookResult = ReturnType<
  typeof useAdVehicleStreamAdVehicleModalSuspenseQuery
>
export type AdVehicleStreamAdVehicleModalQueryResult = Apollo.QueryResult<
  AdVehicleStreamAdVehicleModalQuery,
  AdVehicleStreamAdVehicleModalQueryVariables
>
export const AdVehicleStreamStopHandlingVehicleDocument = gql`
    mutation AdVehicleStreamStopHandlingVehicle($vehicleId: ID!) {
  fccStopHandlingVehicle(vehicleId: $vehicleId)
}
    `
export type AdVehicleStreamStopHandlingVehicleMutationFn =
  Apollo.MutationFunction<
    AdVehicleStreamStopHandlingVehicleMutation,
    AdVehicleStreamStopHandlingVehicleMutationVariables
  >

/**
 * __useAdVehicleStreamStopHandlingVehicleMutation__
 *
 * To run a mutation, you first call `useAdVehicleStreamStopHandlingVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdVehicleStreamStopHandlingVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adVehicleStreamStopHandlingVehicleMutation, { data, loading, error }] = useAdVehicleStreamStopHandlingVehicleMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAdVehicleStreamStopHandlingVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdVehicleStreamStopHandlingVehicleMutation,
    AdVehicleStreamStopHandlingVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdVehicleStreamStopHandlingVehicleMutation,
    AdVehicleStreamStopHandlingVehicleMutationVariables
  >(AdVehicleStreamStopHandlingVehicleDocument, options)
}
export type AdVehicleStreamStopHandlingVehicleMutationHookResult = ReturnType<
  typeof useAdVehicleStreamStopHandlingVehicleMutation
>
export type AdVehicleStreamStopHandlingVehicleMutationResult =
  Apollo.MutationResult<AdVehicleStreamStopHandlingVehicleMutation>
export type AdVehicleStreamStopHandlingVehicleMutationOptions =
  Apollo.BaseMutationOptions<
    AdVehicleStreamStopHandlingVehicleMutation,
    AdVehicleStreamStopHandlingVehicleMutationVariables
  >
