// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { loadTranslations as loadTranslationsAccidentsReports } from '@backoffice-frontend/accidents-reports'
import { loadTranslations as loadTranslationsAccounting } from '@backoffice-frontend/accounting'
import { loadTranslations as loadTranslationsAdConfiguration } from '@backoffice-frontend/ad-configuration'
import { loadTranslations as loadTranslationsAdFleetMap } from '@backoffice-frontend/ad-fleet-map'
import { loadTranslations as loadTranslationsAlerts } from '@backoffice-frontend/alerts'
import { loadTranslations as loadTranslationsAlertsTesting } from '@backoffice-frontend/alerts-testing'
import { loadTranslations as loadTranslationsCapacityPlanning } from '@backoffice-frontend/capacity-planning'
import { loadTranslations as loadTranslationsCoupon } from '@backoffice-frontend/coupon'
import { loadTranslations as loadTranslationsCustomer } from '@backoffice-frontend/customer'
import { loadTranslations as loadTranslationsCustomerFeatureAllowlisting } from '@backoffice-frontend/customer-feature-allowlisting'
import { loadTranslations as loadTranslationsDisruptions } from '@backoffice-frontend/disruptions'
import { loadTranslations as loadTranslationsDriverTipping } from '@backoffice-frontend/driver-tipping'
import { loadTranslations as loadTranslationsDsBoss } from '@backoffice-frontend/ds-boss'
import { loadTranslations as loadTranslationsEmployee } from '@backoffice-frontend/employee'
import { loadTranslations as loadTranslationsEmployeeTimeManagement } from '@backoffice-frontend/employee-time-management'
import { loadTranslations as loadTranslationsFleetMaintenance } from '@backoffice-frontend/fleet-maintenance'
import { loadTranslations as loadTranslationsFleetManagementTesting } from '@backoffice-frontend/fleet-management-testing'
import { loadTranslations as loadTranslationsFleetMap } from '@backoffice-frontend/fleet-map'
import { loadTranslations as loadTranslationsFleets } from '@backoffice-frontend/fleets'
import { loadTranslations as loadTranslationsHubs } from '@backoffice-frontend/hubs'
import { loadTranslations as loadTranslationsKpiDashboard } from '@backoffice-frontend/kpi-dashboard'
import { loadTranslations as loadTranslationsLegalDocumentManagement } from '@backoffice-frontend/legal-document-management'
import { loadTranslations as loadTranslationsOfferAndPooling } from '@backoffice-frontend/offer-and-pooling'
import { loadTranslations as loadTranslationsPassengerApp } from '@backoffice-frontend/passenger-app'
import { loadTranslations as loadTranslationsPricing } from '@backoffice-frontend/pricing'
import { loadTranslations as loadTranslationsReportIo } from '@backoffice-frontend/report-io'
import { loadTranslations as loadTranslationsSafetyDriverGuidance } from '@backoffice-frontend/safety-driver-guidance'
import { loadTranslations as loadTranslationsScLabs } from '@backoffice-frontend/sc-labs'
import { loadTranslations as loadTranslationsServiceHours } from '@backoffice-frontend/service-hours'
import { loadTranslations as loadTranslationsServiceSettings } from '@backoffice-frontend/service-settings'
import { loadTranslations as loadTranslationsShifts } from '@backoffice-frontend/shifts'
import { loadTranslations as loadTranslationsShiftsCreation } from '@backoffice-frontend/shifts-creation'
import { loadTranslations as loadTranslationsStopNetwork } from '@backoffice-frontend/stop-network'
import { loadTranslations as loadTranslationsStopNetworkAlerts } from '@backoffice-frontend/stop-network-alerts'
import { loadTranslations as loadTranslationsTrip } from '@backoffice-frontend/trip'
import { loadTranslations as loadTranslationsUserManagement } from '@backoffice-frontend/user-management'
import { loadTranslations as loadTranslationsVehicle } from '@backoffice-frontend/vehicle'
import { loadTranslations as loadTranslationsVehicleAlerts } from '@backoffice-frontend/vehicle-alerts'
import { loadTranslations as loadTranslationsVehicleGroup } from '@backoffice-frontend/vehicle-group'
import { loadTranslations as loadTranslationsVirtualAdVehicles } from '@backoffice-frontend/virtual-ad-vehicles'
import { loadTranslations as loadTranslationsWelcome } from '@backoffice-frontend/welcome'

export const loadSharedAreaTranslations = () => {
  loadTranslationsPassengerApp()
  loadTranslationsWelcome()
  loadTranslationsUserManagement()
  loadTranslationsFleetMap()
  loadTranslationsAccounting()
  loadTranslationsAdConfiguration()
  loadTranslationsAdFleetMap()
  loadTranslationsSafetyDriverGuidance()
  loadTranslationsAlerts()
  loadTranslationsAlertsTesting()
  loadTranslationsCoupon()
  loadTranslationsCustomer()
  loadTranslationsKpiDashboard()
  loadTranslationsScLabs()
  loadTranslationsDriverTipping()
  loadTranslationsEmployee()
  loadTranslationsEmployeeTimeManagement()
  loadTranslationsFleetMaintenance()
  loadTranslationsPricing()
  loadTranslationsHubs()
  loadTranslationsServiceHours()
  loadTranslationsShifts()
  loadTranslationsCapacityPlanning()
  loadTranslationsShiftsCreation()
  loadTranslationsStopNetwork()
  loadTranslationsStopNetworkAlerts()
  loadTranslationsTrip()
  loadTranslationsVehicle()
  loadTranslationsVehicleAlerts()
  loadTranslationsVehicleGroup()
  loadTranslationsFleetManagementTesting()
  loadTranslationsVirtualAdVehicles()
  loadTranslationsDsBoss()
  loadTranslationsOfferAndPooling()
  loadTranslationsServiceSettings()
  loadTranslationsCustomerFeatureAllowlisting()
  loadTranslationsAccidentsReports()
  loadTranslationsReportIo()
  loadTranslationsDisruptions()
  loadTranslationsFleets()
  loadTranslationsLegalDocumentManagement()
}
