import { DriverTippingAreaRoutes } from '@backoffice-frontend/driver-tipping-api'
import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { CurrencyCircleEuroIcon } from '@moia-dev/pace-icons'
import { DriverTippingAreaDataTestIds } from './DriverTippingAreaDataTestIds'

export const DriverTippingAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={DriverTippingAreaRoutes.route}
      Icon={CurrencyCircleEuroIcon}
      dataTestId={DriverTippingAreaDataTestIds.Navigation}
    />
  )
}
