import { ServiceSettingsAreaId } from './ServiceSettingsAreaId'

const baseRoute = '/service-settings'

export const ServiceSettingsAreaRoutes = {
  route: baseRoute,
  title: `${ServiceSettingsAreaId}:title`,
  ServiceSettingsServiceArea: {
    route: ':serviceAreaId/service-area',
    Edit: {
      route: 'edit',
    },
  },
  ServiceSettingsConfiguration: {
    route: ':serviceAreaId/configuration',
  },
  ServiceSettingsServiceClasses: {
    route: ':serviceAreaId/service-classes',
  },
}
