/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type CallVehicleButtonVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  fccCurrentAdAlerts: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename?: 'FccAdAlert'
      id: string
      type: Types.FccAdAlertType
      status: Types.FccAdAlertStatus
    }>
  } | null
}

export type CallVehicleButtonStartHandlingVehicleMutationVariables =
  Types.Exact<{
    vehicleId: Types.Scalars['ID']['input']
  }>

export type CallVehicleButtonStartHandlingVehicleMutation = {
  __typename?: 'Mutation'
  fccStartHandlingVehicle: any | null
}

export type FccAllPhoneNumbersByVehicleIdQueryVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type FccAllPhoneNumbersByVehicleIdQuery = {
  __typename?: 'Query'
  fccAllPhoneNumbersByVehicleId: Array<{
    __typename?: 'FccPhoneNumber'
    phoneNumber: string
    type: Types.FccPhoneNumberType
  }>
}

export type FccCallSafetyDriverMutationVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String']['input']
  vehicleId: Types.Scalars['ID']['input']
  contactId: Types.Scalars['ID']['input']
}>

export type FccCallSafetyDriverMutation = {
  __typename?: 'Mutation'
  fccCallSafetyDriver: any | null
}

export const CallVehicleButtonVehicleFragmentDoc = gql`
    fragment CallVehicleButtonVehicle on Vehicle {
  id
  fccCurrentAdAlerts {
    alerts {
      id
      type
      status
    }
  }
}
    `
export const CallVehicleButtonStartHandlingVehicleDocument = gql`
    mutation CallVehicleButtonStartHandlingVehicle($vehicleId: ID!) {
  fccStartHandlingVehicle(vehicleId: $vehicleId)
}
    `
export type CallVehicleButtonStartHandlingVehicleMutationFn =
  Apollo.MutationFunction<
    CallVehicleButtonStartHandlingVehicleMutation,
    CallVehicleButtonStartHandlingVehicleMutationVariables
  >

/**
 * __useCallVehicleButtonStartHandlingVehicleMutation__
 *
 * To run a mutation, you first call `useCallVehicleButtonStartHandlingVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallVehicleButtonStartHandlingVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callVehicleButtonStartHandlingVehicleMutation, { data, loading, error }] = useCallVehicleButtonStartHandlingVehicleMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useCallVehicleButtonStartHandlingVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CallVehicleButtonStartHandlingVehicleMutation,
    CallVehicleButtonStartHandlingVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CallVehicleButtonStartHandlingVehicleMutation,
    CallVehicleButtonStartHandlingVehicleMutationVariables
  >(CallVehicleButtonStartHandlingVehicleDocument, options)
}
export type CallVehicleButtonStartHandlingVehicleMutationHookResult =
  ReturnType<typeof useCallVehicleButtonStartHandlingVehicleMutation>
export type CallVehicleButtonStartHandlingVehicleMutationResult =
  Apollo.MutationResult<CallVehicleButtonStartHandlingVehicleMutation>
export type CallVehicleButtonStartHandlingVehicleMutationOptions =
  Apollo.BaseMutationOptions<
    CallVehicleButtonStartHandlingVehicleMutation,
    CallVehicleButtonStartHandlingVehicleMutationVariables
  >
export const FccAllPhoneNumbersByVehicleIdDocument = gql`
    query fccAllPhoneNumbersByVehicleId($vehicleId: ID!) {
  fccAllPhoneNumbersByVehicleId(vehicleId: $vehicleId) {
    phoneNumber
    type
  }
}
    `

/**
 * __useFccAllPhoneNumbersByVehicleIdQuery__
 *
 * To run a query within a React component, call `useFccAllPhoneNumbersByVehicleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFccAllPhoneNumbersByVehicleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFccAllPhoneNumbersByVehicleIdQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useFccAllPhoneNumbersByVehicleIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FccAllPhoneNumbersByVehicleIdQuery,
    FccAllPhoneNumbersByVehicleIdQueryVariables
  > &
    (
      | {
          variables: FccAllPhoneNumbersByVehicleIdQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FccAllPhoneNumbersByVehicleIdQuery,
    FccAllPhoneNumbersByVehicleIdQueryVariables
  >(FccAllPhoneNumbersByVehicleIdDocument, options)
}
export function useFccAllPhoneNumbersByVehicleIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FccAllPhoneNumbersByVehicleIdQuery,
    FccAllPhoneNumbersByVehicleIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FccAllPhoneNumbersByVehicleIdQuery,
    FccAllPhoneNumbersByVehicleIdQueryVariables
  >(FccAllPhoneNumbersByVehicleIdDocument, options)
}
export function useFccAllPhoneNumbersByVehicleIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FccAllPhoneNumbersByVehicleIdQuery,
        FccAllPhoneNumbersByVehicleIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    FccAllPhoneNumbersByVehicleIdQuery,
    FccAllPhoneNumbersByVehicleIdQueryVariables
  >(FccAllPhoneNumbersByVehicleIdDocument, options)
}
export type FccAllPhoneNumbersByVehicleIdQueryHookResult = ReturnType<
  typeof useFccAllPhoneNumbersByVehicleIdQuery
>
export type FccAllPhoneNumbersByVehicleIdLazyQueryHookResult = ReturnType<
  typeof useFccAllPhoneNumbersByVehicleIdLazyQuery
>
export type FccAllPhoneNumbersByVehicleIdSuspenseQueryHookResult = ReturnType<
  typeof useFccAllPhoneNumbersByVehicleIdSuspenseQuery
>
export type FccAllPhoneNumbersByVehicleIdQueryResult = Apollo.QueryResult<
  FccAllPhoneNumbersByVehicleIdQuery,
  FccAllPhoneNumbersByVehicleIdQueryVariables
>
export const FccCallSafetyDriverDocument = gql`
    mutation fccCallSafetyDriver($phoneNumber: String!, $vehicleId: ID!, $contactId: ID!) {
  fccCallSafetyDriver(
    input: {vehicleId: $vehicleId, phoneNumber: $phoneNumber, contactId: $contactId}
  )
}
    `
export type FccCallSafetyDriverMutationFn = Apollo.MutationFunction<
  FccCallSafetyDriverMutation,
  FccCallSafetyDriverMutationVariables
>

/**
 * __useFccCallSafetyDriverMutation__
 *
 * To run a mutation, you first call `useFccCallSafetyDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFccCallSafetyDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fccCallSafetyDriverMutation, { data, loading, error }] = useFccCallSafetyDriverMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      vehicleId: // value for 'vehicleId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useFccCallSafetyDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FccCallSafetyDriverMutation,
    FccCallSafetyDriverMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FccCallSafetyDriverMutation,
    FccCallSafetyDriverMutationVariables
  >(FccCallSafetyDriverDocument, options)
}
export type FccCallSafetyDriverMutationHookResult = ReturnType<
  typeof useFccCallSafetyDriverMutation
>
export type FccCallSafetyDriverMutationResult =
  Apollo.MutationResult<FccCallSafetyDriverMutation>
export type FccCallSafetyDriverMutationOptions = Apollo.BaseMutationOptions<
  FccCallSafetyDriverMutation,
  FccCallSafetyDriverMutationVariables
>
