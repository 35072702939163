import { Routes, confirmForgotPassword } from '@backoffice-frontend/common'
import { FormPasswordfield, FormTextfield } from '@backoffice-frontend/forms'
import { MoiaGrid, useTheme } from '@backoffice-frontend/patterns'
import { usePaceValidators } from '@backoffice-frontend/validators'
import { Button, Callout, LoadingIndicator } from '@moia-dev/pace-web'
import { Text } from '@moia-dev/pace-web'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import type { SetNewPasswordErrorType } from '../api'
import { GetSupportChannelByEmail } from '../components/GetSupportChannelByEmail'
import { PublicAppViewContentLayout } from '../components/PublicAppViewContentLayout'

type SetNewPasswordValues = {
  verificationCode: string
  newPassword: string
  newPasswordRepeat: string
}

export const SetNewPasswordView = ({ isMobile }: { isMobile: boolean }) => {
  const { username = '' } = useParams<{ username: string }>()
  const navigate = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation()
  const { required } = usePaceValidators()

  const { handleSubmit, control, formState, watch, setError } =
    useForm<SetNewPasswordValues>({
      mode: 'onChange',
      defaultValues: {
        verificationCode: '',
        newPassword: '',
        newPasswordRepeat: '',
      },
    })

  const newPasswordRepeat = watch('newPasswordRepeat')

  const handleValidationError = async (error: SetNewPasswordErrorType) => {
    switch (error.code) {
      case 'LimitExceededException':
        setError('root.serverError', {
          message: t('loginView_resetPasswordLimitExceededError'),
          type: 'limitExceeded',
        })
        break
      case 'InvalidPasswordException':
        setError('newPassword', {
          type: 'validate',
          message: t('common_passwordPolicy_error'),
        })
        break
      case 'CodeMismatchException':
        setError('verificationCode', {
          type: 'validate',
          message: t('loginView_resetPasswordCodeMismatchError'),
        })
        break
      default:
        setError('root.serverError', {
          message: t('loginView_resetPasswordError'),
          type: 'default',
        })
    }
  }

  const onSubmit = async ({
    verificationCode,
    newPassword,
  }: SetNewPasswordValues) => {
    if (newPasswordRepeat && newPasswordRepeat !== newPassword) {
      setError('newPasswordRepeat', {
        type: 'validate',
        message: t('common_newPasswordMismatch_error'),
      })
      return
    }

    try {
      await confirmForgotPassword(username, verificationCode, newPassword)
      navigate(Routes.Auth.Login.url)
    } catch (e) {
      handleValidationError(e as SetNewPasswordErrorType)
    }
  }

  return (
    <PublicAppViewContentLayout
      title={t('loginView_resetPassword_title')}
      isMobile={isMobile}
    >
      {formState.errors.root?.serverError && (
        <div
          css={theme => ({
            marginTop: isMobile ? theme.space.Space2 : theme.space.Space6,
          })}
        >
          {formState.errors.root.serverError.type === 'default' ? (
            <Callout variant="critical">
              {formState.errors.root.serverError.message}
              <GetSupportChannelByEmail email={username} />
            </Callout>
          ) : (
            <Callout variant="neutral">
              {formState.errors.root.serverError.message}
            </Callout>
          )}
        </div>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          marginTop: isMobile ? theme.space.Space2 : theme.space.Space6,
        }}
      >
        <Text
          size="sm"
          css={{ paddingBottom: `${theme.space.Space2}`, display: 'block' }}
        >
          <Text weight="bold">{t('common_passwordPolicy_header')}</Text>
          <Text
            css={{
              whiteSpace: 'pre-line',
              display: 'block',
              paddingLeft: theme.space.Space2,
            }}
          >
            {t('common_passwordPolicy')}
          </Text>
        </Text>
        <MoiaGrid mt={1} gridGap={isMobile ? 1 : 3}>
          <FormTextfield
            control={control}
            name="verificationCode"
            label={t('loginView_resetPasswordVerificationCode_label')}
            rules={{ required }}
            autoComplete="code"
            removeSpaces
          />
          <FormPasswordfield
            control={control}
            name="newPassword"
            label={t('loginView_newPassword_label')}
            rules={{
              required,
            }}
            removeSpaces
          />
          <FormPasswordfield
            control={control}
            name="newPasswordRepeat"
            label={t('loginView_newPasswordRepeat_label')}
            rules={{
              required,
            }}
            removeSpaces
          />
          <MoiaGrid
            justifyContent="end"
            gridTemplateColumns="max-content"
            mt={1}
          >
            <Button
              type="submit"
              disabled={!formState.isValid || formState.isSubmitting}
              color="primary"
              label={t('common_submit_button')}
              icon={formState.isSubmitting ? <LoadingIndicator /> : undefined}
            ></Button>
          </MoiaGrid>
        </MoiaGrid>
      </form>
    </PublicAppViewContentLayout>
  )
}
