import {
  EmployeeAreaDataTestIds,
  EmployeeAreaId,
  EmployeeAreaRoutes,
} from '@backoffice-frontend/common'
import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { UsersIcon } from '@moia-dev/pace-icons'
import { useTranslation } from 'react-i18next'

export const EmployeeAreaNavigation = ({
  onClick,
  open,
}: AreaNavigationProps) => {
  const { t } = useTranslation(EmployeeAreaId)
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={t('Employees')}
      to={EmployeeAreaRoutes.route}
      Icon={UsersIcon}
      dataTestId={EmployeeAreaDataTestIds.Navigation}
    />
  )
}
