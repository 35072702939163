import {
  FormField,
  type FormFieldProps,
  Password,
  type PasswordProps,
} from '@moia-dev/pace-web'
import { forwardRef, useId } from 'react'

export type PasswordfieldProps = Pick<
  FormFieldProps,
  'label' | 'caption' | 'validation' | 'hideCharCount'
> &
  PasswordProps

export const Passwordfield = forwardRef<HTMLInputElement, PasswordfieldProps>(
  (
    {
      label,
      name,
      caption,
      id: maybeId,
      validation,
      maxLength,
      value,
      required,
      hideCharCount,
      onChange,
      ...props
    }: PasswordfieldProps,
    ref,
  ) => {
    const generatedId = useId()
    const id = maybeId ? maybeId : generatedId
    return (
      <FormField
        required={required}
        value={maxLength && typeof value === 'string' ? value : undefined}
        maxLength={maxLength}
        caption={caption}
        label={label}
        htmlFor={id}
        validation={validation}
        hideCharCount={hideCharCount}
      >
        <Password
          id={id}
          ref={ref}
          {...props}
          required={required}
          onChange={onChange}
          value={value}
          maxLength={maxLength}
        />
      </FormField>
    )
  },
)
