import type { BoumRight } from '@moia-dev/moia-token-claims'
import type { ReactNode } from 'react'
import { useBoumClaimsConsumer } from './RestrictedContext'
import { hasAccess } from './hasAccess'

type RestrictedProps = {
  requiredRights?: BoumRight[]
  allowedRights?: BoumRight[]
  replacement?: ReactNode
  children?: ReactNode
}

export const Restricted = ({
  allowedRights,
  replacement,
  requiredRights,
  children,
}: RestrictedProps) => {
  const boumClaim = useBoumClaimsConsumer()
  if (
    hasAccess({
      boumClaim,
      allowedRights,
      requiredRights,
    })
  ) {
    return <>{children}</>
  }
  if (replacement) {
    return <>{replacement}</>
  }
  return null
}
