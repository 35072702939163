import { Language, config, setLocale } from '@backoffice-frontend/common'
import { ENV, Environment } from '@backoffice-frontend/environment'
import { Divider } from '@backoffice-frontend/patterns'
import { CheckmarkIcon, ChevronLeftIcon } from '@moia-dev/pace-icons'
import { ActionItem } from '@moia-dev/pace-web'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import type { MenuState } from './MenuState'
import { menuAnimations } from './menuAnimations'

interface AccountBadgeLanguageSwitchProps {
  setCurrentMenu: (menu: MenuState) => void
}

export const AccountBadgeLanguageSwitch = ({
  setCurrentMenu,
}: AccountBadgeLanguageSwitchProps) => {
  const { t } = useTranslation()

  const isProduction = ENV === Environment.production

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={menuAnimations}
    >
      <ActionItem
        label={t('authenticatedAppView_language_label')}
        leading={<ChevronLeftIcon />}
        onClick={e => {
          e.stopPropagation()
          setCurrentMenu('main')
        }}
      />
      <Divider spacing={1} />
      <ActionItem
        leading={config.getLanguage() === Language.de && <CheckmarkIcon />}
        label={t('common_german_label')}
        onClick={e => {
          e.stopPropagation()
          setLocale(Language.de)
        }}
      />
      <ActionItem
        leading={config.getLanguage() === Language.en && <CheckmarkIcon />}
        label={t('common_english_label')}
        onClick={e => {
          e.stopPropagation()
          setLocale(Language.en)
        }}
      />
      {!isProduction && (
        <ActionItem
          leading={config.getLanguage() === 'cimode' && <CheckmarkIcon />}
          label={t('authenticatedAppView_language_phrase_keys')}
          onClick={e => {
            e.stopPropagation()
            setLocale('cimode')
          }}
        />
      )}
    </motion.div>
  )
}
