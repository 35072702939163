// Area route config

// This file was once generated when you created this area
// but you can edit it freely

const baseRoute = '/legal-document-management'

export const LegalDocumentManagementAreaRoutes = {
  route: baseRoute,
  path: '/',
}
