import { DataTestIds } from '@backoffice-frontend/common'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import type * as React from 'react'
import { ExpandMoreIcon } from '../basics/Icons/MaterialUIIcons'
import { Body1 } from '../basics/Typography'
import { CircularProgress } from '../feedback/CircularProgress'

type Props = {
  children: React.ReactNode
  heading: React.ReactNode
  loading?: boolean
  dataTestId?: string
  defaultExpanded?: boolean
  expanded?: boolean
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void
  className?: string
}

/**
 * @deprecated Use Accordion from "@moia-dev/pace-web" instead.
 */
export const ExpanderTile = ({
  dataTestId,
  heading,
  children,
  loading,
  defaultExpanded,
  expanded,
  onChange,
  className,
}: Props) => (
  <Accordion
    data-testid={dataTestId}
    className={className}
    defaultExpanded={defaultExpanded}
    expanded={expanded}
    onChange={onChange}
  >
    <AccordionSummary
      data-testid={DataTestIds.ExpanderTile.ToggleButton}
      expandIcon={loading ? <CircularProgress size={25} /> : <ExpandMoreIcon />}
    >
      <Body1>{heading}</Body1>
    </AccordionSummary>
    <AccordionDetails>
      {!loading && <div style={{ width: '100%' }}>{children}</div>}
    </AccordionDetails>
  </Accordion>
)
