// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import {
  AdConfigurationAreaDataTestIds,
  AdConfigurationAreaId,
  AdConfigurationAreaRoutes,
} from '@backoffice-frontend/common'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { AdConfigurationAreaNavigation } from './AdConfigurationAreaNavigation'

const LazyAdConfigurationComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./AdConfigurationArea')),
)

const AdConfigurationComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyAdConfigurationComponent />
  </SuspenseLoadingWrapper>
)

export const AdConfigurationArea = {
  id: AdConfigurationAreaId,
  dataTestIds: AdConfigurationAreaDataTestIds,
  requiredRight: BoumRight.AREA_AD_CONFIGURATION,
  NavigationComponent: AdConfigurationAreaNavigation,
  route: {
    path: AdConfigurationAreaRoutes.route,
    component: AdConfigurationComponent,
  },
}
