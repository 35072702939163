/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type AdVehicleStreamEmergencyStopResolutionVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  latestADTelemetry: {
    __typename?: 'ADTelemetry'
    rightSlidingDoorOpen: {
      __typename?: 'ADTelemetryBooleanWithTimestamp'
      value: boolean
    } | null
  } | null
}

export const AdVehicleStreamEmergencyStopResolutionVehicleFragmentDoc = gql`
    fragment AdVehicleStreamEmergencyStopResolutionVehicle on Vehicle {
  id
  latestADTelemetry {
    rightSlidingDoorOpen {
      value
    }
  }
}
    `
