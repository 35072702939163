/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type BackofficeUpdateSetLastVisitMutationVariables = Types.Exact<{
  [key: string]: never
}>

export type BackofficeUpdateSetLastVisitMutation = {
  __typename?: 'Mutation'
  backofficeUpdateSetLastVisit: {
    __typename?: 'BackofficeUpdatesLastVisitResponse'
    lastVisitDate: string
  }
}

export type BackofficeStatusSetLastVisitMutationVariables = Types.Exact<{
  [key: string]: never
}>

export type BackofficeStatusSetLastVisitMutation = {
  __typename?: 'Mutation'
  backofficeStatusSetLastVisit: {
    __typename?: 'BackofficeStatusesLastVisitResponse'
    lastVisitDate: string
  }
}

export type BackofficeUpdateGetLastVisitForBadgeQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type BackofficeUpdateGetLastVisitForBadgeQuery = {
  __typename?: 'Query'
  lastVisit: {
    __typename?: 'BackofficeUpdatesLastVisitResponse'
    lastVisitDate: string
  }
}

export type BackofficeStatusGetLastVisitForBadgeQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type BackofficeStatusGetLastVisitForBadgeQuery = {
  __typename?: 'Query'
  lastVisit: {
    __typename?: 'BackofficeStatusesLastVisitResponse'
    lastVisitDate: string
  }
}

export const BackofficeUpdateSetLastVisitDocument = gql`
    mutation backofficeUpdateSetLastVisit {
  backofficeUpdateSetLastVisit {
    lastVisitDate
  }
}
    `
export type BackofficeUpdateSetLastVisitMutationFn = Apollo.MutationFunction<
  BackofficeUpdateSetLastVisitMutation,
  BackofficeUpdateSetLastVisitMutationVariables
>

/**
 * __useBackofficeUpdateSetLastVisitMutation__
 *
 * To run a mutation, you first call `useBackofficeUpdateSetLastVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUpdateSetLastVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeUpdateSetLastVisitMutation, { data, loading, error }] = useBackofficeUpdateSetLastVisitMutation({
 *   variables: {
 *   },
 * });
 */
export function useBackofficeUpdateSetLastVisitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeUpdateSetLastVisitMutation,
    BackofficeUpdateSetLastVisitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeUpdateSetLastVisitMutation,
    BackofficeUpdateSetLastVisitMutationVariables
  >(BackofficeUpdateSetLastVisitDocument, options)
}
export type BackofficeUpdateSetLastVisitMutationHookResult = ReturnType<
  typeof useBackofficeUpdateSetLastVisitMutation
>
export type BackofficeUpdateSetLastVisitMutationResult =
  Apollo.MutationResult<BackofficeUpdateSetLastVisitMutation>
export type BackofficeUpdateSetLastVisitMutationOptions =
  Apollo.BaseMutationOptions<
    BackofficeUpdateSetLastVisitMutation,
    BackofficeUpdateSetLastVisitMutationVariables
  >
export const BackofficeStatusSetLastVisitDocument = gql`
    mutation backofficeStatusSetLastVisit {
  backofficeStatusSetLastVisit {
    lastVisitDate
  }
}
    `
export type BackofficeStatusSetLastVisitMutationFn = Apollo.MutationFunction<
  BackofficeStatusSetLastVisitMutation,
  BackofficeStatusSetLastVisitMutationVariables
>

/**
 * __useBackofficeStatusSetLastVisitMutation__
 *
 * To run a mutation, you first call `useBackofficeStatusSetLastVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeStatusSetLastVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeStatusSetLastVisitMutation, { data, loading, error }] = useBackofficeStatusSetLastVisitMutation({
 *   variables: {
 *   },
 * });
 */
export function useBackofficeStatusSetLastVisitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeStatusSetLastVisitMutation,
    BackofficeStatusSetLastVisitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeStatusSetLastVisitMutation,
    BackofficeStatusSetLastVisitMutationVariables
  >(BackofficeStatusSetLastVisitDocument, options)
}
export type BackofficeStatusSetLastVisitMutationHookResult = ReturnType<
  typeof useBackofficeStatusSetLastVisitMutation
>
export type BackofficeStatusSetLastVisitMutationResult =
  Apollo.MutationResult<BackofficeStatusSetLastVisitMutation>
export type BackofficeStatusSetLastVisitMutationOptions =
  Apollo.BaseMutationOptions<
    BackofficeStatusSetLastVisitMutation,
    BackofficeStatusSetLastVisitMutationVariables
  >
export const BackofficeUpdateGetLastVisitForBadgeDocument = gql`
    query backofficeUpdateGetLastVisitForBadge {
  lastVisit: backofficeUpdateGetLastVisit {
    lastVisitDate
  }
}
    `

/**
 * __useBackofficeUpdateGetLastVisitForBadgeQuery__
 *
 * To run a query within a React component, call `useBackofficeUpdateGetLastVisitForBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUpdateGetLastVisitForBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeUpdateGetLastVisitForBadgeQuery({
 *   variables: {
 *   },
 * });
 */
export function useBackofficeUpdateGetLastVisitForBadgeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BackofficeUpdateGetLastVisitForBadgeQuery,
    BackofficeUpdateGetLastVisitForBadgeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BackofficeUpdateGetLastVisitForBadgeQuery,
    BackofficeUpdateGetLastVisitForBadgeQueryVariables
  >(BackofficeUpdateGetLastVisitForBadgeDocument, options)
}
export function useBackofficeUpdateGetLastVisitForBadgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BackofficeUpdateGetLastVisitForBadgeQuery,
    BackofficeUpdateGetLastVisitForBadgeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BackofficeUpdateGetLastVisitForBadgeQuery,
    BackofficeUpdateGetLastVisitForBadgeQueryVariables
  >(BackofficeUpdateGetLastVisitForBadgeDocument, options)
}
export function useBackofficeUpdateGetLastVisitForBadgeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BackofficeUpdateGetLastVisitForBadgeQuery,
        BackofficeUpdateGetLastVisitForBadgeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BackofficeUpdateGetLastVisitForBadgeQuery,
    BackofficeUpdateGetLastVisitForBadgeQueryVariables
  >(BackofficeUpdateGetLastVisitForBadgeDocument, options)
}
export type BackofficeUpdateGetLastVisitForBadgeQueryHookResult = ReturnType<
  typeof useBackofficeUpdateGetLastVisitForBadgeQuery
>
export type BackofficeUpdateGetLastVisitForBadgeLazyQueryHookResult =
  ReturnType<typeof useBackofficeUpdateGetLastVisitForBadgeLazyQuery>
export type BackofficeUpdateGetLastVisitForBadgeSuspenseQueryHookResult =
  ReturnType<typeof useBackofficeUpdateGetLastVisitForBadgeSuspenseQuery>
export type BackofficeUpdateGetLastVisitForBadgeQueryResult =
  Apollo.QueryResult<
    BackofficeUpdateGetLastVisitForBadgeQuery,
    BackofficeUpdateGetLastVisitForBadgeQueryVariables
  >
export const BackofficeStatusGetLastVisitForBadgeDocument = gql`
    query backofficeStatusGetLastVisitForBadge {
  lastVisit: backofficeStatusGetLastVisit {
    lastVisitDate
  }
}
    `

/**
 * __useBackofficeStatusGetLastVisitForBadgeQuery__
 *
 * To run a query within a React component, call `useBackofficeStatusGetLastVisitForBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeStatusGetLastVisitForBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeStatusGetLastVisitForBadgeQuery({
 *   variables: {
 *   },
 * });
 */
export function useBackofficeStatusGetLastVisitForBadgeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BackofficeStatusGetLastVisitForBadgeQuery,
    BackofficeStatusGetLastVisitForBadgeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BackofficeStatusGetLastVisitForBadgeQuery,
    BackofficeStatusGetLastVisitForBadgeQueryVariables
  >(BackofficeStatusGetLastVisitForBadgeDocument, options)
}
export function useBackofficeStatusGetLastVisitForBadgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BackofficeStatusGetLastVisitForBadgeQuery,
    BackofficeStatusGetLastVisitForBadgeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BackofficeStatusGetLastVisitForBadgeQuery,
    BackofficeStatusGetLastVisitForBadgeQueryVariables
  >(BackofficeStatusGetLastVisitForBadgeDocument, options)
}
export function useBackofficeStatusGetLastVisitForBadgeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BackofficeStatusGetLastVisitForBadgeQuery,
        BackofficeStatusGetLastVisitForBadgeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BackofficeStatusGetLastVisitForBadgeQuery,
    BackofficeStatusGetLastVisitForBadgeQueryVariables
  >(BackofficeStatusGetLastVisitForBadgeDocument, options)
}
export type BackofficeStatusGetLastVisitForBadgeQueryHookResult = ReturnType<
  typeof useBackofficeStatusGetLastVisitForBadgeQuery
>
export type BackofficeStatusGetLastVisitForBadgeLazyQueryHookResult =
  ReturnType<typeof useBackofficeStatusGetLastVisitForBadgeLazyQuery>
export type BackofficeStatusGetLastVisitForBadgeSuspenseQueryHookResult =
  ReturnType<typeof useBackofficeStatusGetLastVisitForBadgeSuspenseQuery>
export type BackofficeStatusGetLastVisitForBadgeQueryResult =
  Apollo.QueryResult<
    BackofficeStatusGetLastVisitForBadgeQuery,
    BackofficeStatusGetLastVisitForBadgeQueryVariables
  >
