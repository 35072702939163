/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type GetVehicleLabelQueryVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type GetVehicleLabelQuery = {
  __typename?: 'Query'
  vehicleByID: {
    __typename?: 'Vehicle'
    id: string
    label: number | null
    fleet: { __typename?: 'Fleet'; name: string } | null
  }
}

export const GetVehicleLabelDocument = gql`
    query getVehicleLabel($vehicleId: ID!) {
  vehicleByID(id: $vehicleId) {
    id
    label
    fleet {
      name
    }
  }
}
    `

/**
 * __useGetVehicleLabelQuery__
 *
 * To run a query within a React component, call `useGetVehicleLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleLabelQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useGetVehicleLabelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVehicleLabelQuery,
    GetVehicleLabelQueryVariables
  > &
    (
      | { variables: GetVehicleLabelQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetVehicleLabelQuery, GetVehicleLabelQueryVariables>(
    GetVehicleLabelDocument,
    options,
  )
}
export function useGetVehicleLabelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVehicleLabelQuery,
    GetVehicleLabelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetVehicleLabelQuery,
    GetVehicleLabelQueryVariables
  >(GetVehicleLabelDocument, options)
}
export function useGetVehicleLabelSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetVehicleLabelQuery,
        GetVehicleLabelQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetVehicleLabelQuery,
    GetVehicleLabelQueryVariables
  >(GetVehicleLabelDocument, options)
}
export type GetVehicleLabelQueryHookResult = ReturnType<
  typeof useGetVehicleLabelQuery
>
export type GetVehicleLabelLazyQueryHookResult = ReturnType<
  typeof useGetVehicleLabelLazyQuery
>
export type GetVehicleLabelSuspenseQueryHookResult = ReturnType<
  typeof useGetVehicleLabelSuspenseQuery
>
export type GetVehicleLabelQueryResult = Apollo.QueryResult<
  GetVehicleLabelQuery,
  GetVehicleLabelQueryVariables
>
