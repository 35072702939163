/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type CreateSingleWorkOrderDialogMutationVariables = Types.Exact<{
  serviceAreaUUID: Types.Scalars['ID']['input']
  status: Types.WorkOrderStatus
  vehicleId: Types.Scalars['ID']['input']
  serviceItem: Types.WorkOrderServiceItem
}>

export type CreateSingleWorkOrderDialogMutation = {
  __typename?: 'Mutation'
  createWorkOrders: boolean
}

export const CreateSingleWorkOrderDialogDocument = gql`
    mutation CreateSingleWorkOrderDialog($serviceAreaUUID: ID!, $status: WorkOrderStatus!, $vehicleId: ID!, $serviceItem: WorkOrderServiceItem!) {
  createWorkOrders(
    input: {serviceAreaUUID: $serviceAreaUUID, status: $status, vehicleIDs: [$vehicleId], serviceItem: $serviceItem}
  )
}
    `
export type CreateSingleWorkOrderDialogMutationFn = Apollo.MutationFunction<
  CreateSingleWorkOrderDialogMutation,
  CreateSingleWorkOrderDialogMutationVariables
>

/**
 * __useCreateSingleWorkOrderDialogMutation__
 *
 * To run a mutation, you first call `useCreateSingleWorkOrderDialogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSingleWorkOrderDialogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSingleWorkOrderDialogMutation, { data, loading, error }] = useCreateSingleWorkOrderDialogMutation({
 *   variables: {
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *      status: // value for 'status'
 *      vehicleId: // value for 'vehicleId'
 *      serviceItem: // value for 'serviceItem'
 *   },
 * });
 */
export function useCreateSingleWorkOrderDialogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSingleWorkOrderDialogMutation,
    CreateSingleWorkOrderDialogMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateSingleWorkOrderDialogMutation,
    CreateSingleWorkOrderDialogMutationVariables
  >(CreateSingleWorkOrderDialogDocument, options)
}
export type CreateSingleWorkOrderDialogMutationHookResult = ReturnType<
  typeof useCreateSingleWorkOrderDialogMutation
>
export type CreateSingleWorkOrderDialogMutationResult =
  Apollo.MutationResult<CreateSingleWorkOrderDialogMutation>
export type CreateSingleWorkOrderDialogMutationOptions =
  Apollo.BaseMutationOptions<
    CreateSingleWorkOrderDialogMutation,
    CreateSingleWorkOrderDialogMutationVariables
  >
