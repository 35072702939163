import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { NavigationItem } from '@backoffice-frontend/patterns'
import { BellIcon } from '@moia-dev/pace-icons'
import { StopNetworkAlertsAreaRoutes } from './StopNetworkAlertsAreaRoutes'

export const StopNetworkAlertsAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={StopNetworkAlertsAreaRoutes.route}
      Icon={BellIcon}
    />
  )
}
