import { useId } from 'react'
import type { FieldRenderProps } from 'react-final-form'
import type { MoiaTextAreaProps } from '../MoiaTextArea'
import { MoiaTextArea } from '../MoiaTextArea'
import { getFinalFormError } from './FormControlErrorHandler'

type FormTextAreaProps<Value> = MoiaTextAreaProps &
  FieldRenderProps<Value> & {
    required?: boolean
  }

/**
 * @deprecated Use react-hook-form instead. See our forms documentation in our pattern-library or our docs.
 */
export function MoiaFormTextArea<Value>({
  input,
  meta,
  required,
  inputProps,
  helperText,
  id,
  ...rest
}: FormTextAreaProps<Value>) {
  const { error, errorHelperText } = getFinalFormError(meta)
  const generatedId = useId()

  return (
    <MoiaTextArea
      id={id ?? generatedId}
      required={required}
      error={error}
      value={input.value}
      onChange={input.onChange}
      helperText={errorHelperText ?? helperText}
      {...rest}
    />
  )
}
