import { getJwtString } from '@backoffice-frontend/common'
import {
  CopyIcon,
  ExpanderTile,
  IconButton,
  Input,
  InputAdornment,
} from '@backoffice-frontend/patterns'
import { jwtDecode } from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const IdTokenTile = () => {
  const { t } = useTranslation()
  const [idToken, setIdToken] = useState('...')
  useEffect(() => {
    getJwtString().then(token => {
      setIdToken(token ?? 'N/A')
    })
  }, [])

  return (
    <ExpanderTile
      dataTestId="idToken"
      loading={false}
      heading={t('authenticatedAppView_cognitoToken_header')}
    >
      <Input
        type="text"
        disabled
        fullWidth
        value={idToken}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={() => {
                console.info(jwtDecode(idToken))
                void navigator.clipboard.writeText(idToken)
              }}
            >
              <CopyIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </ExpanderTile>
  )
}
