import {
  ENV,
  Environment,
  mockServerPort,
} from '@backoffice-frontend/environment'

const CouponStages = {
  [Environment.local]: `http://localhost:${mockServerPort}/api/payment`,
  [Environment.development]:
    'https://payment-coupon-backoffice.api.trip.dev.moia-group.io',
  [Environment.integration]:
    'https://payment-coupon-backoffice.api.trip.int.moia-group.io',
  [Environment.production]:
    'https://payment-coupon-backoffice.api.trip.prd.moia-group.io',
}

export const CouponAreaEndpoints = {}
CouponAreaEndpoints.Host = CouponStages[ENV]
CouponAreaEndpoints.Base = `${CouponAreaEndpoints.Host}`
CouponAreaEndpoints.Batch = {}
CouponAreaEndpoints.Batch.Create = `${CouponAreaEndpoints.Base}/admin/batch`
CouponAreaEndpoints.Batch.List = `${CouponAreaEndpoints.Base}/admin/batch`
CouponAreaEndpoints.Batch.Update = `${CouponAreaEndpoints.Base}/admin/batch/:couponBatchId/activate`
CouponAreaEndpoints.Batch.Deactivate = `${CouponAreaEndpoints.Base}/admin/batch/:couponBatchId/deactivate`
CouponAreaEndpoints.Batch.DownloadCSV = `${CouponAreaEndpoints.Base}/admin/batch/:couponBatchId/coupons/csv`
