import type { ChangeEvent } from 'react'
import { useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useTheme } from '../../basics/theme'
import { useMoiaErrorNotification } from '../../feedback/MoiaNotification/useMoiaErrorNotification'
import type { UploadFileInterface } from './FilePreview'
import { FilePreview } from './FilePreview'
import { FileUploadField } from './FileUploadField'

export type FileUploadProps<T extends UploadFileInterface> = {
  files: T[]
  onAddFiles: (files: File[]) => Promise<void>
  onDelete?: (file: T) => void
  onDownload?: (file: T) => void
  allowedMimeTypes: string[]
  maxNumberFiles?: number
  maxFileSizeBytes?: number
  title?: string
  subtitle?: string
  onPreview?: (file: T) => void
  className?: string
  deleteDialogHeader?: string
  deleteDialogText?: string
  uploading?: boolean
  showUpload?: boolean
  showPreview?: boolean
  required?: boolean
  label?: string
}

/**
 * Renders a file upload component including File Previews.
 */
export const FileUpload = <T extends UploadFileInterface>({
  allowedMimeTypes,
  className,
  deleteDialogText,
  deleteDialogHeader,
  files,
  maxFileSizeBytes,
  maxNumberFiles,
  onAddFiles,
  onDelete,
  onDownload,
  onPreview,
  title,
  subtitle,
  uploading,
  showUpload = true,
  showPreview = true,
  required,
  label,
}: FileUploadProps<T>) => {
  const theme = useTheme()
  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()

  const shouldDisable = useCallback(() => {
    const maxFiles = maxNumberFiles ? files.length >= maxNumberFiles : false
    const loading = files
      .map(it => it.loading)
      .reduce((p, c) => !!c || !!p, false)

    return maxFiles || loading
  }, [files, maxNumberFiles])

  const filesOverstepMaxFiles = (fileNmb: number) => {
    return maxNumberFiles ? fileNmb + files.length > maxNumberFiles : false
  }

  const onFileChangeViaDrop = async (newFiles: File[]) => {
    if (filesOverstepMaxFiles(newFiles.length)) {
      // is max number of files reached overall
      enqueueMoiaErrorNotification(`Max number of files is ${maxNumberFiles}`)
      return
    }
    try {
      await onAddFiles(newFiles)
    } catch (error) {
      enqueueMoiaErrorNotification(error)
    }
  }

  const onFileChangeViaInputEvent = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault()
    const newFiles = Array.from(event.target.files ?? [])

    return await onFileChangeViaDrop(newFiles)
  }

  return (
    <div className={className}>
      {showUpload && (
        <FileUploadField
          onDropChange={onFileChangeViaDrop}
          onInputChange={onFileChangeViaInputEvent}
          disabled={shouldDisable()}
          maxFiles={maxNumberFiles}
          maxFileSizeBytes={maxFileSizeBytes}
          accept={allowedMimeTypes}
          title={title}
          subtitle={subtitle}
          uploading={uploading}
          required={required}
          label={label}
        />
      )}

      {showPreview && (
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: theme.space.Space1,
            marginTop: showUpload ? theme.space.Space5 : 0,
            gap: theme.spacing(1.5),
          }}
        >
          {files.map(file => (
            <FilePreview
              key={
                //NOTE: Any way to make this better? During fileload, non of the file props is defined. After load the loading placeholder won't be removed from.
                uuidv4()
              }
              file={file}
              onDownload={onDownload}
              onDelete={onDelete}
              onPreview={onPreview}
              deleteDialogHeader={deleteDialogHeader}
              deleteDialogText={deleteDialogText}
            />
          ))}
        </div>
      )}
    </div>
  )
}
