import { config } from '@backoffice-frontend/common'
import { LocalizationProvider, deDE, enUS } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { de as deLocale } from 'date-fns/locale/de'
import { enGB as enGbLocale } from 'date-fns/locale/en-GB'
import type * as React from 'react'

const localeMap = (language: string) => {
  switch (language) {
    case 'de':
      return deLocale
    default:
      return enGbLocale
  }
}

const localeText = (language: string) => {
  switch (language) {
    case 'de':
      return deDE.components.MuiLocalizationProvider.defaultProps.localeText
    default:
      return enUS.components.MuiLocalizationProvider.defaultProps.localeText
  }
}

export const PickersUtilsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => (
  <LocalizationProvider
    adapterLocale={localeMap(config.getLanguage())}
    dateAdapter={AdapterDateFns}
    localeText={localeText(config.getLanguage())}
  >
    {children}
  </LocalizationProvider>
)
