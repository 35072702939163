// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import { ServiceSettingsAreaId } from './ServiceSettingsAreaId'
import { ServiceSettingsAreaRoutes } from './ServiceSettingsAreaRoutes'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { ServiceSettingsAreaNavigation } from './ServiceSettingsAreaNavigation'

const LazyServiceSettingsComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./ServiceSettingsArea')),
)

const ServiceSettingsComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyServiceSettingsComponent />
  </SuspenseLoadingWrapper>
)

export const ServiceSettingsArea = {
  id: ServiceSettingsAreaId,
  requiredRight: BoumRight.SERVICE_AREA_R,
  NavigationComponent: ServiceSettingsAreaNavigation,
  route: {
    path: ServiceSettingsAreaRoutes.route,
    component: ServiceSettingsComponent,
  },
}
