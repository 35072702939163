import type { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar'
import { useLocaleText } from '@mui/x-date-pickers/internals'
import { MoiaButton } from '../MoiaButton'

/**
 * @deprecated Use the DatePicker component from pace-web instead.
 */
export const DatePickersActionBar = (props: PickersActionBarProps) => {
  const { onAccept, onClear, onCancel, className, actions } = props
  const localeText = useLocaleText()

  const hideClearAction = actions && !actions.includes('clear')
  const hideCancelAction = actions && !actions.includes('cancel')
  const hideAcceptAction = actions && !actions.includes('accept')

  return (
    <div
      className={className}
      css={theme => ({
        display: 'flex',
        justifyContent: 'space-between',
        padding: `0 ${theme.spacing(2)} ${theme.spacing(3)}`,
      })}
    >
      <div>
        {!hideClearAction && (
          <MoiaButton variant="inline" onClick={onClear} color="secondary">
            {localeText.clearButtonLabel}
          </MoiaButton>
        )}
      </div>
      <div>
        {!hideCancelAction && (
          <MoiaButton
            css={{ marginRight: '8px' }}
            onClick={onCancel}
            variant="inline"
            color="secondary"
          >
            {localeText.cancelButtonLabel}
          </MoiaButton>
        )}
        {!hideAcceptAction && (
          <MoiaButton variant="inline" onClick={onAccept} color="primary">
            {localeText.okButtonLabel}
          </MoiaButton>
        )}
      </div>
    </div>
  )
}
