import { i18n as paceI18n } from '@moia-dev/pace-web'
import i18next from 'i18next'
import { REACT_I18NEXT_LANGUAGE_KEY } from '../const/config'
import { Language } from '../i18n/i18n'

export const setLocale = (lang: Language | 'cimode'): void => {
  if (lang === 'cimode') {
    i18next.changeLanguage('cimode')
    window.localStorage.setItem(REACT_I18NEXT_LANGUAGE_KEY, 'cimode')
    return
  }

  if (!Language[lang]) {
    throw new Error(
      `Unknown locale, existing: ${Object.keys(Language).join(', ')}`,
    )
  }
  console.info('setLocale', lang)
  void paceI18n.changeLanguage(lang)
  void i18next.changeLanguage(lang)
}
