import { getEnvironment } from '@backoffice-frontend/environment'
import {
  Body2,
  ContentInfo,
  H5,
  MoiaButton,
  MoiaGrid,
  MoiaViewBox,
  Subtitle1,
} from '@backoffice-frontend/patterns'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { AreaComponentsAreaId } from '../AreaComponentsAreaId'

const contentBlock = {
  whiteSpace: 'pre-wrap',
  textAlign: 'start',
  px: 1,
  py: 2,
} as const

const getBackofficeFrontendEmail = (): string =>
  `backoffice-dev+${getEnvironment()}-frontend-error@moia.io`

export const ErrorBoundaryView = ({
  error,
  areaId,
}: {
  error: Error
  areaId: string
}) => {
  const { t } = useTranslation(AreaComponentsAreaId)
  const location = useLocation()

  const getSerializedContext = () =>
    JSON.stringify({ areaId, location, history }, undefined, 4)

  const createEmailLink = () => {
    const contextBody = `${t('Context:')}\n${getSerializedContext()}\n`

    const emailBody = encodeURI(
      `${t('Message:')}\n${error?.message}\n\n` +
        `${t('StackTrace:')}\n${error?.stack}\n\n` +
        `${contextBody}`,
    )

    const emailLink = `mailto:${getBackofficeFrontendEmail()}?&subject=${t(
      'An error occurred, please contact us with a detailed description of how the error occurred. This is what will be sent:',
    )}&body=${emailBody}`

    return emailLink
  }

  const context = getSerializedContext()

  return (
    <MoiaViewBox>
      <MoiaGrid>
        <ContentInfo
          title={t(
            'An error occurred, please contact us with a detailed description of how the error occurred. This is what will be sent:',
          )}
          variant="criticalAlert"
        />
        <H5>{t('Error Details')}</H5>
        <Subtitle1>{t('Message:')}</Subtitle1>
        <Body2 css={contentBlock}>{error?.message}</Body2>
        <Subtitle1>{t('StackTrace:')}</Subtitle1>
        <Body2 css={contentBlock}>{error?.stack?.trim()}</Body2>
        {context && (
          <>
            <Subtitle1>{t('Context:')}</Subtitle1>
            <Body2 css={contentBlock}>{context}</Body2>
          </>
        )}

        <MoiaButton color="primary" href={createEmailLink()} target="_blank">
          {t('Send via Email')}
        </MoiaButton>
      </MoiaGrid>
    </MoiaViewBox>
  )
}
