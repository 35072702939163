import type { Environment } from '@backoffice-frontend/environment'
import { chartColors } from './chartColors'

export const backroundEnv: {
  [key in Environment]: { bright: string; dark: string }
} = {
  test: {
    bright: chartColors.green[5],
    dark: chartColors.green[1],
  },
  local: {
    bright: chartColors.green[5],
    dark: chartColors.green[1],
  },
  development: {
    bright: chartColors.green[5],
    dark: chartColors.green[1],
  },
  integration: {
    bright: chartColors.orange[6],
    dark: chartColors.orange[3],
  },
  production: {
    bright: chartColors.red[4],
    dark: chartColors.red[1],
  },
}
