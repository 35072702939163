import { useToggle } from '@backoffice-frontend/hooks'
import {
  Body2,
  MoiaButton,
  MoiaDialog,
  MoiaDialogContent,
  MoiaDialogFormFooter,
} from '@backoffice-frontend/patterns'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { BackofficeStatusDeleteButton } from './BackofficeStatusDeleteButton'
import type { BackofficeStatusFormInput } from './BackofficeStatusForm'
import { BackofficeStatusForm } from './BackofficeStatusForm'

type BackofficeStatusDialogContentProps = {
  initialValues: Partial<BackofficeStatusFormInput>
  onSubmit: (input: BackofficeStatusFormInput) => void
  header: string
  loading: boolean
  openDialog: boolean
  id: string | undefined
  toggleOpenDialog: VoidFunction
  type: 'new' | 'edit'
}

export const BackofficeStatusDialogContent = ({
  initialValues,
  onSubmit,
  header,
  loading,
  openDialog,
  id,
  toggleOpenDialog,
  type = 'new',
}: BackofficeStatusDialogContentProps) => {
  const { t } = useTranslation()
  const [openDiscardDialog, toggleOpenDiscardDialog] = useToggle()
  const [openSubmitDialog, toggleOpenSubmitDialog] = useToggle()

  const handleClose = (pristine: boolean) => {
    pristine ? toggleOpenDialog() : toggleOpenDiscardDialog()
  }

  return (
    <>
      <Form<BackofficeStatusFormInput>
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values, submitting, pristine, invalid }) => (
          <>
            <MoiaDialog
              maxWidth="xl"
              open={openDialog}
              onClose={() => handleClose(pristine)}
              header={header}
            >
              <MoiaDialogContent>
                <BackofficeStatusForm onSubmit={handleSubmit} values={values} />
              </MoiaDialogContent>
              <MoiaDialogFormFooter
                trailing={
                  <>
                    <MoiaButton
                      onClick={() => handleClose(pristine)}
                      disabled={submitting}
                    >
                      {t('common_cancel_button')}
                    </MoiaButton>
                    <MoiaButton
                      onClick={
                        type === 'edit' ? handleSubmit : toggleOpenSubmitDialog
                      }
                      submitting={submitting}
                      disabled={loading || pristine || invalid}
                      color="primary"
                    >
                      {t('common_confirm_button')}
                    </MoiaButton>
                  </>
                }
                leading={
                  id && (
                    <BackofficeStatusDeleteButton
                      onDelete={toggleOpenDialog}
                      id={id}
                    />
                  )
                }
              />
            </MoiaDialog>
            <MoiaDialog
              maxWidth="lg"
              open={openSubmitDialog}
              header={t('status_dialog_submitStatus_header')}
            >
              <MoiaDialogContent>
                <Body2 paragraph>{t('status_dialog_submitStatus')}</Body2>
              </MoiaDialogContent>
              <MoiaDialogFormFooter
                trailing={
                  <>
                    <MoiaButton onClick={toggleOpenSubmitDialog}>
                      {t('common_cancel_button')}
                    </MoiaButton>
                    <MoiaButton
                      onClick={() => {
                        toggleOpenSubmitDialog()
                        handleSubmit()
                      }}
                      color="primary"
                    >
                      {t('common_submit_button')}
                    </MoiaButton>
                  </>
                }
              />
            </MoiaDialog>
          </>
        )}
      </Form>
      <MoiaDialog
        maxWidth="lg"
        open={openDiscardDialog}
        header={t('status_dialog_discardStatusDraft_header')}
      >
        <MoiaDialogContent>
          <Body2 paragraph>{t('status_dialog_discardStatusDraft')}</Body2>
        </MoiaDialogContent>
        <MoiaDialogFormFooter
          trailing={
            <>
              <MoiaButton onClick={toggleOpenDiscardDialog}>
                {t('common_back_button')}
              </MoiaButton>
              <MoiaButton
                onClick={() => {
                  toggleOpenDiscardDialog()
                  toggleOpenDialog()
                }}
                color="primary"
              >
                {t('status_dialog_discardStatusDraft_button')}
              </MoiaButton>
            </>
          }
        />
      </MoiaDialog>
    </>
  )
}
