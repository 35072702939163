import { PricingAreaRoutes } from '@backoffice-frontend/common'
import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { CurrencyCircleEuroIcon } from '@moia-dev/pace-icons'

export const PricingAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={PricingAreaRoutes.route}
      Icon={CurrencyCircleEuroIcon}
    />
  )
}
