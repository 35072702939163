import { MoiaFormTextField } from '@backoffice-frontend/patterns'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { UpdatesFormFieldWrapper } from './UpdatesFormFieldWrapper'

export const BackofficeUpdatesVideosForm = () => {
  const { t } = useTranslation()

  return (
    <UpdatesFormFieldWrapper
      title={t('updates_dialog_updateVideos_header')}
      tooltipText={{
        title: t('updates_tooltip_updateVideosTitle'),
        paragraphs: [t('updates_tooltip_updateVideosDescription')],
      }}
    >
      <Field
        component={MoiaFormTextField}
        name="videoEn"
        id="videoEn"
        label={t('updates_dialog_updateVideosEnglish_label')}
      />
      <Field
        component={MoiaFormTextField}
        name="videoDe"
        id="videoDe"
        label={t('updates_dialog_updateVideosGerman_label')}
      />
    </UpdatesFormFieldWrapper>
  )
}
