/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { LastBoxStateFragmentDoc } from '../../../../../vehicle-common/src/lib/graphql/fragment/lastBoxState.hook'
const defaultOptions = {} as const
export type VehicleTelematicsSubscriptionVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type VehicleTelematicsSubscription = {
  __typename?: 'Subscription'
  vehicleTelematics: {
    __typename?: 'TelematicBoxState'
    timestamp: string | null
    mileage: number | null
    ignition: Types.IgnitionState | null
    centralLock: Types.CentralLockState | null
    doors: Types.DoorState | null
    keyFob: Types.KeyFobState | null
    windows: Types.WindowState | null
    locationTimestamp: string | null
    highVoltageBatteryLevel: number | null
    highVoltageBatteryHealth: number | null
    powerPlug: Types.PowerplugState | null
    batteryVoltage: number | null
    cruisingRange: number | null
    interiorTemperature: number | null
    targetTemperature: number | null
    climateControlActive: boolean | null
    connectedState: Types.ConnectedState | null
    chargingMode: Types.ChargingMode | null
    batteryEnergy: number | null
    remainingCruisingRange: number | null
    consumedEnergy: number | null
    speed: number | null
    sdsMode: Types.SdsMode | null
    location: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    } | null
  }
}

export const VehicleTelematicsDocument = gql`
    subscription vehicleTelematics($vehicleId: ID!) {
  vehicleTelematics(vehicleId: $vehicleId) {
    ...LastBoxState
  }
}
    ${LastBoxStateFragmentDoc}`

/**
 * __useVehicleTelematicsSubscription__
 *
 * To run a query within a React component, call `useVehicleTelematicsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVehicleTelematicsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleTelematicsSubscription({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useVehicleTelematicsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    VehicleTelematicsSubscription,
    VehicleTelematicsSubscriptionVariables
  > &
    (
      | { variables: VehicleTelematicsSubscriptionVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    VehicleTelematicsSubscription,
    VehicleTelematicsSubscriptionVariables
  >(VehicleTelematicsDocument, options)
}
export type VehicleTelematicsSubscriptionHookResult = ReturnType<
  typeof useVehicleTelematicsSubscription
>
export type VehicleTelematicsSubscriptionResult =
  Apollo.SubscriptionResult<VehicleTelematicsSubscription>
