// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import {
  VehicleAreaDataTestIds,
  VehicleAreaId,
  VehicleAreaRoutes,
} from '@backoffice-frontend/common'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { VehicleAreaNavigation } from './VehicleAreaNavigation'

const LazyVehicleComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./VehicleArea')),
)

const VehicleComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyVehicleComponent />
  </SuspenseLoadingWrapper>
)

export const VehicleArea = {
  id: VehicleAreaId,
  dataTestIds: VehicleAreaDataTestIds,
  requiredRight: BoumRight.AREA_VEHICLE,
  NavigationComponent: VehicleAreaNavigation,
  route: {
    path: VehicleAreaRoutes.route,
    component: VehicleComponent,
  },
}
