import classNames from 'clsx'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { InformationIcon } from '../basics/Icons'
import { Body2 } from '../basics/Typography'
import { UnstyledButton } from '../inputs/MoiaButton'

export type MoiaToastMessageProps = {
  dataTestId?: string
  message?: ReactNode
  className?: string
  icon?: ReactNode
  severity: 'error' | 'info' | 'warning' | 'success'
  onActionClick?: () => void
}

// the custom svgs are required to swap path fill color depending on light/dark mode
const IconStyles = {
  height: '18px',
  width: '18px',
  fontSize: 'inherit',
} as const
const ToastIcon = ({
  severity,
}: {
  severity: MoiaToastMessageProps['severity']
}) => {
  switch (severity) {
    case 'error':
      return (
        <svg
          viewBox="0 0 20 20"
          css={{ ...IconStyles }}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            css={{
              fill: 'var(--color-critical-default)',
            }}
            d="M9.99998 0.833328C4.93697 0.833328 0.833313 4.93698 0.833313 9.99999C0.833313 15.063 4.93697 19.1667 9.99998 19.1667C15.063 19.1667 19.1666 15.063 19.1666 9.99999C19.1666 4.93698 15.063 0.833328 9.99998 0.833328Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99998 10.4167C10.4602 10.4167 10.8333 10.0436 10.8333 9.58334V6.25001C10.8333 5.78977 10.4602 5.41667 9.99998 5.41667C9.53975 5.41667 9.16665 5.78977 9.16665 6.25001V9.58334C9.16665 10.0436 9.53975 10.4167 9.99998 10.4167Z"
            css={{
              fill: 'var(--color-content-on-contrast-critical-success-default-alternative)',
            }}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.95832 12.9137C8.95576 13.4934 9.42609 13.9583 9.99915 13.9583C10.5731 13.9583 11.0417 13.4941 11.0417 12.9167C11.0417 12.3414 10.5752 11.875 9.99999 11.875C9.42576 11.875 8.95995 12.3398 8.95832 12.9137Z"
            css={{
              fill: 'var(--color-content-on-contrast-critical-success-default-alternative)',
            }}
          />
        </svg>
      )
    case 'info':
      return (
        <InformationIcon
          css={{
            ...IconStyles,
            color: 'var(--color-content-on-contrast-critical-success-default)',
          }}
        />
      )
    case 'warning':
      return (
        <svg
          viewBox="0 0 19 17"
          fill="none"
          css={{ ...IconStyles }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.18671 1.18356L0.583163 12.4096C-0.358292 14.0101 0.79568 16.0277 2.65252 16.0277H15.8596C17.7165 16.0277 18.8704 14.0101 17.929 12.4096L11.3254 1.18356C10.3971 -0.394519 8.11499 -0.394521 7.18671 1.18356Z"
            fill="var(--color-warning-default)"
          />
          <path
            d="M8.99775 3.72807C8.41783 3.72807 7.94336 4.20255 7.94336 4.78246V10.0544C7.94336 10.6343 8.41783 11.1088 8.99775 11.1088C9.57766 11.1088 10.0521 10.6343 10.0521 10.0544V4.78246C10.0521 4.20255 9.57766 3.72807 8.99775 3.72807Z"
            fill="var(--color-content-on-contrast-critical-success-default-alternative)"
          />
          <path
            d="M8.99775 14.272C9.58007 14.272 10.0521 13.7999 10.0521 13.2176C10.0521 12.6352 9.58007 12.1632 8.99775 12.1632C8.41542 12.1632 7.94336 12.6352 7.94336 13.2176C7.94336 13.7999 8.41542 14.272 8.99775 14.272Z"
            fill="var(--color-content-on-contrast-critical-success-default-alternative)"
          />
        </svg>
      )
    case 'success':
      return (
        <svg
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          css={{ ...IconStyles, fill: 'var(--color-success-default)' }}
        >
          <path d="M9.99998 0.833336C4.93697 0.833336 0.833313 4.93699 0.833313 10C0.833313 15.063 4.93697 19.1667 9.99998 19.1667C15.063 19.1667 19.1666 15.063 19.1666 10C19.1666 4.93699 15.063 0.833336 9.99998 0.833336Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            css={{
              fill: 'var(--color-content-on-contrast-critical-success-default-alternative)',
            }}
            d="M14.334 6.90546C14.6623 7.22795 14.6671 7.75557 14.3446 8.08392L8.6154 13.9173C8.44243 14.0934 8.20028 14.1837 7.95423 14.164C7.70817 14.1443 7.48352 14.0164 7.34084 13.815L5.57001 11.315C5.30398 10.9394 5.39278 10.4193 5.76835 10.1533C6.14391 9.88729 6.66402 9.97609 6.93005 10.3517L8.12475 12.0383L13.1555 6.91607C13.478 6.58772 14.0056 6.58297 14.334 6.90546Z"
          />
        </svg>
      )
    default:
      throw new Error('Unknown toast message type')
  }
}

/**
 * @deprecated Use the Toast component from pace-web instead.
 */
export const MoiaToastMessage = ({
  message,
  onActionClick,
  severity,
  icon,
  className,
}: MoiaToastMessageProps) => {
  const [open, toggleOpen] = useState(true)
  return (
    <>
      {open && (
        <div
          role="alert"
          className={classNames(severity, className)}
          css={theme => ({
            display: 'grid',
            position: 'relative',
            alignItems: 'center',
            background: 'var(--color-surface-contrast-default)',
            color: 'var(--color-content-on-contrast-critical-success-default)',
            padding: theme.spacing(1.5),
            paddingRight: '44px',
            borderRadius: '10px',
            gap: theme.spacing(1),
            gridTemplateColumns: 'min-content 1fr min-content',
          })}
        >
          {icon ? icon : <ToastIcon severity={severity} />}
          <Body2
            css={theme => ({
              color:
                theme.semantic.ColorContentOnContrastCriticalSuccessDefault,
            })}
          >
            {message}
          </Body2>
          <UnstyledButton
            css={theme => ({
              position: 'absolute',
              height: '100%',
              right: 0,
              padding: theme.spacing(1.5),
              borderTopRightRadius: '10px',
              borderBottomRightRadius: '10px',
              '&:hover': {
                background: 'var(--color-surface-contrast-pressed)',
              },
            })}
            onClick={() => {
              if (onActionClick) {
                onActionClick()
              } else {
                toggleOpen(false)
              }
            }}
          >
            OK
          </UnstyledButton>
        </div>
      )}
    </>
  )
}
