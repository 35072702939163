import clsx from 'clsx'
import type {
  ButtonHTMLAttributes,
  InputHTMLAttributes,
  ReactNode,
} from 'react'
import { forwardRef, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '../feedback/CircularProgress'

export type MoiaButtonProps = {
  //@deprecated use color critical
  danger?: boolean
  submitting?: boolean
  //@deprecated use a Link
  to?: string
  target?: string
  spinnerSize?: number
  variant?: 'inline'
  startIcon?: ReactNode
  endIcon?: ReactNode
  //@deprecated use a Link
  href?: string
  color?: 'critical' | 'primary' | 'secondary'
} & UnstyledButtonProps

export type UnstyledButtonProps = ButtonHTMLAttributes<HTMLButtonElement>
export const UnstyledButton = forwardRef<
  HTMLButtonElement,
  UnstyledButtonProps
>((props, ref) => {
  return (
    <button
      ref={ref}
      css={{
        margin: 0,
        padding: 0,
        background: 'none',
        fontSize: 'inherit',
        color: 'inherit',
        transform: 'none',
        border: 'none',
        cursor: 'pointer',
        '&:disabled': {
          cursor: 'not-allowed',
        },
      }}
      {...props}
    />
  )
})

export const ButtonHoverStyles = {
  '&.critical': {
    borderColor: 'var(--color-critical-default)',
    background: 'var(--color-critical-default)',
    color:
      'var(--color-content-on-contrast-critical-success-default-alternative)',
    '&:hover': {
      borderColor: 'var(--color-critical-hovered)',
      background: 'var(--color-critical-hovered)',
    },
    '&:active': {
      borderColor: 'var(--color-critical-pressed)',
      background: 'var(--color-critical-pressed)',
    },
    '&:disabled': {
      borderColor: 'var(--color-critical-disabled)',
      background: 'var(--color-critical-disabled)',
    },
  },
  '&.secondary': {
    borderColor: 'var(--color-action-secondary-default)',
    background: 'var(--color-action-secondary-default)',
    color: 'var(--color-content-on-action-secondary-default)',
    '&:hover': {
      borderColor: 'var(--color-action-secondary-hovered)',
      background: 'var(--color-action-secondary-hovered)',
    },
    '&:active': {
      borderColor: 'var(--color-action-secondary-pressed)',
      background: 'var(--color-action-secondary-pressed)',
    },
    '&:disabled': {
      borderColor: 'var(--color-action-secondary-disabled-alternative)',
      background: 'var(--color-action-secondary-disabled-alternative)',
      color: 'var(--color-content-on-action-secondary-disabled)',
    },
  },
  '&.primary': {
    borderColor: 'var(--color-action-primary-default)',
    background: 'var(--color-action-primary-default)',
    color: 'var(--color-content-on-action-primary-default)',
    '&:hover': {
      borderColor: 'var(--color-action-primary-default-hovered)',
      background: 'var(--color-action-primary-default-hovered)',
    },
    '&:active': {
      borderColor: 'var(--color-action-primary-default-pressed)',
      background: 'var(--color-action-primary-default-pressed)',
    },
    '&:disabled': {
      borderColor: 'var(--color-action-primary-default-disabled)',
      background: 'var(--color-action-primary-default-disabled)',
      color: 'var(--color-content-on-action-primary-disabled)',
    },
  },
}

/**
 * @deprecated Use Button from "@moia-dev/pace-web" instead.
 */
export const MoiaButton = forwardRef<HTMLButtonElement, MoiaButtonProps>(
  (
    {
      danger,
      to,
      children,
      className,
      variant,
      disabled,
      spinnerSize,
      href,
      startIcon,
      endIcon,
      onClick,
      target,
      submitting = false,
      color = 'default',
      type = 'button',
      ...props
    },
    ref,
  ) => {
    const dangerColor = danger ? 'critical' : color
    const navigate = useNavigate()
    return (
      <UnstyledButton
        css={{
          position: 'relative',
          border: 'solid 1px',
          borderRadius: '8px',
          fontWeight: '700',
          fontSize: '14px',
          lineHeight: '17px',
          '&.inline': {
            borderRadius: '4px',
            '&.critical': {
              borderColor: 'transparent',
              background: 'transparent',
              color: 'var(--color-critical-default)',
              '&:hover': {
                color:
                  'var(--color-content-on-contrast-critical-success-default)',
              },
              '&:active': {
                color:
                  'var(--color-content-on-contrast-critical-success-default)',
              },
              '&:disabled': {
                color: 'var(--color-critical-disabled)',
                background: 'var(--color-surface-default)',
                borderColor: 'transparent',
              },
            },
            '&.primary': {
              borderColor: 'transparent',
              background: 'transparent',
              color: 'var(--color-action-primary-alternative)',
              '&:hover': {
                color: 'var(--color-content-on-action-primary-default)',
              },
              '&:active': {
                color: 'var(--color-content-on-action-primary-default)',
              },
              '&:disabled': {
                color: 'var(--color-action-primary-alternative-disabled)',
                background: 'var(--color-surface-default)',
                borderColor: 'transparent',
              },
            },
            '&.secondary': {
              borderColor: 'transparent',
              background: 'transparent',
              color: 'var(--color-action-secondary-default)',
              '&:hover': {
                borderColor: 'transparent',
                background: 'var(--color-surface-hovered)',
              },
              '&:active': {
                borderColor: 'transparent',
                background: 'var(--color-surface-pressed)',
              },
              '&:disabled': {
                color: 'var(--color-action-secondary-disabled)',
                background: 'var(--color-surface-default)',
                borderColor: 'transparent',
              },
            },
            '&.default': {
              borderColor: 'transparent',
              background: 'var(--color-surface-default)',
              color: 'var(--color-action-secondary-default)',
              '&:hover': {
                background: 'var(--color-surface-hovered)',
              },
              '&:active': {
                background: 'var(--color-surface-pressed)',
              },
              '&:disabled': {
                color: 'var(--color-action-secondary-disabled)',
                background: 'var(--color-surface-default)',
                borderColor: 'transparent',
              },
            },
          },
          '&.default': {
            borderColor: 'var(--color-border-on-action-secondary-default)',
            background: 'var(--color-surface-default)',
            color: 'var(--color-action-secondary-default)',
            '&:hover': {
              background: 'var(--color-surface-hovered)',
            },
            '&:active': {
              background: 'var(--color-surface-pressed)',
            },
            '&:disabled': {
              color: 'var(--color-action-secondary-disabled)',
              background: 'var(--color-surface-default)',
            },
          },
          ...ButtonHoverStyles,
        }}
        className={clsx(className, dangerColor, variant)}
        ref={ref}
        type={type}
        disabled={submitting || disabled}
        //@todo remove once we don't use buttons for links
        onClick={e => {
          onClick?.(e)
          if (target) {
            window.open(to ?? href)
          } else if (to) {
            navigate(to)
          } else if (href) {
            location.href = href
          }
        }}
        {...props}
      >
        {submitting && (
          <CircularProgress
            size={variant === 'inline' ? 14 : 18}
            css={{
              opacity: 1,
              color: 'inherit',
              position: 'absolute',
              margin: 'auto',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
        <span
          css={theme => ({
            alignItems: 'center',
            justifyItems: 'flex-start',
            display: 'inline-grid',
            gridAutoFlow: 'column',
            // no gap if we only have icons
            gap: children ? theme.spacing(1) : 0,
            padding: theme.spacing(1),
            gridTemplateColumns: `${
              startIcon ? 'min-content' : ''
            } max-content ${endIcon ? 'min-content' : ''}`,
            '& svg': {
              height: '18px',
              width: '18px',
            },
            ...(variant === 'inline' && {
              padding: theme.spacing(0.5, 1),
              // remove once all icons have the same height
              minHeight: '27px',
              '& svg': {
                height: '14px',
                width: '14px',
              },
            }),
            ...(submitting && {
              opacity: 0,
            }),
          })}
        >
          {startIcon} {children} {endIcon}
        </span>
      </UnstyledButton>
    )
  },
)

export type MoiaUploadButtonProps = MoiaButtonProps & {
  inputFileProps: InputHTMLAttributes<HTMLInputElement>
  // needed to simulate upload for unit tests
  dataTestId?: string
}
export const MoiaUploadButton = ({
  onClick,
  inputFileProps,
  dataTestId,
  ...props
}: MoiaUploadButtonProps) => {
  const refUpload = useRef<HTMLInputElement | null>(null)
  return (
    <>
      <input
        aria-hidden
        css={{ display: 'none' }}
        ref={refUpload}
        type="file"
        data-testid={dataTestId}
        {...inputFileProps}
      />
      <MoiaButton
        onClick={e => {
          if (refUpload?.current) {
            refUpload.current.click()
            onClick?.(e)
          }
        }}
        {...props}
      />
    </>
  )
}

export const CreateButton = (props: MoiaButtonProps) => {
  const { t } = useTranslation()
  return <MoiaButton {...props}>{t('common_create_button')}</MoiaButton>
}

export const CancelButton = (props: MoiaButtonProps) => {
  const { t } = useTranslation()
  return <MoiaButton {...props}>{t('common_cancel_button')}</MoiaButton>
}

export const NoButton = (props: MoiaButtonProps) => {
  const { t } = useTranslation()
  return <MoiaButton {...props}>{t('common_no_label')}</MoiaButton>
}

export const YesButton = (props: MoiaButtonProps) => {
  const { t } = useTranslation()
  return <MoiaButton {...props}>{t('common_yes_label')}</MoiaButton>
}

export const CloseButton = (props: MoiaButtonProps) => {
  const { t } = useTranslation()
  return <MoiaButton {...props}>{t('patterns_close_button')}</MoiaButton>
}
