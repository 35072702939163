// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import {
  ServiceHoursAreaDataTestIds,
  ServiceHoursAreaId,
  ServiceHoursAreaRoutes,
} from '@backoffice-frontend/common'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { ServiceHoursAreaNavigation } from './ServiceHoursAreaNavigation'

const LazyServiceHoursComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./ServiceHoursArea')),
)

const ServiceHoursComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyServiceHoursComponent />
  </SuspenseLoadingWrapper>
)

export const ServiceHoursArea = {
  id: ServiceHoursAreaId,
  dataTestIds: ServiceHoursAreaDataTestIds,
  requiredRight: BoumRight.AREA_SERVICE_HOURS,
  NavigationComponent: ServiceHoursAreaNavigation,
  route: {
    path: ServiceHoursAreaRoutes.route,
    component: ServiceHoursComponent,
  },
}
