import type {
  ApolloError,
  MutationFunction,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client'
import { useMoiaErrorNotification } from '@backoffice-frontend/patterns'
import { useCallback } from 'react'

const omitTypename = <T>(key: string, value: T): T | undefined =>
  key === '__typename' ? undefined : value
type UseMutationFn<TData, TVariables> = (
  options?: MutationHookOptions<TData, TVariables>,
) => MutationTuple<TData, TVariables>

export const isError = (error: unknown): error is Error => {
  return error instanceof Error
}

const createdSanitizedMutationFn =
  <TData, TVariables>(
    mutationFunction: MutationFunction<TData, TVariables>,
  ): MutationFunction<TData, TVariables> =>
  mutationFunctionOptions => {
    // NOTE: workaround to remove the `__typename` key in the object
    //  https://github.com/apollographql/apollo-feature-requests/issues/6
    const newVariables = mutationFunctionOptions?.variables
      ? JSON.parse(
          JSON.stringify(mutationFunctionOptions.variables),
          omitTypename,
        )
      : undefined

    const sanitizedMutationFunctionOptions = {
      ...mutationFunctionOptions,
      ...(newVariables ? { variables: newVariables } : {}),
    }

    return mutationFunction(sanitizedMutationFunctionOptions)
  }

/**
 * Adds error handling to a graphql-code-generator TypeScript React Apollo Hook mutation
 * @param useMutationFn reference to the actual exported mutation function
 * @param options Apollo React Hook options as usual
 */
export const useErrorHandlingMutation = <TData, TVariables>(
  useMutationFn: UseMutationFn<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>,
  getErrorTranslations?: (error: ApolloError) => string,
): MutationTuple<TData, TVariables> => {
  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()

  const defaultErrorHandler = (error: ApolloError) => {
    enqueueMoiaErrorNotification(
      getErrorTranslations ? getErrorTranslations(error) : error,
    )
    return error
  }

  const [mutationFunction, mutationResult] = useMutationFn({
    ...options,
    onError: options?.onError ?? defaultErrorHandler,
  })

  const sanitizedMutationFunction = useCallback<
    MutationFunction<TData, TVariables>
  >(
    opts => createdSanitizedMutationFn(mutationFunction)(opts),
    [mutationFunction],
  )

  return [sanitizedMutationFunction, mutationResult]
}
