import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  useTheme,
} from '@backoffice-frontend/patterns'

type NavigationDrawerFooterProps = {
  onClick: (event: React.MouseEvent) => void
  open: boolean
}

export const NavigationDrawerFooter = ({
  onClick,
  open,
}: NavigationDrawerFooterProps) => {
  const theme = useTheme()
  return (
    <div
      css={{
        height: '56px',
        display: 'flex',
        alignItems: 'center',
        ...(open
          ? {
              justifyContent: 'flex-end',
            }
          : {
              justifyContent: 'center',
              padding: '12px',
            }),
      }}
    >
      <Button
        css={{
          background: theme.semantic.ColorControlsDefault,
          borderRadius: '100%',
          boxShadow: 'none',
          color: theme.semantic.ColorContentDefault,
          height: '36px',
          minWidth: '36px',
          padding: 0,
          width: '36px',
          ...(open && { right: theme.spacing(2) }),
          '&:hover': {
            background: theme.semantic.ColorControlsPressed,
          },
        }}
        onClick={onClick}
        size="small"
      >
        {open ? <ArrowLeftIcon /> : <ArrowRightIcon />}
      </Button>
    </div>
  )
}
