import type { RioComponent } from '@backoffice-frontend/graphql'
import { RioComponentType } from '@backoffice-frontend/graphql'
import { useEffect, useState } from 'react'
import type { RioTemplateFragment } from '../graphql'

export type UseComponentContext = {
  nextVisualComponent: RioComponent | undefined
  previousVisualComponent: RioComponent | undefined
  parents: RioComponent[]
  children: RioComponent[]
  index: number
  section: RioTemplateFragment['editSections'][0]
  template: RioTemplateFragment
}

const HIDDEN_COMPONENT_TYPES = [
  RioComponentType.VehicleUuid,
  RioComponentType.FleetId,
  RioComponentType.EmployeeUuid,
]

export const extractParents = (components: RioComponent[], childId: string) =>
  components.filter(component =>
    component.dependingComponentIds?.includes(childId),
  )

export const extractChildren = (
  components: RioComponent[],
  dependentComponentIds: string[] | undefined | null,
) =>
  components.filter(component =>
    dependentComponentIds?.includes(component.componentId),
  )

// get next non-hidden component
export const getNextVisualComponent = (
  index: number,
  section: RioTemplateFragment['editSections'][0],
  template: RioTemplateFragment,
) => {
  let nextComponent
  for (let i = index + 1; i < section.componentIds.length; i++) {
    nextComponent = template.components.find(
      c => c.componentId == section.componentIds[i],
    )
    if (
      nextComponent &&
      !HIDDEN_COMPONENT_TYPES.includes(nextComponent.componentType)
    ) {
      break
    }
  }
  return nextComponent
}

// get previous non-hidden component
export const getPreviousVisualComponent = (
  index: number,
  section: RioTemplateFragment['editSections'][0],
  template: RioTemplateFragment,
) => {
  let prevComponent
  for (let i = index - 1; i >= 0; i--) {
    prevComponent = template.components.find(
      c => c.componentId == section.componentIds[i],
    )
    if (
      prevComponent &&
      !HIDDEN_COMPONENT_TYPES.includes(prevComponent.componentType)
    ) {
      break
    }
  }
  return prevComponent
}

export const useComponentContext = (
  index: number,
  template: RioTemplateFragment,
  section: RioTemplateFragment['editSections'][0],
): UseComponentContext => {
  const [nextVisualComponent, setNextVisualComponent] = useState<
    RioComponent | undefined
  >(getNextVisualComponent(index, section, template))
  const [previousVisualComponent, setPreviousVisualComponent] = useState<
    RioComponent | undefined
  >(getPreviousVisualComponent(index, section, template))
  const [parents, setParents] = useState<RioComponent[]>(
    extractParents(template.components, section.componentIds[index]),
  )
  const [children, setChildren] = useState<RioComponent[]>(
    extractChildren(
      template.components,
      template.components.find(
        c => c.componentId == section.componentIds[index],
      )?.dependingComponentIds ?? [],
    ),
  )

  useEffect(() => {
    setNextVisualComponent(getNextVisualComponent(index, section, template))
    setPreviousVisualComponent(
      getPreviousVisualComponent(index, section, template),
    )
    setParents(extractParents(template.components, section.componentIds[index]))
    setChildren(
      extractChildren(
        template.components,
        template.components.find(
          c => c.componentId == section.componentIds[index],
        )?.dependingComponentIds,
      ),
    )
  }, [index, template, section])

  return {
    nextVisualComponent,
    previousVisualComponent,
    parents,
    children,
    index,
    section,
    template,
  }
}
