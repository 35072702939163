import {
  CouponAreaDataTestIds,
  CouponAreaRoutes,
} from '@backoffice-frontend/common'
import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { CouponIcon } from '@moia-dev/pace-icons'

export const CouponAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={CouponAreaRoutes.route}
      Icon={CouponIcon}
      dataTestId={CouponAreaDataTestIds.Navigation}
    />
  )
}
