import type { BoundingBox } from '@backoffice-frontend/common'
import type {
  EndpointArgument,
  ParseArgument,
  ProviderOptions,
  ProviderParams,
  SearchArgument,
  SearchResult,
} from 'leaflet-geosearch/dist/providers/provider'
import type { HereMapsSearch, HereMapsSearchResult } from '../../here-maps'
import { getHereMapsSearchApi } from '../../here-maps'
import { AbstractProvider } from './AbstractProvider'

type HereMapsGeoSearchProviderOptions = {
  rectangularBounds: BoundingBox
} & ProviderOptions

export class HereMapsGeoSearchProvider extends AbstractProvider<
  HereMapsSearch,
  HereMapsSearchResult
> {
  options: HereMapsGeoSearchProviderOptions

  constructor(options: HereMapsGeoSearchProviderOptions) {
    super(options)
    this.options = options
  }

  getUrl(): string {
    return ''
  }

  getParamString(params: ProviderParams): string {
    return Object.keys(params)
      .map(
        key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
      )
      .join('&')
  }

  endpoint({ query }: EndpointArgument): string {
    const searchQuery =
      typeof query === 'string' ? query : this.getParamString(query)
    return getHereMapsSearchApi(searchQuery, this.options.rectangularBounds)
  }

  parse(
    response: ParseArgument<HereMapsSearch>,
  ): SearchResult<HereMapsSearchResult>[] {
    return response.data.results
      .filter(res => res.position !== undefined)
      .filter(res => res.vicinity !== undefined)
      .map(res => ({
        label: `${res.title}, ${res.vicinity.replace('<br/>', ', ')}`,
        x: res.position[1],
        y: res.position[0],
        bounds: res.bbox,
        raw: res,
      }))
  }

  async search(
    options: SearchArgument,
  ): Promise<SearchResult<HereMapsSearchResult>[]> {
    const url = this.endpoint({
      query: options.query,
    })

    const request = await fetch(url)
    const json: HereMapsSearch = await request.json()

    return this.parse({ data: json })
  }
}
