import { NAV_OPEN_FLAG_KEY } from '@backoffice-frontend/common'
import { useLocalStorage } from '@backoffice-frontend/hooks'
import { type ReactNode, createContext, useContext } from 'react'

type NavigationDrawerContextType = {
  open?: boolean
  setOpen?: (open: boolean) => void
}

const NavigationDrawerContext = createContext<NavigationDrawerContextType>({})

export const NavigationDrawerContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [open, setOpen] = useLocalStorage(NAV_OPEN_FLAG_KEY, false)
  return (
    <NavigationDrawerContext.Provider value={{ open, setOpen }}>
      {children}
    </NavigationDrawerContext.Provider>
  )
}

export const useNavigationDrawerState = () => {
  const context = useContext(NavigationDrawerContext)
  return context.open ?? false
}

export const useSetNavigationDrawerState = () => {
  const context = useContext(NavigationDrawerContext)
  return context.setOpen ?? (() => {})
}
