import {
  ENV,
  Environment,
  mockServerPort,
} from '@backoffice-frontend/environment'

const DriverTippingStages: Record<Environment, string> = {
  [Environment.local]: `http://localhost:${mockServerPort}/api/payment`,
  [Environment.test]: `http://localhost:${mockServerPort}/api/payment`,
  [Environment.development]:
    'https://payment2-backoffice.api.trip.dev.moia-group.io',
  [Environment.integration]:
    'https://payment2-backoffice.api.trip.int.moia-group.io',
  [Environment.production]:
    'https://payment2-backoffice.api.trip.prd.moia-group.io',
}

const Host = DriverTippingStages[ENV]
const Base = `${Host}/backoffice/tipping`

// TODO(Vinod): get rid of this Urls in the object
export const DriverTippingAreaEndpoints = {
  Urls: {
    List: `${Base}/tips`,
    PayoutPeriods: {
      List: `${Base}/payout-periods`,
      Show: `${Base}/payout-periods/:periodId`,
      PayoutsList: `${Base}/payout-periods/:periodId/payouts`,
      BatchPayout: `${Base}/payout-periods/:periodId/batch-payout`,
    },
    Employees: {
      PayoutsList: `${Base}/employees/:employeeId/payouts`,
    },
  },
}
