import { StopNetworkAreaRoutes, createRoute } from '@backoffice-frontend/common'
import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { useGlobalServiceAreaIds } from '@backoffice-frontend/service-area-picker'
import { NetworkIcon } from '@moia-dev/pace-icons'

export const StopNetworkAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  const [{ id: globalServiceAreaId }] = useGlobalServiceAreaIds()
  const route = globalServiceAreaId
    ? createRoute(StopNetworkAreaRoutes.Show.route, {
        serviceAreaId: globalServiceAreaId,
      })
    : StopNetworkAreaRoutes.route
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={route}
      Icon={NetworkIcon}
    />
  )
}
