// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { loadTranslations as loadTranslationsAdAlertCards } from '@backoffice-frontend/ad-alert-cards'
import { loadTranslations as loadTranslationsAdExteriorVideoStream } from '@backoffice-frontend/ad-exterior-video-stream'
import { loadTranslations as loadTranslationsAdOperatorCall } from '@backoffice-frontend/ad-operator-call'
import { loadTranslations as loadTranslationsAdOperatorDrawer } from '@backoffice-frontend/ad-operator-drawer'
import { loadTranslations as loadTranslationsAdOperatorVideo } from '@backoffice-frontend/ad-operator-video'
import { loadTranslations as loadTranslationsAdPassengerList } from '@backoffice-frontend/ad-passenger-list'
import { loadTranslations as loadTranslationsAdPoolingToggle } from '@backoffice-frontend/ad-pooling-toggle'
import { loadTranslations as loadTranslationsAdTelematicsCards } from '@backoffice-frontend/ad-telematics-cards'
import { loadTranslations as loadTranslationsAdVehicleStream } from '@backoffice-frontend/ad-vehicle-stream'
import { loadTranslations as loadTranslationsAlertCards } from '@backoffice-frontend/alert-cards'
import { loadTranslations as loadTranslationsAlertCardsCommon } from '@backoffice-frontend/alert-cards-common'
import { loadTranslations as loadTranslationsAlertDrawer } from '@backoffice-frontend/alert-drawer'
import { loadTranslations as loadTranslationsAlertsCommon } from '@backoffice-frontend/alerts-common'
import { loadTranslations as loadTranslationsAreaComponents } from '@backoffice-frontend/area-components'
import { loadTranslations as loadTranslationsAuthenticatedAppView } from '@backoffice-frontend/authenticated-app-view'
import { loadTranslations as loadTranslationsCustomerComponents } from '@backoffice-frontend/customer-components'
import { loadTranslations as loadTranslationsEmployeeAutocompleteField } from '@backoffice-frontend/employee-autocomplete-field'
import { loadTranslations as loadTranslationsEmployeeIdentity } from '@backoffice-frontend/employee-identity'
import { loadTranslations as loadTranslationsFeatureToggle } from '@backoffice-frontend/feature-toggle'
import { loadTranslations as loadTranslationsFeeInvoicing } from '@backoffice-frontend/fee-invoicing'
import { loadTranslations as loadTranslationsFleetMapsCommon } from '@backoffice-frontend/fleet-maps-common'
import { loadTranslations as loadTranslationsLeafletMapCommon } from '@backoffice-frontend/leaflet-map-common'
import { loadTranslations as loadTranslationsLoginView } from '@backoffice-frontend/login-view'
import { loadTranslations as loadTranslationsOperatorCall } from '@backoffice-frontend/operator-call'
import { loadTranslations as loadTranslationsOperatorConnect } from '@backoffice-frontend/operator-connect'
import { loadTranslations as loadTranslationsPatterns } from '@backoffice-frontend/patterns'
import { loadTranslations as loadTranslationsPayment } from '@backoffice-frontend/payment'
import { loadTranslations as loadTranslationsReportIoLib } from '@backoffice-frontend/report-io-lib'
import { loadTranslations as loadTranslationsServiceAreaPicker } from '@backoffice-frontend/service-area-picker'
import { loadTranslations as loadTranslationsServiceCancellation } from '@backoffice-frontend/service-cancellation'
import { loadTranslations as loadTranslationsServiceMetrics } from '@backoffice-frontend/service-metrics'
import { loadTranslations as loadTranslationsShiftsEmployee } from '@backoffice-frontend/shifts-employee'
import { loadTranslations as loadTranslationsStatus } from '@backoffice-frontend/status'
import { loadTranslations as loadTranslationsTables } from '@backoffice-frontend/tables'
import { loadTranslations as loadTranslationsTranslations } from '@backoffice-frontend/translations'
import { loadTranslations as loadTranslationsTripComponents } from '@backoffice-frontend/trip-components'
import { loadTranslations as loadTranslationsUpdates } from '@backoffice-frontend/updates'
import { loadTranslations as loadTranslationsValidators } from '@backoffice-frontend/validators'
import { loadTranslations as loadTranslationsVehicleChecks } from '@backoffice-frontend/vehicle-checks'
import { loadTranslations as loadTranslationsVehicleCommon } from '@backoffice-frontend/vehicle-common'
import { loadTranslations as loadTranslationsVehicleMission } from '@backoffice-frontend/vehicle-mission'

export const loadSharedLibTranslations = () => {
  loadTranslationsServiceAreaPicker()
  loadTranslationsAreaComponents()
  loadTranslationsFeatureToggle()
  loadTranslationsValidators()
  loadTranslationsTranslations()
  loadTranslationsLoginView()
  loadTranslationsAuthenticatedAppView()
  loadTranslationsPatterns()
  loadTranslationsTables()
  loadTranslationsUpdates()
  loadTranslationsStatus()
  loadTranslationsAdOperatorCall()
  loadTranslationsAdOperatorVideo()
  loadTranslationsOperatorConnect()
  loadTranslationsServiceMetrics()
  loadTranslationsOperatorCall()
  loadTranslationsAdOperatorDrawer()
  loadTranslationsAdPassengerList()
  loadTranslationsAdPoolingToggle()
  loadTranslationsAdTelematicsCards()
  loadTranslationsAdVehicleStream()
  loadTranslationsAlertCards()
  loadTranslationsAlertCardsCommon()
  loadTranslationsAdAlertCards()
  loadTranslationsAlertDrawer()
  loadTranslationsFleetMapsCommon()
  loadTranslationsVehicleMission()
  loadTranslationsLeafletMapCommon()
  loadTranslationsVehicleCommon()
  loadTranslationsServiceCancellation()
  loadTranslationsEmployeeIdentity()
  loadTranslationsShiftsEmployee()
  loadTranslationsAlertsCommon()
  loadTranslationsCustomerComponents()
  loadTranslationsPayment()
  loadTranslationsTripComponents()
  loadTranslationsEmployeeAutocompleteField()
  loadTranslationsReportIoLib()
  loadTranslationsVehicleChecks()
  loadTranslationsFeeInvoicing()
  loadTranslationsAdExteriorVideoStream()
}
