import {
  cleanUpLocalStorage,
  logout as mockLogout,
} from '@backoffice-frontend/common'
import { useErrorHandlingMutation } from '@backoffice-frontend/data-fetching'
import { ENV, Environment } from '@backoffice-frontend/environment'
import { useBackofficeLoggedInUser } from '@backoffice-frontend/user-management-ui'
import { ActionItem } from '@moia-dev/pace-web'
import { useTranslation } from 'react-i18next'
import { useBackofficeLogoutUserMutation } from './LogoutButton.hook'

const useLogoutButton = () => {
  const { user } = useBackofficeLoggedInUser()
  const [logoutMutation, { loading }] = useErrorHandlingMutation(
    useBackofficeLogoutUserMutation,
  )

  const logout = async () => {
    // the logoutMutation won't work in the testing stage but is needed for the real envs to make sure we invalidate the token at once!
    // As a default the cognito token is still valid after calling the build in logout which can be exploited by an attacker
    // MOIA-92360
    if (ENV === Environment.local || ENV === Environment.test) {
      await mockLogout()
      return
    }
    if (user?.id) {
      await logoutMutation({ variables: { input: { id: user.id } } })
      cleanUpLocalStorage()
      // reload to trigger the local cognito logout and redirect to the loginpage
      window.location.reload()
    }
  }
  return { logout, loading }
}

export const LogoutButton = () => {
  const { logout, loading } = useLogoutButton()
  const { t } = useTranslation()

  return (
    <ActionItem
      disabled={loading}
      label={t('common_logout_button')}
      onClick={logout}
    />
  )
}
