import { transformRoutes } from '../utils'

const baseRoute = '/disruptions'

export const BaseDisruptionsAreaRoutes = {
  route: baseRoute,
  ViewReport: {
    route: '/:reportId/view',
  },
  NotFound: {
    route: '/not-found',
  },
}

export const DisruptionsAreaRoutes = transformRoutes(BaseDisruptionsAreaRoutes)
